import { Autocomplete, Box, FormControl } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useClasses from "../../hooks/useClasses";
import { selectLatestTraining } from "../../store/slice/auth";
import {
  FlexBox,
  StyledButton,
  StyledText,
  StyledTextfield,
} from "../../theme/commonStyles";
import Card from "../Global/Card/Card";
import { styles } from "./styles";

const CohortCard = ({
  variablesData,
  setVariablesData,
  levelVariables,
  setLevelVariables,
}) => {
  const [selectedVariable, setSelectedVariable] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const latestTraining = useSelector(selectLatestTraining);
  const classes = useClasses(styles);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (latestTraining && latestTraining?.training_params) {
        setSelectedVariable(latestTraining.training_params.selectedVariables);
      }
    };
    fetchData();
  }, [latestTraining]);

  useEffect(() => {
    const fetchData = async () => {
      if (variablesData.length > 0) {
        let temp = {};
        variablesData.forEach((item, _i) => {
          temp[_i + 1] = item.variables;
        });
        setLevelVariables(temp);
      }
    };
    fetchData();
  }, [setLevelVariables, variablesData]);

  const handleAddLevel = () => {
    if (variablesData.length === 0) {
      setVariablesData([
        {
          level: 1,
          variables: [selectedVariable[0]],
        },
      ]);
      setLevelVariables({
        1: [selectedVariable[0]],
      });
    } else {
      let temp = [
        ...variablesData,
        {
          level: variablesData[variablesData.length - 1].level + 1,
          variables: [selectedVariable[0]],
        },
      ];
      setVariablesData(temp);
      setLevelVariables({
        ...levelVariables,
        [variablesData.length + 1]: [selectedVariable[0]],
      });
    }
  };

  return (
    <Card
      heading="Comparators"
      customContainerCss={{ mt: 10 }}
      isCollapsible
      isOpen={isOpen}
      handleCollapse={handleCollapse}>
      <Box className={clsx(classes.openCard, !isOpen && classes.closedCard)}>
        {variablesData.map((item, i) => (
          <FlexBox col mt={3} key={i}>
            <StyledText>Cohort {i + 1}</StyledText>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                id="selected-variables"
                options={selectedVariable}
                getOptionLabel={(option) => option}
                name="selectedVariables"
                value={levelVariables[i + 1] ? levelVariables[i + 1] : []}
                onChange={(_event, value) => {
                  setLevelVariables({
                    ...levelVariables,
                    [i + 1]: value,
                  });
                  const temp = [...variablesData];
                  temp[i] = {
                    ...temp[i],
                    variables: value,
                  };
                  setVariablesData(temp);
                }}
                renderInput={(params) => (
                  <StyledTextfield
                    {...params}
                    variant="outlined"
                    label="Select Variables"
                    placeholder="Variables"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </FlexBox>
        ))}
        <StyledButton
          sx={{ mt: 2, alignSelf: "flex-end" }}
          onClick={handleAddLevel}>
          Add Cohort
        </StyledButton>
      </Box>
    </Card>
  );
};

export default CohortCard;
