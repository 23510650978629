import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./misOrgs.styles";
import Card from "../../Global/Card/Card";
import EditIcon from "@mui/icons-material/Edit";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import { useNavigate } from "react-router-dom";
import { fetchAllOrgDetails } from "../../../api/mis.api";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
// import moment from "moment";

const columns = [
  "Org Id",
  "Name",
  "Setup Stage",
  "Created",
  "Modified",
  "Trial Valid Till",
  "Actions",
];

const AllOrgs = () => {
  const classes = useClasses(styles);
  const [loading, setLoading] = useState();
  const [orgs, setOrgs] = useState({
    count: "",
    data: [],
  });
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await fetchAllOrgDetails();
    if (data) setOrgs(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = (org) => {
    navigate(`/mis-dashboard/orgs/${org.org_id}`, { state: org });
  };

  const activeOrg = useMemo(() => {
    let count = 0;
    const currentDate = moment();
    orgs.data.forEach((org) => {
      if (moment(org.trial_valid_till).isAfter(currentDate)) count++;
    });
    return count;
  }, [orgs.data]);

  const expiredOrg = useMemo(() => {
    let count = 0;
    const currentDate = moment();
    orgs.data.forEach((org) => {
      if (moment(org.trial_valid_till).isBefore(currentDate)) count++;
    });
    return count;
  }, [orgs.data]);

  // code snippet
  const CountBox = ({ title, value }) => {
    return (
      <Box className={classes.countBoxWrapper} p={2}>
        <StyledText fs={16} clr="text3" fontWeight={600} whiteSpace="nowrap">
          {title}
        </StyledText>
        <StyledText fs={24} clr="tertiary" fontWeight={600}>
          {loading ? <Skeleton /> : value}
        </StyledText>
      </Box>
    );
  };

  return (
    <>
      <Stack direction="row" mb={4} justifyContent="flex-start" spacing={4}>
        <CountBox title="Organizations" value={orgs.count} />
        <CountBox title="Active" value={activeOrg} />
        <CountBox title="Expired" value={expiredOrg} />
      </Stack>
      <Card heading="All Organizations">
        <TableContainer sx={{ mt: 2, maxHeight: "400px" }}>
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          <Table aria-label="All Organizations List">
            <TableHead>
              <TableRow>
                {columns.map((item, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orgs.data.length > 0 ? (
                orgs.data.map((org) => (
                  <TableRow key={org.id}>
                    <TableCell scope="row" className={classes.tableCell}>
                      {org?.org_id}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      {org?.name}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      {org?.setup_stage}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      {new Date(org?.created).toLocaleDateString()}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      {new Date(org?.modified).toLocaleDateString()}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      {/* {moment(org?.trial_valid_till).format(
                        " MMMM Do YYYY, h:mm:ss a"
                      )} */}
                      {org?.trial_valid_till}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      <FlexBox alignItems="center" justifyContent="center">
                        <IconButton
                          color="primary"
                          sx={{ ml: 1 }}
                          size="small"
                          onClick={() => handleEdit(org)}>
                          <EditIcon sx={{ fontSize: 22 }} />
                        </IconButton>
                      </FlexBox>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <StyledText fontWeight="bold" textAlign="center">
                      No Organization
                    </StyledText>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default AllOrgs;
