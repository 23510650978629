import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Box, Select, TextField, Typography } from "@mui/material";
import { css } from "@emotion/react";
import LeftPaneBg from "../assets/leftPaneBg.svg";
import { LoadingButton } from "@mui/lab";

export const COLORS = {
  primary: "#009EF1",
  secondary: "#005776",
  tertiary: "#48AFD3",
  text1: "#fff",
  text2: "#000",
  text3: "rgba(64, 64, 64, 0.8)",
  text4: "rgba(64, 64, 64, 0.6)",
  text5: "rgba(168, 168, 168, 1)",
  grad: "linear-gradient(105.78deg, #005776 2.22%, #009EF1 100%)",
  gray: "#444",
  gray2: "rgba(64, 64, 64, 0.5)",
  hover: "#BBD2EE",
};

export const FONT_SIZES = {
  regular: "1rem",
  subHeading: "1.125rem",
  small: "1rem",
  h1: "3.052rem",
  h2: "2.441rem",
  h3: "1.953rem",
  h4: "1.563rem",
  h5: "1.25rem",
};

export const SPACING = {
  size_1: "0.5rem",
  size_2: "1rem",
  size_3: "1.5rem",
  size_4: "2rem",
  size_5: "2.5rem",
  size_6: "3rem",
  size_7: "3.5rem",
  size_8: "4rem",
  size_9: "4.5rem",
  size_10: "5rem",
};

export const flexStyles = (dir, ai = "", jc = "") => ({
  display: "flex",
  flexDirection: dir === "col" ? "column" : "row",
  alignItems: ai === "fs" ? "flex-start" : ai === "fe" ? "flex-end" : "center",
  justifyContent:
    jc === "fs"
      ? "flex-start"
      : jc === "fe"
      ? "flex-end"
      : jc === "se"
      ? "space-evenly"
      : jc === "sb"
      ? "space-between"
      : "center",
});

export const RouterLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: initial;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: initial;
  }
  ${({ breadcrumb }) =>
    breadcrumb === "true" &&
    css`
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const StyledText = styled(Typography)`
  ${({ clr }) =>
    clr &&
    css`
      color: ${clr.includes("#") ? clr : COLORS[clr]};
    `}

  ${({ fs }) =>
    fs &&
    css`
      font-size: ${fs}px;
    `}
    
    ${({ size }) =>
    size &&
    css`
      font-size: ${FONT_SIZES[size]};
    `}
`;

export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${({ col }) => (col ? "column" : "row")};
  ${({ flexcenter }) =>
    flexcenter === "true" &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const StyledDivider = styled(Box)`
  width: 100%;
  height: 1px;
  background: #a8a8a8;
  ${({ clr }) =>
    clr &&
    css`
      background: ${clr.includes("#") ? clr : COLORS[clr]};
    `}

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
    `}
`;

export const StyledCard = styled(FlexBox)`
  /* width: 100%; */
  background: linear-gradient(
    180deg,
    #f4f7fb 0%,
    rgba(244, 247, 251, 0.8) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`;

export const LeftPaneWrapper = styled(Box)`
  width: 320px;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  background: #fff;
  padding: 2rem 1rem;
  background-image: url(${LeftPaneBg});
  background-size: 100%;
  background-repeat: no-repeat;

  &::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  ${({ wid }) =>
    wid &&
    css`
      width: ${wid}px;
    `}
`;

export const FilterWrapper = styled(LeftPaneWrapper)`
  max-width: 320px,
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 1rem 1rem 2rem;
  background-image:linear-gradient(180deg, #F4F7FB 0%, rgba(244, 247, 251, 0.8) 100%);
  border-radius: 20px;
`;

export const StyledTextfield = styled(TextField)`
  & .MuiInput-root:hover::before {
    border-bottom: 2px solid #009ef1 !important;
  }
  & .MuiInput-root::before {
    border-bottom: 2px solid #009ef1;
  }
`;

export const StyledTextfield2 = styled(StyledTextfield)`
  & .MuiInputBase-root {
    border-radius: 8px;
  }
`;

export const StyledSelect = styled(Select)`
  &:hover::before {
    border-bottom: 2px solid #009ef1 !important;
  }
  &::before {
    border-bottom: 2px solid #009ef1;
  }
`;

export const StyledButton = styled(LoadingButton)`
  background-image: ${COLORS.grad};
  color: ${COLORS.text1};
  font-weight: 600;
  font-size: 12px;

  &:hover {
    opacity: 0.8;
  }

  ${({ bg }) =>
    bg &&
    css`
      background-color: ${bg.includes("#") ? bg : COLORS[bg]};
      &:hover {
        background-color: ${bg.includes("#") ? bg : COLORS[bg]};
      }
    `}
  ${({ grabg }) =>
    grabg &&
    css`
      background-image: ${COLORS[grabg]};
      &:hover {
        background-image: ${COLORS[grabg]};
        opacity: 0.8;
      }
    `}
  ${({ flexcenter }) =>
    flexcenter === "true" &&
    css`
      align-items: center;
      justify-content: center;
    `};
  ${({ stop_hover }) =>
    stop_hover === "true" &&
    css`
      &:hover {
        opacity: 1;
        cursor: unset;
      }
    `};
  padding: 10px 32px;
  text-transform: none;
  border-radius: 310px;
  box-shadow: -6px 12px 24px rgba(0, 88, 120, 0.1);
  &.Mui-disabled {
    opacity: 0.8;
    cursor: not-allowed;
    color: #fff;
  }
`;
