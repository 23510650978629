import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useMemo } from "react";

const StyledDataGrid = ({ loading, data }) => {
  function CustomToolbar() {
    const btnStyles = {
      p: "5px",
      backgroundColor: "#BBD2EE",
      color: "#000",
      textTransform: "capitalize",
      borderRadius: "5px",
      marginLeft: "0.5rem",
      "&:hover": {
        backgroundColor: "#BBD2EE",
        opacity: 0.8,
      },
    };
    return (
      <GridToolbarContainer sx={{ justifyContent: "flex-end", mb: 1 }}>
        <GridToolbarColumnsButton sx={btnStyles} />
        <GridToolbarFilterButton sx={btnStyles} />
        <GridToolbarDensitySelector sx={btnStyles} />
      </GridToolbarContainer>
    );
  }

  const rowsWithId = useMemo(() => {
    return data.map((val, index) => {
      return { ...val, id: index };
    });
  }, [data]);

  const columns = useMemo(() => {
    return Object.keys(data[0] || []).map((k) => ({
      field: k,
      headerName: k,
      flex: 1,
      minWidth: 180,
    }));
  }, [data]);
  return (
    <div
      style={{
        height: 500,
        width: "100%",
        paddingBottom: "2rem",
      }}
    >
      <DataGrid
        loading={loading}
        pageSize={15}
        rows={rowsWithId}
        columns={columns}
        hideFooter={true}
        hideFooterPagination={true}
        getRowId={(payListWithId) => payListWithId.id}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
        }}
        sx={{
          mt: 1,
          border: "none",
          "& .MuiDataGrid-main .MuiDataGrid-columnHeaders": {
            background: "#F4F7FB",
            borderRadius: "8px 8px 0px 0px",
          },
        }}
      />
    </div>
  );
};

export default StyledDataGrid;
