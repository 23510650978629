import React, { useRef, useEffect, useMemo } from "react";
import Chart from "chart.js";
import { StyledText } from "../../../theme/commonStyles";
import Card from "../../Global/Card/Card";
import useClasses from "../../../hooks/useClasses";
import { styles } from "../home.styles";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "./../../../store/slice/auth";
import Skeleton from "react-loading-skeleton";

const BarGraphCard = ({ graphData, loading }) => {
  const latestTraining = useSelector(selectLatestTraining);
  const classes = useClasses(styles);
  const canvasRef = useRef(null);

  const data = useMemo(
    () => ({
      labels: graphData.labels,
      datasets: [
        {
          label: "Pay parity",
          data: graphData.data,
          backgroundColor: "rgba(26, 108, 205, 0.4)",
          borderColor: "rgba(26, 108, 205, 1)",
          barThickness: 20,
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    }),
    [graphData]
  );

  useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx && !loading) {
      new Chart(ctx, {
        type: "bar",
        data: data,
        responsive: true,
        maintainAspectRatio: true,
        options: {
          tooltips: {
            callbacks: {
              title: function (tooltipItem) {
                let label = tooltipItem[0].label;
                return `${latestTraining.training_params.performanceField}: ${label}`;
              },
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                if (label) {
                  label += ": ";
                }
                label += tooltipItem.yLabel + "%";
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                scaleLabel: {
                  display: true,
                  labelString: "Pay Parity ⟶",
                },
                ticks: {
                  suggestedMax: 100,
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                scaleLabel: {
                  display: true,
                  labelString: `${latestTraining.training_params.performanceField} ⟶`,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [
    data,
    graphData,
    latestTraining.training_params.performanceField,
    loading,
  ]);
  return (
    <Card heading={"Pay Parity"} customCssClassName={classes.graphCard}>
      {loading ? (
        <Skeleton sx={{ width: "100%", height: "300px", marginTop: "1rem" }} />
      ) : graphData.labels.length > 0 ? (
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            minHeight: "300px",
            marginTop: "1rem",
          }}></canvas>
      ) : (
        <StyledText fs={18} clr="text5" textAlign="center" mt={2}>
          Opps! something went wrong
        </StyledText>
      )}
    </Card>
  );
};

export default React.memo(BarGraphCard);
