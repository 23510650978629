import React from "react";
import Skeleton from "react-loading-skeleton";
import useClasses from "../../../hooks/useClasses";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import { styles } from "../styles";

const TotalCards = ({ loading, heading, value, subValue }) => {
  const classes = useClasses(styles);
  return (
    <FlexBox className={classes.totalCard} col>
      <FlexBox
        className={classes.totalCardHeading}
        alignItems="center"
        justifyContent="center">
        <StyledText clr="text3" className="heading" fontWeight={600}>
          {heading}
        </StyledText>
      </FlexBox>

      <FlexBox p={2} col flexcenter="true">
        {loading ? (
          <>
            <Skeleton style={{ width: "100px", height: "50px" }} />
            <Skeleton style={{ width: "100px" }} />
          </>
        ) : (
          <>
            <StyledText clr="primary" variant="h3">
              {value}
            </StyledText>
            <StyledText clr="text5" fs={14}>
              ({subValue})
            </StyledText>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default TotalCards;
