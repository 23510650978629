export const styles = (theme) => ({
  modalWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBox: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linex: {
    width: "100%",
    margin: `${theme.sizes.size_3} 0`,
    border: "2px dashed rgb(75, 75, 75)",
  },
});
