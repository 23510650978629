import React from "react";
import { Box, Stack } from "@mui/material";
import Card from "../Global/Card/Card";
import useClasses from "./../../hooks/useClasses";
import { styles } from "./home.styles";
// import { ReactComponent as ConfigIcon } from "../../assets/configIcon.svg";
import { StyledText } from "../../theme/commonStyles";
import Skeleton from "react-loading-skeleton";
import { numberWithCommas } from "./../../helpers/utils";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/slice/auth";

const CountsSection = ({
  spendOptimizerTotals,
  useCasePredictionCount,
  loading,
}) => {
  const classes = useClasses(styles);
  const userData = useSelector(selectUserData);

  // code snippet
  const CountBox = ({ title, value }) => {
    return (
      <Box
        className={classes.countBoxWrapper}
        p={2}
        textAlign={
          userData.modulePermissions["Ranges"] &&
          userData.modulePermissions["Ranges"].length === 0
            ? "center"
            : "left"
        }>
        <StyledText fs={16} clr="text3" fontWeight={600} whiteSpace="nowrap">
          {title}
        </StyledText>
        <StyledText fs={24} clr="tertiary" fontWeight={600}>
          {loading ? <Skeleton /> : numberWithCommas(value)}
        </StyledText>
      </Box>
    );
  };
  return (
    <Stack direction="row" spacing={3}>
      <Card
        heading="Organization Details"
        customCssClassName={classes.totalCountCard}
        customContainerCss={{
          flex:
            userData.modulePermissions["Ranges"] &&
            userData.modulePermissions["Ranges"].length === 0
              ? 1
              : 0,
        }}>
        <Stack direction="row" my={4} spacing={3}>
          <CountBox
            title="Total Employees"
            value={spendOptimizerTotals?.total_employee || 0}
          />
          <CountBox
            title="Total comp spend"
            value={Math.round(spendOptimizerTotals?.total_comp_spend) || 0}
          />
        </Stack>
      </Card>
      {userData.modulePermissions["Ranges"] &&
        userData.modulePermissions["Ranges"].length !== 0 && (
          <Card
            heading="Prediction Count"
            customCssClassName={classes.totalCountCard}
            customContainerCss={{ flex: 1 }}>
            <Stack direction="row" my={4} spacing={3} width={"100%"}>
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Hiring") && (
                  <CountBox
                    title="Hiring"
                    value={useCasePredictionCount["Hiring"] || 0}
                  />
                )}

              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Transfer") && (
                  <CountBox
                    title="Transfer"
                    value={useCasePredictionCount["Transfer"] || 0}
                  />
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Retention") && (
                  <CountBox
                    title="Retention"
                    value={useCasePredictionCount["Retention"] || 0}
                  />
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Promotion") && (
                  <CountBox
                    title="Promotion"
                    value={useCasePredictionCount["Promotion"] || 0}
                  />
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes(
                  "Role Change"
                ) && (
                  <CountBox
                    title="Role Change"
                    value={useCasePredictionCount["Role Change"] || 0}
                  />
                )}
            </Stack>
          </Card>
        )}
    </Stack>
  );
};

export default CountsSection;
