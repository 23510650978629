import { Autocomplete, Grid, LinearProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
  getMarketDataTempplateVariables,
  latestTrainings,
  marketValueTemplate,
  marketValueUpload,
} from "../../../api";
import ErrorService from "../../../helpers/ErrorService";
import { FlexBox, StyledButton } from "../../../theme/commonStyles";
import InternalDataFileUpload from "../InternalDataConfig/InternalDataFileUpload";
import Card from "./../../Global/Card/Card";

const MarketData = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [trainingId, setTraining] = useState(0);
  const [isDownloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const nav = useNavigate();

  const { resetForm, setFieldValue, values, handleSubmit } = useFormik({
    initialValues: {
      selectedVariables: [],
    },
    async onSubmit(values) {
      setDownloading(true);
      try {
        const downloadFile = await marketValueTemplate(
          trainingId,
          values.selectedVariables
        );
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement("a");
        a.href = url;
        a.download = "filename.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } finally {
        setDownloading(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getLatest = await latestTrainings();
        if (getLatest && getLatest?.data?.training_params) {
          setTraining(getLatest.data.id);
          if (getLatest.data.status === "Successful") {
          } else {
            resetForm();
          }
        }
      } catch (error) {
        if (error.message === "403") nav("/trial-end");
      }
    };

    fetchData();
  }, [nav, resetForm, setFieldValue]);

  const handleFileUpload = async () => {
    setIsUploading(true);
    try {
      const data = await marketValueUpload(trainingId, uploadedFile);
      if (data.id) {
        ErrorService.sendOkMessage("File Uploaded Successfully");
      }
    } catch (e) {
      ErrorService.sendErrorMessage(e);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getMarketDataTempplateVariables(trainingId);
      if (res) setSelectedVariables(res.fields);
    };
    if (trainingId) fetchData();
    setLoading(false);
  }, [trainingId]);

  return (
    <Grid container width={"100%"} spacing={2}>
      {isUploading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={4}>
        <InternalDataFileUpload
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setIsUploading={setIsUploading}
          handleFileUpload={() => {}}
        />
        <StyledButton
          loading={isUploading}
          disabled={!uploadedFile}
          onClick={handleFileUpload}
          sx={{
            mt: 3,
            width: "100%",
            "&.Mui-disabled": {
              opacity: "0.5",
              color: "white",
            },
          }}>
          Upload File
        </StyledButton>
      </Grid>
      <Grid item xs={8}>
        <Card heading="Download Template">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Autocomplete
                sx={{ my: 3 }}
                multiple
                id="selected-variables"
                options={selectedVariables}
                getOptionLabel={(option) => option}
                value={values.selectedVariables}
                name="selectedVariables"
                onChange={(_event, value) => {
                  setFieldValue("selectedVariables", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Fields"
                    placeholder="Fields"
                  />
                )}
              />
              <FlexBox justifyContent="flex-end">
                <StyledButton
                  loading={isDownloading}
                  disabled={values.selectedVariables.length === 0}
                  onClick={handleSubmit}
                  sx={{
                    "&.Mui-disabled": {
                      color: "white",
                    },
                  }}>
                  Download Template
                </StyledButton>
              </FlexBox>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default MarketData;
