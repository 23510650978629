import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getComparatorLevelData } from "../../api";
import useClasses from "../../hooks/useClasses";
import StyledDataGridPagination from "../DataGrids/StyledDataGridPagination";
import Card from "../Global/Card/Card";
import { styles } from "./styles";
import { changeColumnName } from "../../helpers/utils";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import clsx from "clsx";
import "jspdf-autotable";
import jsPDF from "jspdf";

const CohortWiseDataTables = ({
  predictedResult,
  trainingId,
  level = 1,
  levelVariables,
}) => {
  const classes = useClasses(styles);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [comparisonData, setComparisonData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const fetchComparatorLevelData = useCallback(async () => {
    setLoading(true);
    const data = await getComparatorLevelData(
      predictedResult.id,
      trainingId,
      level,
      currentPage + 1
    );
    if (data && data.results.length > 0) {
      // need to find some better approach...
      const temp3 = data.results.map((item) => {
        const obj = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            obj[changeColumnName(key)] = item[key];
          }
        }
        return obj;
      });
      setComparisonData(temp3);
      setTotalRowCount(data.count);
    }
    setLoading(false);
  }, [currentPage, level, predictedResult.id, trainingId]);

  useEffect(() => {
    if (trainingId) fetchComparatorLevelData();
  }, [fetchComparatorLevelData, trainingId]);

  const pageChangeHandler = (value) => setCurrentPage(value);

  const dataDownloadHandler = () => {
    if (comparisonData.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(comparisonData);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Employee Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "Employee.xlsx");
    }
  };

  const pdfDownloadHandler = async () => {
    const pdf = new jsPDF("l", "pt", "a3");
    const columns = Object.keys(comparisonData[0]);
    let rows = [];
    for (let i = 0; i < comparisonData.length; i++) {
      var temp = Object.values(comparisonData[i]);
      rows.push(temp);
    }
    pdf.text(500, 40, "Cohort Data");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    pdf.save("CohortData_a3");
  };

  return (
    <Box mt={5}>
      <Card
        heading={`Comparator Analysis Overview-Cohort ${level}`}
        customCssClassName={classes.dataCards}
        showDownload
        downloadHandler={dataDownloadHandler}
        isCollapsible
        isOpen={isOpen}
        isPositionLeft
        handleCollapse={handleCollapse}
        pdfDownloadHandler={pdfDownloadHandler}>
        <Box className={clsx(classes.openCard, !isOpen && classes.closedCard)}>
          <StyledDataGridPagination
            data={comparisonData}
            levelVariables={levelVariables}
            currentPage={currentPage}
            pageChangeHandler={pageChangeHandler}
            totalRowCount={totalRowCount}
            loading={loading}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default CohortWiseDataTables;
