import React from "react";
import useClasses from "../../../hooks/useClasses";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import { styles } from "./styles";

const Banner = ({ heading }) => {
  const classes = useClasses(styles);
  return (
    <FlexBox
      component="section"
      className={classes.bannerWrapper}
      flexcenter="true"
    >
      <StyledText variant="h4" fontWeight="600" clr="text2">
        {heading}
      </StyledText>
    </FlexBox>
  );
};

export default Banner;
