import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
  StyledTextfield2,
} from "../../../theme/commonStyles";
import {
  compareObjects,
  compareObjectsArr,
  modulePermissionsCheck,
} from "../../../helpers/utils";
import ErrorService from "../../../helpers/ErrorService";
import { updateOrgDetails } from "../../../api/mis.api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const setupStages = [
  { id: 0, label: "Incomplete" },
  { id: 1, label: "Internal Data Uploaded" },
  { id: 2, label: "Model Trained" },
  { id: 3, label: "Pay Equity Viewed" },
  { id: 4, label: "Market Data Uploaded" },
];

const OrgsDetails = () => {
  const { state: orgDetails } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialState = () => {
    if (orgDetails) {
      return {
        name: orgDetails.name,
        setupStage: orgDetails.setup_stage,
        trialValid: orgDetails.trial_valid_till,
        modulePermissions: orgDetails.module_permissions,
      };
    }
    return {
      name: "",
      setupStage: "",
      trialValid: "",
      modulePermissions: {},
    };
  };
  const [formData, setFormData] = useState(initialState);
  const [modulePermissions, setModulePermissions] = useState(() => {
    if (orgDetails) {
      modulePermissionsCheck(orgDetails.module_permissions);
      return orgDetails.module_permissions;
    }
    return {};
  });

  const handleModulePermission = (e, module_name, submodule_name) => {
    const temp = JSON.parse(JSON.stringify(modulePermissions));
    if (!e.target.checked) {
      // temp[e.target.name]
      let tempArr = [];
      if (temp[module_name]) {
        tempArr = [...temp[module_name]];
      }
      temp[module_name] = tempArr.filter((item) => item !== submodule_name);
      if (temp[module_name].length === 0) delete temp[module_name];
      setModulePermissions(temp);
    } else {
      let tempArr = [];
      if (temp[module_name]) {
        tempArr = [...temp[module_name]];
      }
      tempArr.push(submodule_name);
      temp[module_name] = tempArr;
      setModulePermissions(temp);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const prevData = {
      name: orgDetails.name,
      setupStage: orgDetails.setup_stage,
      trialValid: orgDetails.trial_valid_till,
      modulePermissions: orgDetails.module_permissions,
    };
    const updatePayload = {
      id: orgDetails.id,
      org_details: {},
    };
    let modulePermissionObj = {};
    if (orgDetails?.module_permissions) {
      modulePermissionObj = { ...orgDetails.module_permissions };
    }
    if (
      compareObjects(formData, prevData) &&
      compareObjectsArr(modulePermissions, modulePermissionObj)
    ) {
      ErrorService.sendWarnMessage({
        message: "No Values are changed to be update",
      });
    } else {
      if (prevData.name !== formData.name)
        updatePayload.org_details.name = formData.name;
      if (prevData.setupStage !== formData.setupStage)
        updatePayload.org_details.setup_stage = formData.setupStage;
      if (prevData.trialValid !== formData.trialValid)
        updatePayload.org_details.trial_valid_till = formData.trialValid;

      if (
        JSON.stringify(modulePermissions) !==
        JSON.stringify(modulePermissionObj)
      )
        updatePayload.org_details["module_permissions"] = modulePermissions;
      const data = await updateOrgDetails(updatePayload);
      if (data && data.message === "Updated the Organizations successfully!") {
        ErrorService.sendOkMessage(data.message);
        navigate("/mis-dashboard");
      }
    }
    setLoading(false);
  };

  const handleDiscard = () => {
    setFormData(initialState);
  };

  return (
    <Box>
      <FlexBox alignItems="center">
        <IconButton size="small" mr={1} onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <StyledText variant="h6">Change Organization Details :</StyledText>
        &nbsp;
        <StyledText variant="h6" fontWeight={600}>
          {orgDetails.name}
        </StyledText>
      </FlexBox>
      <StyledDivider my={1} />
      <Stack direction="column" spacing={4} mt={4}>
        <Grid container>
          <Grid item xs={2}>
            <StyledText>Name</StyledText>
          </Grid>
          <Grid item xs={10}>
            <StyledTextfield2
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              size="small"
              name="name"
              id="name"
              placeholder="Enter Organization Name"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <StyledText>Setup stage</StyledText>
          </Grid>
          <Grid item xs={10}>
            <StyledTextfield2
              value={formData.setupStage}
              onChange={(e) =>
                setFormData({ ...formData, setupStage: e.target.value })
              }
              fullWidth
              select
              size="small"
              name="name"
              id="name"
              placeholder="Enter Organization Name">
              {setupStages.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextfield2>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <StyledText>Trial valid till</StyledText>
          </Grid>
          <Grid item xs={10}>
            <DateTimePicker
              value={moment(formData.trialValid)}
              onChange={(newValue) =>
                setFormData({
                  ...formData,
                  trialValid: moment(newValue).format(),
                })
              }
              renderInput={(params) => <StyledTextfield2 {...params} />}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <StyledText>Module Permissions</StyledText>
          </Grid>
          <Grid item xs={10}>
            <Stack direction={"column"}>
              <FormControlLabel
                label="Pay Ranges"
                control={
                  <Checkbox
                    checked={
                      (modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].length === 5) ||
                      false
                    }
                    indeterminate={
                      (modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].length < 5) ||
                      false
                    }
                    onChange={(e) => {
                      if (!e.target.checked) {
                        const temp = { ...modulePermissions };
                        delete temp["Ranges"];
                        setModulePermissions(temp);
                      } else {
                        setModulePermissions({
                          ...modulePermissions,
                          Ranges: [
                            "Hiring",
                            "Transfer",
                            "Retention",
                            "Promotion",
                            "Role Change",
                          ],
                        });
                      }
                    }}
                  />
                }
              />
              <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                <FormControlLabel
                  label="Hiring"
                  control={
                    <Checkbox
                      name="Ranges"
                      size="small"
                      checked={
                        modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].includes("Hiring")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Ranges", "Hiring")
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Transfer"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].includes("Transfer")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Ranges", "Transfer")
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Retention"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].includes("Retention")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Ranges", "Retention")
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Promotion"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].includes("Promotion")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Ranges", "Promotion")
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Role Change"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Ranges"] &&
                        modulePermissions["Ranges"].includes("Role Change")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Ranges", "Role Change")
                      }
                    />
                  }
                />
              </Box>
              <StyledDivider my={2} />
              <FormControlLabel
                label="Pay Analytics"
                control={
                  <Checkbox
                    checked={
                      (modulePermissions["Analytics"] &&
                        modulePermissions["Analytics"].length === 2) ||
                      false
                    }
                    indeterminate={
                      (modulePermissions["Analytics"] &&
                        modulePermissions["Analytics"].length < 2) ||
                      false
                    }
                    // indeterminate={checked[0] !== checked[1]}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        const temp = { ...modulePermissions };
                        delete temp["Analytics"];
                        setModulePermissions(temp);
                      } else {
                        setModulePermissions({
                          ...modulePermissions,
                          Analytics: ["Optimizer", "Report"],
                        });
                      }
                    }}
                  />
                }
              />
              <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                <FormControlLabel
                  label="Spend Optimizer"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Analytics"] &&
                        modulePermissions["Analytics"].includes("Optimizer")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Analytics", "Optimizer")
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Reports"
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        modulePermissions["Analytics"] &&
                        modulePermissions["Analytics"].includes("Report")
                      }
                      onChange={(e) =>
                        handleModulePermission(e, "Analytics", "Report")
                      }
                    />
                  }
                />
              </Box>
              <StyledDivider my={2} />
              <FormControlLabel
                label="Pay Equity"
                control={
                  <Checkbox
                    checked={modulePermissions["Equity"]}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        const temp = { ...modulePermissions };
                        delete temp["Equity"];
                        setModulePermissions(temp);
                      } else {
                        setModulePermissions({
                          ...modulePermissions,
                          Equity: [],
                        });
                      }
                    }}
                  />
                }
              />
            </Stack>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item xs={2}>
            <StyledText>Best Model Used</StyledText>
          </Grid>
          <Grid item xs={10}>
            <StyledTextfield2
              fullWidth
              select
              size="small"
              name="name"
              id="name"
              placeholder="Enter Organization Name">
              {setupStages.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledTextfield2>
          </Grid>
        </Grid> */}
      </Stack>
      <FlexBox mt={4} justifyContent="flex-end" sx={{ columnGap: "1rem" }}>
        <StyledButton onClick={handleSubmit} loading={loading}>
          Save
        </StyledButton>
        <Button
          onClick={handleDiscard}
          color="error"
          variant="contained"
          sx={{
            fontWeight: 600,
            fontSize: 12,
          }}>
          Discard
        </Button>
      </FlexBox>
    </Box>
  );
};

export default OrgsDetails;
