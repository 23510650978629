import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import AppLink from "../AppLink";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setOrgDetails, setUser } from "../../store/slice/auth";
import { useNavigate } from "react-router-dom";
import {
  fetchOrgDetailsOnSignIn,
  getLoggedInMemberInfo,
  signIn,
} from "../../api";
import {
  modulePermissionsCheck,
  setCookie,
  // hashPassword,
} from "../../helpers/utils";

function SignInForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const response = await signIn({
          ...values,
          // password: hashPassword(values.password),
        });
        if (!response.ok) {
          const errors = await response.json();
          if (response.status === 400) setErrors(errors);
          else throw new Error(errors);
          return;
        }

        let data = await response.json();
        // Setting Cookies...
        const d = new Date();
        d.setTime(d.getTime() + 30 * 60 * 1000);
        setCookie("sessionid", data.sid, d);
        const authData = {
          token: data.token,
          authToken: data.auth,
          sid: data.sid,
          sidExp: data.sidexp,
        };
        const result = await getLoggedInMemberInfo(authData);
        const data2 = await fetchOrgDetailsOnSignIn(authData);
        modulePermissionsCheck(data2?.module_permissions);
        modulePermissionsCheck(result?.module_permissions);
        dispatch(
          setUser({
            ...authData,
            id: result?.id,
            role: result?.role,
            details: result?.user,
            dataPermissions: result?.data_permissions,
            modulePermissions:
              result?.module_permissions || data2?.module_permissions,
          })
        );
        dispatch(setOrgDetails(data2));
        navigate("/", { replace: true });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 1 }}
      onSubmit={formik.handleSubmit}>
      {Boolean(formik.errors.non_field_errors) && (
        <Alert severity="error">{formik.errors.non_field_errors}</Alert>
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoFocus
        value={formik.values.username}
        onChange={formik.handleChange}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={formik.isSubmitting}>
        Sign In
      </Button>
      <Grid container>
        <Grid item xs>
          <AppLink to="/account/password-reset" variant="body2">
            Forgot password?
          </AppLink>
        </Grid>
        <Grid item>
          <AppLink to="/register" variant="body2">
            {"Don't have an account? Sign Up"}
          </AppLink>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignInForm;
