import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { useState } from "react";
import { requestPasswordReset } from "../../api";

function PasswordResetForm() {
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let response = await requestPasswordReset(values);
        if (!response.ok) {
          const errors = await response.json();
          if (response.status === 400) setErrors(errors);
          else throw new Error(errors);
          return;
        }
        setSubmitted(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      {submitted && (
        <Alert severity="success">
          We have sent you an email with a link to reset your password. Please
          check your email and click the link to continue.
        </Alert>
      )}
      <p>
        Forgot your password? Enter your email in the form below and we'll send
        you instructions for creating a new one.
      </p>
      <TextField
        margin="dense"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={formik.isSubmitting}
        disabled={submitted}
      >
        Reset password
      </Button>
    </Box>
  );
}

export default PasswordResetForm;
