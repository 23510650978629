import SignInForm from "./SignInForm";
import LayoutAuth from "../Layout/LayoutAuth";

function SignIn() {
  return (
    <LayoutAuth title="Sign In">
      <SignInForm />
    </LayoutAuth>
  );
}

export default SignIn;
