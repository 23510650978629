// import { Typography, Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { FlexBox, RouterLink, StyledText } from "../../../theme/commonStyles";

const routes = [
  { path: "/mis-dashboard", breadcrumb: "Home" },
  {
    path: "/mis-dashboard/orgs",
    breadcrumb: "Org",
    props: {
      loc: "/mis-dashboard",
    },
  },
  {
    path: "/mis-dashboard/orgs/:orgId",
    props: {
      loc: "/mis-dashboard",
    },
  },
];

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <FlexBox>
      {breadcrumbs.slice(1).map(({ match, breadcrumb, loc }, index, arr) => (
        <FlexBox key={match.pathname} mr={1} alignItems="center">
          <RouterLink
            to={index === 1 ? loc : index === 2 ? loc : match.pathname}
            breadcrumb="true"
            disabled>
            <StyledText fs={18} clr="text3">
              {breadcrumb}
            </StyledText>
          </RouterLink>
          {index !== arr.length - 1 && (
            <KeyboardArrowRight sx={{ fontSize: 18, color: "#404040cc" }} />
          )}
        </FlexBox>
      ))}
    </FlexBox>
  );
};

export default Breadcrumbs;
