import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  misAdmin: { token: null },
  user: {
    token: null,
    authToken: null,
    sid: null,
    sidExp: null,
    id: null,
    role: null,
    details: {},
    modulePermissions: {},
    dataPermissions:{}
  },
  org: {
    name: "",
    setupStage: 0,
  },
  latestTraining: null,
  column_details: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAdmin: (state, action) => {
      state.misAdmin = action.payload;
    },
    resetUser: (state) => {
      state.user = initialState.user;
      state.latestTraining = initialState.latestTraining;
      state.org = initialState.org;
    },
    resetAdmin: (state) => {
      state.misAdmin = initialState.misAdmin;
    },
    setOrgDetails: (state, action) => {
      state.org.name = action.payload.name;
      state.org.setupStage = action.payload.setup_stage;
      state.org.modulePermissions = action.payload.modulePermissions;
    },
    setLatestTraining: (state, action) => {
      state.latestTraining = action.payload;
    },
    setColDetails: (state, action) => {
      state.column_details = action.payload;
    },
  },
});

export const {
  setUser,
  resetUser,
  setOrgDetails,
  setLatestTraining,
  setColDetails,
  setAdmin,
  resetAdmin,
} = authSlice.actions;

export const selectUserData = (state) => state.auth.user;
export const selectDataPermissions = (state) => state.auth.user.dataPermissions;
export const selectToken = (state) => state.auth.user.token;
export const selectSetupStage = (state) => state.auth.org.setupStage;
export const selectOrg = (state) => state.auth.org;
export const selectLatestTraining = (state) => state.auth.latestTraining;
export const selectColDetails = (state) => state.auth.column_details;
export const selectAdminData = (state) => state.auth.misAdmin;
export const selectAdminToken = (state) => state.auth.misAdmin.token;

export default authSlice.reducer;
