// import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "./../../../store/slice/auth";
import { fetchTrainings } from "./../../../api/index";
import Banner from "./../../Global/Banner/Banner";
import { FlexBox } from "./../../../theme/commonStyles";
import DataRefreshTrainingSelect from "./../../PayEquity/DataRefreshTrainingSelect";
import LayoutDashboard from "../../Layout/LayoutDashboard";
// import FilterPane from "../../PayEquity/FilterPane";
import SaveReportTables from "../../PayEquity/SaveReportTables";

const Report = () => {
  const latestTraining = useSelector(selectLatestTraining);
  const [selectedTraining, setSelectedTraining] = useState(latestTraining);
  const [trainings, setTrainings] = useState([]);
  // const [filters, setFilters] = useState({});
  // const [trainingFieldsData, setTrainingFieldsData] = useState([]);
  // const fetchTrainingFields = useCallback(async () => {
  //   const res = await getTrainingFields(latestTraining.id);
  //   const data = res.fields.filter(
  //     (item) =>
  //       latestTraining.uploaded_file_detail.extracted_columns.includes(
  //         item.name
  //       ) && !item.is_continous
  //   );
  //   setTrainingFieldsData(data);
  // }, [
  //   latestTraining.id,
  //   latestTraining.uploaded_file_detail.extracted_columns,
  // ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTrainings();
      if (data) {
        const result = data.filter((item) => item.status === "Success");
        setTrainings(result);
      }
    };
    fetchData();
    // fetchTrainingFields();
  }, []);

  return (
    <LayoutDashboard>
      <Banner heading={`Pay Analytics - Reports`} />
      <FlexBox col p={4} pt={1}>
        <DataRefreshTrainingSelect
          trainings={trainings}
          selectedTraining={selectedTraining}
          setSelectedTraining={setSelectedTraining}
        />
        <SaveReportTables
          heading="Report"
          latestTraining={latestTraining}
          filters={{}}
        />
        {/* <Stack mt={3} direction="row" spacing={3} alignItems="flex-start">
          <FilterPane
            latestTraining={selectedTraining}
            filters={filters}
            setFilters={setFilters}
            trainingFieldsData={trainingFieldsData}
          />
          <Stack direction="column" sx={{ flex: 1 }}>
          
          </Stack>
        </Stack> */}
      </FlexBox>
    </LayoutDashboard>
  );
};

export default Report;
