import React, { useState } from "react";

export const ModalContext = React.createContext();

const ModalContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <ModalContext.Provider
      value={{
        isOpen,
        toggleModal,
        handleOpen,
        handleClose,
      }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
