import { flexStyles } from "../../theme/commonStyles";

export const styles = (theme) => ({
  leftPaneWrapper: {
    width: "20vw",
    minHeight: "100vh",
    background: "#fff",
    padding: "2rem 1rem",
  },
  openCard: {
    height: "auto",
    overflow: "hidden",
    transition: "transform 0.3s ease-out",
    transform: "scaleY(1)",
    transformOrigin: "top",
  },
  closedCard: {
    transition: "transform 0.3s ease-out",
    transform: "scaleY(0)",
    height: 0,
  },
  payRangeResultWrapper: {
    height: "92vh",
    overflowY: "scroll",
    background: "#f5f5f5",
    padding: "2rem",
    width: "calc(100% - 320px)",
    "&::-webkit-scrollbar": {
      // display: "none",
      background: "transparent",
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
    },
  },
  formControl: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  compensableFactorCard: {
    width: "30%",
    background: theme.colors.text1,
    boxShadow: "4px 4px 10px #D9D9D9",
  },
  dataCards: {
    background: theme.colors.text1,
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.15)",
    flex: 1,
  },
  dataCardsContentBox: {
    flex: 1,
    marginTop: "1rem",
    ...flexStyles("col"),
    "&:first-child": {
      borderRight: "2px solid #A8A8A8",
    },
  },
  recommendationContextBox: {
    flex: 1,
    ...flexStyles("col"),
  },
  graphWrapper: {
    width: "100%",
    height: "104px",
    background: "rgba(142, 210, 235, 0.3)",
    backgroundClip: "content-box",
    position: "relative",
    borderLeft: "2px solid rgba(168, 168, 168, 1)",
    borderRight: "2px solid rgba(168, 168, 168, 1)",
    padding: "16px 0",
  },
  recommendedQuadrant: {
    height: "104px",
    top: 0,
    background: theme.colors.primary,
    position: "absolute",
    borderLeft: "2px solid rgba(168, 168, 168, 1)",
    borderRight: "2px solid rgba(168, 168, 168, 1)",
    padding: "16px 0",
    backgroundClip: "content-box",
  },
  rLeftText: {
    position: "absolute",
    top: "100%",
    left: "0",
  },
  rRightText: {
    position: "absolute",
    top: "100%",
    right: "0",
  },
  rDivider: {
    width: "10px",
    height: "100%",
    position: "absolute",
    top: "0",
    borderLeft: "6px dashed #fff",
    zIndex: 2,
  },
  rDivider2: {
    width: "10px",
    left: "10px",
    height: "100%",
    position: "absolute",
    top: "0",
    borderLeft: "2px solid rgba(168, 168, 168, 1)",
  },
  rDivider3: {
    width: "10px",
    right: 0,
    height: "100%",
    position: "absolute",
    top: "0",
    borderLeft: "2px solid rgba(168, 168, 168, 1)",
  },
  tableHead: {
    padding: "10px",
    background: "#F4F7FB",
    borderRadius: `5px 5px 0 0`,

    "& th": {
      borderBottom: "none",
    },
  },
  tableRow: {
    "& td": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#A8A8A8",
      textAlign: "center",
      borderBottom: "none",
      border: "1px solid #D9D9D9",
      wordWrap: "break-word",
    },
  },
});
