import React from "react";
import useClasses from "../../../hooks/useClasses";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import LayoutDashboard from "../../Layout/LayoutDashboard";
import { styles } from "./trialEnd.styles";
import { ReactComponent as TrialEnded } from "../../../assets/trial-ended.svg";
import { useNavigate } from "react-router-dom";

const TrialEnd = () => {
  const classes = useClasses(styles);
  const navigate = useNavigate();

  return (
    <LayoutDashboard>
      <FlexBox col alignItems="center" justifyContent="center" height="80vh">
        <FlexBox col alignItems="center" justifyContent="center" mt={3}>
          <StyledText variant="h5">Your Trial has ended</StyledText>
          <FlexBox alignItems="center">
            <StyledText variant="caption">contact us at</StyledText>&nbsp;
            <StyledText
              variant="caption"
              color="primary"
              fontWeight="bold"
              component="a"
              href="mailto:support@paylens.com"
              sx>
              support@paylens.com
            </StyledText>
          </FlexBox>
          <StyledDivider my={2} />
          <StyledButton
            onClick={() => {
              navigate("/config?tabValue=4");
            }}
            sx={{
              mb: 2,
            }}>
            Extend License
          </StyledButton>
        </FlexBox>
        <TrialEnded className={classes.heroImg} />
      </FlexBox>
    </LayoutDashboard>
  );
};

export default TrialEnd;
