import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUser,
  selectSetupStage,
  selectUserData,
  setLatestTraining,
} from "../store/slice/auth";
import { latestTrainings, logout } from "../api";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { getCookie } from "../helpers/utils";

function RequireAuth({ allowedRoles }) {
  const auth = useSelector(selectUserData);
  const org = useSelector(selectSetupStage);
  const location = useLocation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const sessionId = getCookie("sessionid");

  const handleLogout = useCallback(async () => {
    await logout();
    dispatch(resetUser());
  }, [dispatch]);

  useEffect(() => {
    if (!sessionId) {
      handleLogout();
    }
  }, [handleLogout, sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      const latestTraining = await latestTrainings();
      if (latestTraining?.code === "trial_expired")
        nav("/trial-end", {
          state: {
            from: location,
            reRender: false,
          },
          replace: true,
        });
      if (latestTraining && latestTraining?.data?.training_params) {
        dispatch(
          setLatestTraining({
            ...latestTraining.data,
            fields: latestTraining.fields,
            unique_values: latestTraining.unique_values_of_columns,
          })
        );
      }
    };
    if (auth?.token && org !== 0) fetchData();
  }, [auth?.token, dispatch, location, nav, org]);

  return allowedRoles?.includes(auth?.role) && sessionId ? (
    <Outlet />
  ) : auth?.token && sessionId ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default React.memo(RequireAuth);
