import { Box, Stack } from "@mui/material";
import React from "react";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import useClasses from "../../../hooks/useClasses";
import { styles } from "../home.styles";
import ReactSpeedometer from "react-d3-speedometer";
import Skeleton from "react-loading-skeleton";
import { clsx } from "clsx";

const PayParitySpeedometer = ({
  percentileValue = 0,
  loading,
  isDark = false,
  customHeight,
  customWidth,
}) => {
  const classes = useClasses(styles);
  return (
    <Stack
      direction="column-reverse"
      className={clsx(classes.cardWrapper, isDark && classes.cardWrapperDark)}>
      <Box className={classes.textWrapper} flex={1} textAlign="center">
        <StyledText fs={32} clr="text3">
          Pay Parity
        </StyledText>
        <StyledText fs={62} clr="primary">
          {loading ? <Skeleton /> : `${percentileValue}%`}
        </StyledText>
      </Box>
      {/* <StyledDivider /> */}
      <FlexBox
        flex={1}
        flexcenter="true"
        sx={{
          width: "100%",
          height: "auto",
          "& .current-value": {
            display: "none",
          },
        }}>
        {loading ? (
          <Skeleton style={{ width: "400px", height: "220px" }} />
        ) : (
          <ReactSpeedometer
            maxValue={100}
            value={percentileValue}
            needleColor="#7a635f"
            segments={4}
            segmentColors={["#ce3125", "#d99737", "#f3c15f", "#4da633"]}
            width={customWidth || 320}
            height={customHeight || 185}
            needleHeightRatio={0.7}
            currentValueText={""}
            customSegmentLabels={[
              {
                text: "",
                position: "INSIDE",
              },
              {
                text: "",
                position: "INSIDE",
              },
              {
                text: "",
                position: "INSIDE",
              },
              {
                text: "",
                position: "INSIDE",
              },
            ]}
          />
        )}
      </FlexBox>
    </Stack>
  );
};

export default PayParitySpeedometer;
