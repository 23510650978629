import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";

import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import {
  FlexBox,
  StyledDivider,
  StyledSelect,
  StyledText,
} from "../../../theme/commonStyles";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "../../../store/slice/auth";
import { fetchOptionsForVariable, getTrainingFields } from "../../../api";
import Skeleton from "react-loading-skeleton";
import DeleteIcon from "@mui/icons-material/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataModule-tabpanel-${index}`}
      aria-labelledby={`dataModule-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `dataModule-tab-${index}`,
    "aria-controls": `dataModule-tabpanel-${index}`,
  };
}

const DataModule = ({
  dataPermission,
  setDataPermission,
  modulePermissions = {},
  setModulePermissions,
  updateData,
}) => {
  const [value, setValue] = useState(0);
  const latestTraining = useSelector(selectLatestTraining);
  const [isAddOptionDialogOpen, setIsAddOptionDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultVariable, setDefaultVariable] = useState([]);
  const [extraVariables, setExtraVariables] = useState([]);
  const [continuousExtraVariables, setContinuousExtraVariables] = useState([]);
  const [variablesOptions, setVariablesOptions] = useState({});
  const [variablesOptions2, setVariablesOptions2] = useState({});
  const [isContinuousCol, setIsContinuousCol] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dialogToggleHandler = () => {
    setIsAddOptionDialogOpen(!isAddOptionDialogOpen);
  };

  const fetchTrainingColumns = useCallback(async () => {
    const data = await getTrainingFields(latestTraining.id);
    const continuousCol = data.fields
      .filter((item) =>
        latestTraining?.uploaded_file_detail?.extracted_columns.includes(
          item.name
        )
      )
      .filter((item) => item.is_continous)
      .map((item) => item.name);
    if (updateData) {
      const temp = Object.keys(dataPermission).sort();
      const defaults = temp.filter((item) =>
        latestTraining?.training_params.selectedVariables.includes(item)
      );
      const extrasIsContinuous = temp
        .filter(
          (item) =>
            !latestTraining?.training_params.selectedVariables.includes(item)
        )
        .filter((item) => continuousCol.includes(item));
      const extrasNonContinuous = temp
        .filter(
          (item) =>
            !latestTraining?.training_params.selectedVariables.includes(item)
        )
        .filter((item) => !continuousCol.includes(item));

      setDefaultVariable(defaults);
      setExtraVariables(extrasNonContinuous);
      setContinuousExtraVariables(extrasIsContinuous);
    } else {
      setDefaultVariable(latestTraining?.training_params.selectedVariables);
    }
    setIsContinuousCol(continuousCol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    latestTraining.id,
    latestTraining?.uploaded_file_detail?.extracted_columns,
    updateData,
  ]);

  const fetchValues = useCallback(async () => {
    setLoading(true);
    let promises = [];
    defaultVariable.forEach((item) => {
      promises.push(fetchOptionsForVariable(latestTraining.id, item, {}));
    });
    const optionsData = await Promise.all(promises);
    if (optionsData[0]) {
      setVariablesOptions(optionsData);
      const temp = {};
      defaultVariable.forEach((item, i) => {
        temp[item] = optionsData[i].values;
      });
      if (Object.keys(dataPermission).length === 0)
        setDataPermission({
          ...dataPermission,
          ...temp,
        });
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestTraining.id, defaultVariable]);

  const fetchValuesForAddedColumns = useCallback(async () => {
    setLoading(true);
    let promises = [];
    extraVariables.forEach((item) => {
      promises.push(fetchOptionsForVariable(latestTraining.id, item, {}));
    });
    const optionsData = await Promise.all(promises);
    if (optionsData[0]) {
      setVariablesOptions2(optionsData);
      const temp = {};
      extraVariables.forEach((item, i) => {
        if (!Object.keys(dataPermission).includes(item))
          temp[item] = optionsData[i].values;
      });
      setDataPermission({
        ...dataPermission,
        ...temp,
      });
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraVariables, latestTraining.id]);

  useEffect(() => {
    fetchTrainingColumns();
  }, [fetchTrainingColumns]);

  useEffect(() => {
    if (latestTraining.id) fetchValues();
  }, [fetchValues, latestTraining.id]);

  useEffect(() => {
    if (extraVariables.length > 0) fetchValuesForAddedColumns();
  }, [extraVariables, fetchValuesForAddedColumns]);

  function SimpleDialog() {
    const [selectedColumn, setSelectedColumn] = useState("");
    const handleConfirm = () => {
      if (isContinuousCol.includes(selectedColumn)) {
        setContinuousExtraVariables([
          ...continuousExtraVariables,
          selectedColumn,
        ]);
        setDataPermission({
          ...dataPermission,
          [selectedColumn]: [],
        });
      } else {
        setExtraVariables([...extraVariables, selectedColumn]);
      }
      dialogToggleHandler();
    };

    const col = useMemo(() => {
      if (updateData) {
        const allVar = defaultVariable.concat(extraVariables);
        const temp =
          latestTraining?.uploaded_file_detail?.extracted_columns.filter(
            (item) => !allVar.includes(item)
          );
        return temp;
      }
      const temp =
        latestTraining?.uploaded_file_detail?.extracted_columns.filter(
          (item) =>
            !latestTraining?.training_params.selectedVariables.includes(item)
        );
      return temp;
    }, []);
    return (
      <Dialog
        onClose={dialogToggleHandler}
        open={isAddOptionDialogOpen}
        sx={{
          "& .MuiPaper-root": {
            width: "300px",
          },
        }}>
        <DialogTitle sx={{ py: 2 }}>
          <StyledText fs={18} clr="primary" textAlign="center">
            Select Column
          </StyledText>
        </DialogTitle>
        <StyledDivider />
        <DialogContent>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel sx={{ left: "-14px" }} id={`variable-field-label`}>
              Select Column
            </InputLabel>
            <StyledSelect
              size="small"
              labelId={`variable-field-label`}
              id={`variable-field-select`}
              label="Select Column"
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              name={"Select Column"}
              variant="standard">
              {col.length > 0 ? (
                col.map((column, i) => (
                  <MenuItem key={i} value={column}>
                    {column}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Options</MenuItem>
              )}
            </StyledSelect>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={dialogToggleHandler}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }

  function DataForm() {
    return (
      <Box my={2} sx={{ height: "300px", overflowY: "auto" }} pt={2}>
        {isAddOptionDialogOpen && <SimpleDialog />}
        <StyledText fs={14} clr="text3">
          Selected Columns
        </StyledText>
        <StyledDivider mb={1} />
        {defaultVariable.length > 0 &&
          defaultVariable.map((data, i) => (
            <Grid container spacing={0.5} mb={2} alignItems="center" key={i}>
              <Grid item xs={3}>
                <StyledText fontWeight={600} sx={{ wordBreak: "break-word" }}>
                  {data}
                </StyledText>
              </Grid>
              <Grid item xs={9}>
                {loading ? (
                  <Box flex={1}>
                    <Skeleton />
                  </Box>
                ) : (
                  <FormControl sx={{ mt: 2 }} fullWidth key={i}>
                    <InputLabel
                      sx={{ left: "-14px" }}
                      id={`variable-field-label-${i}`}>
                      Select Value
                    </InputLabel>
                    <StyledSelect
                      size="small"
                      multiple
                      labelId={`variable-field-label-${i}`}
                      id={`variable-field-select-${i}`}
                      label={" Select Value"}
                      value={dataPermission[data] || []}
                      onChange={(e) => {
                        setDataPermission({
                          ...dataPermission,
                          [data]: e.target.value,
                        });
                      }}
                      name={data}
                      variant="standard"
                      renderValue={(selected) => selected.join(", ")}>
                      {variablesOptions[i] ? (
                        variablesOptions[i].values.map((column, i) => (
                          <MenuItem key={i} value={column}>
                            <Checkbox
                              checked={
                                (dataPermission[data] || []).indexOf(column) >
                                -1
                              }
                            />
                            <ListItemText primary={column} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={i}>No Options</MenuItem>
                      )}
                    </StyledSelect>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          ))}
        {(extraVariables.length > 0 || continuousExtraVariables.length > 0) && (
          <Box mt={4} mb={3}>
            <StyledText fs={14} clr="text3">
              Extracted Columns
            </StyledText>
            <StyledDivider />
          </Box>
        )}
        {extraVariables.length > 0 &&
          extraVariables.map((data, i) => (
            <Grid container spacing={0.5} mb={2} alignItems="center" key={i}>
              <Grid item xs={3}>
                <StyledText fontWeight={600} sx={{ wordBreak: "break-word" }}>
                  {data}
                </StyledText>
              </Grid>
              <Grid item xs={8}>
                {loading ? (
                  <Box flex={1}>
                    <Skeleton />
                  </Box>
                ) : (
                  <FormControl fullWidth key={i} sx={{ flex: 1 }}>
                    <InputLabel
                      sx={{ left: "-14px" }}
                      id={`variable-field-label-${i}`}>
                      Select Value
                    </InputLabel>
                    <StyledSelect
                      size="small"
                      multiple
                      labelId={`variable-field-label-${i}`}
                      id={`variable-field-select-${i}`}
                      label={" Select Value"}
                      value={dataPermission[data] || []}
                      onChange={(e) => {
                        setDataPermission({
                          ...dataPermission,
                          [data]: e.target.value,
                        });
                      }}
                      name={data}
                      variant="standard"
                      renderValue={(selected) => selected.join(", ")}>
                      {variablesOptions2[i] ? (
                        variablesOptions2[i].values.map((column, i) => (
                          <MenuItem key={i} value={column}>
                            <Checkbox
                              checked={
                                (dataPermission[data] || []).indexOf(column) >
                                -1
                              }
                            />
                            <ListItemText primary={column} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={i}>No Options</MenuItem>
                      )}
                    </StyledSelect>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    const temp = extraVariables.filter(
                      (_, index) => index !== i
                    );
                    const temp2 = { ...dataPermission };
                    delete temp2[data];
                    setExtraVariables(temp);
                    setDataPermission(temp2);
                  }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        {continuousExtraVariables.length > 0 &&
          continuousExtraVariables.map((data, i) => (
            <Grid container spacing={0.5} mb={2} alignItems="center" key={i}>
              <Grid item xs={3}>
                <StyledText fontWeight={600} sx={{ wordBreak: "break-word" }}>
                  {data}
                </StyledText>
              </Grid>
              <Grid item xs={8}>
                <StyledText clr="primary" fs={14}>
                  No unique values available..
                </StyledText>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    const temp = continuousExtraVariables.filter(
                      (_, index) => index !== i
                    );
                    const temp2 = { ...dataPermission };
                    delete temp2[data];
                    setContinuousExtraVariables(temp);
                    setDataPermission(temp2);
                  }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        <FlexBox justifyContent="flex-end" width="100%" mt={4}>
          <Button
            onClick={dialogToggleHandler}
            size="small"
            fs={14}
            clr="primary"
            variant="outlined"
            sx={{
              borderRadius: "5px",
            }}>
            Add Column
          </Button>
        </FlexBox>
      </Box>
    );
  }

  const ModuleForm = () => {
    const handleModulePermission = (e, module_name, submodule_name) => {
      const temp = JSON.parse(JSON.stringify(modulePermissions));
      if (!e.target.checked) {
        // temp[e.target.name]
        let tempArr = [];
        if (temp[module_name]) {
          tempArr = [...temp[module_name]];
        }
        temp[module_name] = tempArr.filter((item) => item !== submodule_name);
        if (temp[module_name].length === 0) delete temp[module_name];
        setModulePermissions(temp);
      } else {
        let tempArr = [];
        if (temp[module_name]) {
          tempArr = [...temp[module_name]];
        }
        tempArr.push(submodule_name);
        temp[module_name] = tempArr;
        setModulePermissions(temp);
      }
    };

    return (
      <Box my={2} sx={{ height: "300px", overflowY: "auto" }} px={2}>
        <Stack direction={"column"}>
          <FormControlLabel
            label="Pay Ranges"
            control={
              <Checkbox
                checked={
                  (modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].length === 5) ||
                  false
                }
                indeterminate={
                  (modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].length < 5) ||
                  false
                }
                onChange={(e) => {
                  if (!e.target.checked) {
                    const temp = { ...modulePermissions };
                    delete temp["Ranges"];
                    setModulePermissions(temp);
                  } else {
                    setModulePermissions({
                      ...modulePermissions,
                      Ranges: [
                        "Hiring",
                        "Transfer",
                        "Retention",
                        "Promotion",
                        "Role Change",
                      ],
                    });
                  }
                }}
              />
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <FormControlLabel
              label="Hiring"
              control={
                <Checkbox
                  name="Ranges"
                  size="small"
                  checked={
                    modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].includes("Hiring")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Ranges", "Hiring")
                  }
                />
              }
            />
            <FormControlLabel
              label="Transfer"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].includes("Transfer")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Ranges", "Transfer")
                  }
                />
              }
            />
            <FormControlLabel
              label="Retention"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].includes("Retention")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Ranges", "Retention")
                  }
                />
              }
            />
            <FormControlLabel
              label="Promotion"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].includes("Promotion")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Ranges", "Promotion")
                  }
                />
              }
            />
            <FormControlLabel
              label="Role Change"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Ranges"] &&
                    modulePermissions["Ranges"].includes("Role Change")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Ranges", "Role Change")
                  }
                />
              }
            />
          </Box>
          <StyledDivider my={2} />
          <FormControlLabel
            label="Pay Analytics"
            control={
              <Checkbox
                checked={
                  (modulePermissions["Analytics"] &&
                    modulePermissions["Analytics"].length === 2) ||
                  false
                }
                indeterminate={
                  (modulePermissions["Analytics"] &&
                    modulePermissions["Analytics"].length < 2) ||
                  false
                }
                // indeterminate={checked[0] !== checked[1]}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const temp = { ...modulePermissions };
                    delete temp["Analytics"];
                    setModulePermissions(temp);
                  } else {
                    setModulePermissions({
                      ...modulePermissions,
                      Analytics: ["Optimizer", "Report"],
                    });
                  }
                }}
              />
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <FormControlLabel
              label="Spend Optimizer"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Analytics"] &&
                    modulePermissions["Analytics"].includes("Optimizer")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Analytics", "Optimizer")
                  }
                />
              }
            />
            <FormControlLabel
              label="Reports"
              control={
                <Checkbox
                  size="small"
                  checked={
                    modulePermissions["Analytics"] &&
                    modulePermissions["Analytics"].includes("Report")
                  }
                  onChange={(e) =>
                    handleModulePermission(e, "Analytics", "Report")
                  }
                />
              }
            />
          </Box>
          <StyledDivider my={2} />
          <FormControlLabel
            label="Pay Equity"
            control={
              <Checkbox
                checked={modulePermissions["Equity"]}
                // indeterminate={checked[0] !== checked[1]}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const temp = { ...modulePermissions };
                    delete temp["Equity"];
                    setModulePermissions(temp);
                  } else {
                    setModulePermissions({
                      ...modulePermissions,
                      Equity: [],
                    });
                  }
                }}
              />
            }
          />
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        background: "#F4F7FB",
        boxShadow: "4px 4px 10px #D9D9D9",
        p: 2,
        mt: 3,
      }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="data module tabs example">
          <Tab label="Data" {...a11yProps(0)} />
          <Tab label="Module" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DataForm
          dataPermission={dataPermission}
          setDataPermission={setDataPermission}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ModuleForm />
      </TabPanel>
    </Box>
  );
};

export default DataModule;
