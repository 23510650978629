import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  predictedResult: null,
};

export const payRangesSlice = createSlice({
  name: "payRanges",
  initialState,
  reducers: {
    setPredictedResult: (state, action) => {
      state.predictedResult = action.payload;
    },
  },
});

export const { setPredictedResult } = payRangesSlice.actions;

export const selectPredictedResult = (state) => state.payRanges.predictedResult;

export default payRangesSlice.reducer;
