export const loadState = () => {
  try {
    const persistedStateString = localStorage.getItem("state");
    if (persistedStateString) {
      return JSON.parse(persistedStateString);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    localStorage.setItem("state", JSON.stringify(state));
  } catch (err) {
    console.error(err);
  }
};
