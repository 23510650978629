import { Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "../../../store/slice/auth";
import { fetchTrainings, getTrainingFields } from "../../../api/index";
import Banner from "../../Global/Banner/Banner";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import DataRefreshTrainingSelect from "../../PayEquity/DataRefreshTrainingSelect";
import LayoutDashboard from "../../Layout/LayoutDashboard";
import FilterPane from "../../PayEquity/FilterPane";
import PerformanceGrid from "./PerformanceGrid";
import EmployeeDetails from "./EmployeeDetails";
import Overview from "./Overview";
import { getSpendOptimizerTotals } from "./../../../api/index";
import useClasses from "./../../../hooks/useClasses";
import { styles } from "../styles";
import PayEquityMainData from "../../PayEquity/PayEquityMainData";
import PayEquityGraphData from "../../PayEquity/PayEquityGraphData";

const SalaryOptimizer = () => {
  const classes = useClasses(styles);
  const latestTraining = useSelector(selectLatestTraining);
  const [selectedTraining, setSelectedTraining] = useState(latestTraining);
  const [trainings, setTrainings] = useState([]);
  const [filters, setFilters] = useState({});
  const [trainingFieldsData, setTrainingFieldsData] = useState([]);
  const [selectedTile, setSelectedTile] = useState(null);
  const [data, setData] = useState({
    total_employee: 0,
    total_comp_spend: 0,
    total_P1_spend: 0,
    total_P2_spend: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchTrainingFields = useCallback(async () => {
    const res = await getTrainingFields(latestTraining.id);
    const data = res.fields.filter(
      (item) =>
        latestTraining.uploaded_file_detail.extracted_columns.includes(
          item.name
        ) && !item.is_continous
    );
    setTrainingFieldsData(data);
  }, [
    latestTraining?.id,
    latestTraining?.uploaded_file_detail.extracted_columns,
  ]);

  const fetchOverviewData = useCallback(async () => {
    const res = await getSpendOptimizerTotals(latestTraining.id);

    if (res) {
      setData(res.data);
    }
    setLoading(false);
  }, [latestTraining?.id]);

  const fetchTrainingsAPI = useCallback(async () => {
    const data = await fetchTrainings();
    if (data) {
      const result = data.filter((item) => item.status === "Success");
      setTrainings(result);
    }
  }, []);

  useEffect(() => {
    fetchTrainingsAPI();
    fetchTrainingFields();
    fetchOverviewData();
  }, [fetchOverviewData, fetchTrainingFields, fetchTrainingsAPI]);

  return (
    <LayoutDashboard>
      <Banner heading={`Pay Analytics - Spend Optimizer`} />
      <FlexBox col p={4} pt={1} pb={0}>
        <DataRefreshTrainingSelect
          trainings={trainings}
          selectedTraining={selectedTraining}
          setSelectedTraining={setSelectedTraining}
        />
        <PayEquityMainData
          latestTraining={selectedTraining}
          isPayAnalytics={true}
        />
        <Stack
          mt={5}
          direction="row"
          spacing={4}
          alignItems="flex-start"
          sx={{ height: "calc(100vh - 70px)", overflow: "hidden" }}>
          <FilterPane
            latestTraining={selectedTraining}
            filters={filters}
            setFilters={setFilters}
            trainingFieldsData={trainingFieldsData}
          />
          <Stack direction="column" className={classes.dataWrapper}>
            <PayEquityGraphData
              latestTraining={selectedTraining}
              filters={filters}
              trainingFieldsData={trainingFieldsData}
              showReports={false}
              isPayAnalytics
            />
            <Stack direction="row" spacing={3} mt={4}>
              <Overview
                latestTraining={selectedTraining}
                data={data}
                loading={loading}
              />
              <PerformanceGrid
                latestTraining={selectedTraining}
                filters={filters}
                setSelectedTile={setSelectedTile}
                totalData={data}
              />
            </Stack>
            {selectedTile ? (
              <EmployeeDetails
                latestTraining={latestTraining}
                filters={filters}
                tile={selectedTile}
              />
            ) : (
              <StyledText textAlign="center" mt={4} fs={18}>
                Please Click on values to see the employees tables
              </StyledText>
            )}
          </Stack>
        </Stack>
      </FlexBox>
    </LayoutDashboard>
  );
};

export default SalaryOptimizer;
