import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Card from "../../Global/Card/Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledDivider, StyledText } from "../../../theme/commonStyles";
import { fetchOrgDetails, getAllTrainingModels } from "./../../../api/index";

const TrainingDetails = ({ trainings = [] }) => {
  const [trainingModelData, setTrainingModelData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bestModelUsed, setBestModelUsed] = useState(null);

  const fetchTrainingModels = useCallback(async (id, page) => {
    setLoading(true);
    const getData = await fetchOrgDetails();
    const data = await getAllTrainingModels(id, page);
    if (data && getData) {
      page === 1
        ? setTrainingModelData(data.results)
        : setTrainingModelData((dt) => [...dt, ...data.results]);
      setTotalRowCount(data.count);
      setBestModelUsed({
        ...getData.config.best_model,
        models_ranked_by: getData.config.models_ranked_by,
      });
    }
    setLoading(false);
  }, []);

  const columns = useMemo(() => {
    if (trainingModelData.length === 0) return [];
    return Object.keys(trainingModelData[0]);
  }, [trainingModelData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Card heading="Training Details">
      <Box mt={2}>
        <Stack direction="row" width="100%" mb={2}>
          <StyledText flex={1} fs={15} ml={2} fontWeight="bold">
            Initiated On
          </StyledText>
          <StyledText flex={1} fs={15} ml={-5} fontWeight="bold">
            Status
          </StyledText>
        </Stack>
        <Box>
          {trainings.map((training, i) => (
            <Accordion
              key={i}
              elevation={0}
              sx={{
                borderRadius: "10px !important",
                backgroundColor: "transparent",
                mb: 2,
                "&::before": {
                  backgroundColor: "transparent",
                },
                "&::after": {
                  backgroundColor: "transparent",
                },
              }}
              onChange={(e, isExpanded) =>
                isExpanded && fetchTrainingModels(training.id, currentPage + 1)
              }>
              <AccordionSummary
                sx={{ background: "#fff", borderRadius: "10px" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Stack direction="row" width="100%">
                  <StyledText flex={1} clr="text3">
                    {new Date(training.modified).toLocaleString()}
                  </StyledText>
                  <StyledText flex={1} clr="text3">
                    {training.status}
                  </StyledText>
                </Stack>
              </AccordionSummary>
              <StyledDivider mb={1} />
              <AccordionDetails>
                <Box
                  sx={{
                    boxShadow: "4px 4px 10px #D9D9D9",
                    borderRadius: "20px",
                    background: "#fff",
                  }}
                  pt={2}>
                  <StyledText clr="text3" textAlign="center">
                    All Training Models
                  </StyledText>
                  <StyledDivider mt={1} mb={-1} />
                  <div style={{ maxHeight: 500, width: "100%" }}>
                    <TableContainer>
                      {loading && (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      )}

                      <Table aria-label="trainings list">
                        <TableHead>
                          <TableRow>
                            {columns.map((item, i) => (
                              <TableCell
                                key={i}
                                sx={{
                                  fontWeight: "bold",
                                  textTransform:
                                    i === 0 ? "capitalize" : "uppercase",
                                  color:
                                    item === bestModelUsed?.models_ranked_by
                                      ? "#BBD2EE"
                                      : "unset",
                                }}>
                                {item}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trainingModelData.length > 0 ? (
                            trainingModelData.map((model, i) => (
                              <TableRow
                                key={i}
                                sx={{
                                  background:
                                    model.name === bestModelUsed?.name
                                      ? "#BBD2EE"
                                      : "unset",
                                }}>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {model.name}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {model.mae}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {model.mse}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {model.r2}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {model.mape}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  sx={{
                                    color:
                                      model.name === bestModelUsed?.name
                                        ? "#fff"
                                        : "unset",
                                  }}>
                                  {Math.round(model.rmse)}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <StyledText
                                  fontWeight="bold"
                                  textAlign="center">
                                  No Models Found
                                </StyledText>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                        {totalRowCount > 10 && (
                          <TableFooter>
                            <TablePagination
                              count={totalRowCount}
                              page={currentPage}
                              onPageChange={(page) => setCurrentPage(page)}
                              rowsPerPage={rowsPerPage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              rowsPerPageOptions={[10, 15]}
                            />
                          </TableFooter>
                        )}
                      </Table>
                    </TableContainer>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export default React.memo(TrainingDetails);
