import React, { useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import InternalDataFileUpload from "../InternalDataConfig/InternalDataFileUpload";
import { uploadLicenseFile } from "../../../api";
import moment from "moment";
import ErrorService from "../../../helpers/ErrorService";

const LicenseUpload = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async () => {
    setIsUploading(true);
    try {
      const data = await uploadLicenseFile(uploadedFile);
      if (data) {
        const date_1 = moment(data.trial_valid_till);
        const date_2 = moment();
        const diff = date_1.diff(date_2, "minutes");
        if (diff > 0) {
          ErrorService.sendOkMessage(
            `Your validity is extended till ${moment(
              data.trial_valid_till
            ).format("MMM Do YYYY")}`
          );
          setUploadedFile(null);
        } else {
          ErrorService.sendErrorMessage({
            message: " The license date is expired",
          });
        }
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box>
      <StyledText variant="h5" fontWeight={500}>
        License Upload
      </StyledText>
      <StyledDivider mb={4} />
      {isUploading && <LinearProgress />}

      <InternalDataFileUpload
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        setIsUploading={setIsUploading}
        handleFileUpload={() => {}}
      />
      <FlexBox sx={{ mt: 2 }} justifyContent="flex-end">
        <StyledButton
          disabled={!uploadedFile}
          loading={isUploading}
          onClick={handleFileUpload}>
          Extend License
        </StyledButton>
      </FlexBox>
    </Box>
  );
};

export default LicenseUpload;
