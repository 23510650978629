import React, { useCallback, useEffect, useState } from "react";
import Card from "../../Global/Card/Card";
import { styles } from "../styles";
import useClasses from "../../../hooks/useClasses";
import { getPayEquityReport } from "../../../api/index";
import { changeColumnName } from "../../../helpers/utils";
import StyledDataGrid from "../../DataGrids/StyledDataGrid";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import jsPDF from "jspdf";

const EmployeeDetails = ({ latestTraining, filters, tile }) => {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const classes = useClasses(styles);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await getPayEquityReport(latestTraining.id, {
      filters: filters,
      fields: [],
      tile: tile || {},
      currentPage: 1,
    });
    if (res) {
      // need to find some better approach...
      const temp3 = res.data.map((item) => {
        const obj = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            obj[changeColumnName(key)] = item[key];
          }
        }
        return obj;
      });
      setReportData(temp3);
    }
    setLoading(false);
  }, [filters, latestTraining.id, tile]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const excelDownloadHandler = () => {
    if (reportData.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(reportData);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Employee Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "Employee.xlsx");
    }
  };

  const pdfDownloadHandler = async () => {
    const pdf = new jsPDF("l", "pt", "a3");
    const columns = Object.keys(reportData[0]);
    let rows = [];
    for (let i = 0; i < reportData.length; i++) {
      var temp = Object.values(reportData[i]);
      rows.push(temp);
    }
    pdf.text(500, 40, "Grid Analysis");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    pdf.save("GridAnalysis_a3");
  };

  return (
    <Card
      customContainerCss={{ mt: 2 }}
      heading={"Grid Analysis"}
      customCssClassName={classes.graphCard}
      showDownload
      downloadHandler={excelDownloadHandler}
      pdfDownloadHandler={pdfDownloadHandler}>
      <StyledDataGrid data={reportData} loading={loading} />
    </Card>
  );
};

export default EmployeeDetails;
