import BannerPng from "../../../assets/bannerBg.png";

export const styles = (theme) => ({
  bannerWrapper: {
    width: "100%",
    height: "20vh",
    backgroundImage: `linear-gradient(105.78deg, rgba(142, 210, 235,0.7) 2.22%, rgba(181, 188, 255,0.7) 100%), url(${BannerPng})`,
    // opacity: 0.3,
  },
});
