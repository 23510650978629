import Card from "./../../Global/Card/Card";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { StyledDivider, StyledText } from "../../../theme/commonStyles";
import useClasses from "./../../../hooks/useClasses";
import { styles } from "../styles";
import Skeleton from "react-loading-skeleton";
import { numberWithCommas } from "./../../../helpers/utils";

const Overview = ({ latestTraining, data, loading }) => {
  const classes = useClasses(styles);

  return (
    <Card heading="Overview" customCssClassName={classes.overViewCard}>
      <Stack direction="column">
        <Box mt={3}>
          <StyledText fs={18} clr="text3">
            Total Employees
          </StyledText>
          {loading ? (
            <Skeleton />
          ) : (
            <StyledText fs={18} clr="primary">
              {numberWithCommas(data?.total_employee) || 0}
            </StyledText>
          )}
        </Box>
        <StyledDivider my={3} />
        <Box>
          <StyledText fs={18} clr="text3">
            Total Current Spend
          </StyledText>
          {loading ? (
            <Skeleton />
          ) : (
            <StyledText fs={18} clr="primary">
              {numberWithCommas(Math.round(data?.total_comp_spend)) || 0}
            </StyledText>
          )}
        </Box>
        <StyledDivider my={3} />
        <Box>
          <StyledText fs={18} clr="text3">
            Total Predicted Spend
          </StyledText>
          {loading ? (
            <Skeleton />
          ) : (
            <StyledText fs={18} clr="primary">
              {numberWithCommas(Math.round(data?.total_P2_spend)) || 0}
            </StyledText>
          )}
        </Box>
      </Stack>
    </Card>
  );
};

export default Overview;
