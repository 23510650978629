export const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `linear-gradient(105.78deg, rgba(142, 210, 235,0.7) 2.22%, rgba(181, 188, 255,0.7) 100%)`,
  },
  logo: {
    width: "80px",
    height: "80px",
  },
  loginWrapper: {
    width: "400px",
    // height: "40%",
    background: "rgba(255,255,255,0.5)",
    backdropFilter: "blur(5px)",
    borderRadius: "10px",
    padding: "2rem",
  },
});
