import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  fetchOptionsForVariable,
  predictData,
  saveComparatorLevelConfigVariables,
} from "../../../api";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledSelect,
  StyledText,
  StyledTextfield,
} from "../../../theme/commonStyles";
import StyledSwitch from "../../Global/FormComponents/StyledSwitch";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Global/Loading/Loading";
import Card from "../../Global/Card/Card";
import CohortCard from "../CohortCard";
import ErrorService from "../../../helpers/ErrorService";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPredictedResult,
  setPredictedResult,
} from "../../../store/slice/payRanges";
import EmployeeIdSelect from "./EmployeeIdSelect";

const InternalForm = ({
  variables,
  trainingId,
  employeeIdField,
  levels,
  useCase,
}) => {
  const predictedResult = useSelector(selectPredictedResult);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [variableInteractions, setVariableInteractions] = useState(() => {
    return predictedResult ? predictedResult?.variableInteractions : true;
  });
  const [variablesOptions, setVariablesOptions] = useState(
    predictedResult ? predictedResult.variablesOptions : {}
  );
  const [selectedVariable, setSelectedVariable] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    predictedResult ? predictedResult.selectedEmployeeId : null
  );
  // for Cohort
  const [levelVariables, setLevelVariables] = useState({});
  const [variablesData, setVariablesData] = useState(levels);

  const initialValues = variables.reduce(
    (a, v) => ({ ...a, [v.name]: "" }),
    {}
  );
  const formik = useFormik({
    initialValues: predictedResult ? predictedResult.values : initialValues,
    async onSubmit(val, { setSubmitting }) {
      setSubmitting(true);
      const temp = { ...val };
      const values = {
        [employeeIdField]: selectedEmployeeId[employeeIdField],
        ...temp,
      };
      const param = variablesData.map((item, i) => {
        return {
          ...item,
          variables: levelVariables[i + 1],
        };
      });
      let data = await saveComparatorLevelConfigVariables(trainingId, param);
      if (data.length > variablesData.length) {
        ErrorService.sendOkMessage("New Level Added");
      } else if (data.length > 0) {
        ErrorService.sendOkMessage("Levels Updated");
      }

      data = data.filter((item) => item.variables.length > 0);

      const result = await predictData(trainingId, values, useCase);
      dispatch(
        setPredictedResult({
          ...result,
          values: values,
          variablesOptions,
          employeeIdField,
          selectedEmployeeId,
          levelsData: data,
          variableInteractions,
        })
      );
      setSubmitting(false);
    },
  });

  const fetchInitialOptions = useCallback(async () => {
    console.log("recall");
    let promises = [];
    const selectedVar = {};
    variables.forEach(async (item) => {
      promises.push(
        fetchOptionsForVariable(trainingId, item.name, selectedVar)
      );
      selectedVar[item.name] = selectedEmployeeId[item.name];
    });
    const data = await Promise.all(promises);
    if (data[0]) {
      setSelectedVariable(selectedVar);
      const temp = {};
      variables.forEach((item, i) => {
        temp[item.name] = data[i].values;
      });
      setVariablesOptions(temp);
    }
  }, [selectedEmployeeId, trainingId, variables]);

  useEffect(() => {
    if (!selectedEmployeeId) return;
    if (predictedResult) return;
    fetchInitialOptions();
    Object.keys(formik.values).forEach((item) => {
      if (item !== "employeeId")
        formik.setFieldValue(item, selectedEmployeeId[item]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployeeId, variableInteractions]);

  const fetchOptions = async (variableName) => {
    setLoading(true);
    const isFilled = Object.values(formik.values).every((val) => val !== "");
    let selectedVar = { ...selectedVariable };
    if (isFilled) {
      formik.resetForm();
      setSelectedVariable({});
      selectedVar = {};
    }
    let result = await fetchOptionsForVariable(
      trainingId,
      variableName,
      variableInteractions ? selectedVar : {}
    );
    if (result)
      setVariablesOptions({
        ...variablesOptions,
        [variableName]: result.values,
      });
    setLoading(false);
  };

  const handleSelect = (name, i) => {
    setOpen(i);
    fetchOptions(name);
  };

  const handleValueSelection = (colName, colValue) => {
    setSelectedVariable({
      ...selectedVariable,
      [colName]: colValue,
    });
  };

  const handleGo = () => {
    let isValid = true;
    Object.keys(formik.values).forEach(
      (key) => formik.values[key] === "" && (isValid = false)
    );
    if (isValid) {
      formik.handleSubmit();
    } else {
      ErrorService.sendWarnMessage({
        message: "Please fill the form to predict data",
      });
    }
  };

  const handleReset = () => {
    dispatch(setPredictedResult(null));
  };

  return (
    <>
      <Card heading="Prediction">
        <Box component="form" width="100%" mt={3}>
          {variables.length > 0 ? (
            <>
              <FormControl
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <StyledText fontWeight="300">Variable Interaction</StyledText>
                <StyledSwitch
                  checked={variableInteractions}
                  onChange={(e, c) => {
                    setVariableInteractions(c);
                    setSelectedVariable({});
                    setVariablesOptions({});
                    setSelectedEmployeeId(null);
                    dispatch(setPredictedResult(null));
                    formik.resetForm();
                  }}
                />
              </FormControl>
              <StyledDivider mt={1} size={2} clr="primary" />
              <EmployeeIdSelect
                trainingId={trainingId}
                employeeIdField={employeeIdField}
                selectedEmployeeId={selectedEmployeeId}
                setSelectedEmployeeId={setSelectedEmployeeId}
                initialEmpId={
                  predictedResult ? selectedEmployeeId[employeeIdField] : ""
                }
              />
              {selectedEmployeeId &&
                variables.map((item, i) =>
                  item.is_continous ? (
                    <StyledTextfield
                      key={uuidv4()}
                      type="number"
                      id={`continuos-field-${i}`}
                      label={item.name}
                      variant="standard"
                      name={item.name}
                      value={formik.values[item.name]}
                      onChange={formik.handleChange}
                      required
                      sx={{ mt: 2 }}
                      fullWidth
                    />
                  ) : (
                    <>
                      <FormControl sx={{ mt: 2 }} fullWidth key={uuidv4()}>
                        <InputLabel
                          sx={{ left: "-14px" }}
                          id={`variable-field-label-${i}`}>
                          {item.name}
                        </InputLabel>
                        <StyledSelect
                          open={open === i}
                          labelId={`variable-field-label-${i}`}
                          id={`variable-field-select-${i}`}
                          label={item.name}
                          value={formik.values[item.name]}
                          onChange={formik.handleChange}
                          name={item.name}
                          required
                          onOpen={() => handleSelect(item.name, i)}
                          onClose={() => setOpen(null)}
                          variant="standard">
                          {loading ? (
                            <Loading css={{ my: 2 }} />
                          ) : variablesOptions[item.name] ? (
                            variablesOptions[item.name].map((column, i) => (
                              <MenuItem
                                key={i}
                                value={column}
                                onClick={() =>
                                  handleValueSelection(item.name, column)
                                }>
                                {column}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>Fetching Options...</MenuItem>
                          )}
                        </StyledSelect>
                      </FormControl>
                    </>
                  )
                )}
            </>
          ) : (
            <StyledText fs={14} clr="text4">
              Fetching Variables...
            </StyledText>
          )}
        </Box>
      </Card>
      <CohortCard
        variablesData={variablesData}
        setVariablesData={setVariablesData}
        levelVariables={levelVariables}
        setLevelVariables={setLevelVariables}
      />
      <FlexBox width="100%" mt={3} justifyContent="space-between">
        <StyledButton onClick={handleReset}>Reset</StyledButton>
        <StyledButton onClick={handleGo}>Go</StyledButton>
      </FlexBox>
    </>
  );
};

export default React.memo(InternalForm);
