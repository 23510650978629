import LayoutAuth from "../Layout/LayoutAuth";
import RegisterForm from "../RegisterForm";

function Register() {
  return (
    <LayoutAuth title="Register">
      <RegisterForm />
    </LayoutAuth>
  );
}

export default Register;
