import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetUser, selectUserData } from "../../../store/slice/auth";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./styles";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { FlexBox, RouterLink } from "../../../theme/commonStyles";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import clsx from "clsx";
import HomeIcon from "@mui/icons-material/Home";
import ScaleIcon from "@mui/icons-material/Scale";
import BalanceIcon from "@mui/icons-material/Balance";
import TimelineIcon from "@mui/icons-material/Timeline";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../../api";
import { eraseCookie } from "../../../helpers/utils";

const NavBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [payAnalyticsMenu, setPayAnalyticsMenu] = React.useState(null);
  const userData = useSelector(selectUserData);

  const handleOpenPayRangesMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClosePayRangesMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenPayAnalyticsMenu = (event) => {
    setPayAnalyticsMenu(event.currentTarget);
  };

  const handleClosePayAnalyticsMenu = () => {
    setPayAnalyticsMenu(null);
  };

  const classes = useClasses(styles);

  const handleLogout = async () => {
    await logout();
    eraseCookie("sessionid");
    dispatch(resetUser());
    navigate("/login");
  };

  const redirect = (path) => {
    navigate(path);
  };

  return (
    <>
      <AppBar component="nav" elevation={0} className={classes.appBarWrapper}>
        <Toolbar className={classes.toolBarWrapper}>
          <div
            style={{
              flexGrow: 1,
              cursor: "pointer",
            }}>
            <Logo className={classes.logo} />
          </div>
          <FlexBox height={"100%"} alignItems="center">
            <Button
              sx={{
                alignItems: "center",
              }}
              className={clsx(
                classes.navBtn,
                location.pathname === "/" && classes.activeBtn
              )}
              onClick={() => redirect("/")}
              startIcon={<HomeIcon />}>
              Home
            </Button>
            {userData.modulePermissions["Ranges"] &&
              userData.modulePermissions["Ranges"].length > 0 && (
                <Button
                  className={clsx(
                    classes.navBtn,
                    location.pathname === "/use-cases" && classes.activeBtn
                  )}
                  onClick={handleOpenPayRangesMenu}
                  startIcon={<ScaleIcon />}>
                  Pay Ranges
                </Button>
              )}
            <Menu
              sx={{ mt: "60px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleClosePayRangesMenu}>
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Hiring") && (
                  <MenuItem>
                    <RouterLink to={`/use-cases?useCase=Hiring`}>
                      <Typography textAlign="center">Hiring</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Transfer") && (
                  <MenuItem>
                    <RouterLink to={`/use-cases?useCase=Transfer`}>
                      <Typography textAlign="center">Transfer</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Retention") && (
                  <MenuItem>
                    <RouterLink to={`/use-cases?useCase=Retention`}>
                      <Typography textAlign="center">Retention</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes("Promotion") && (
                  <MenuItem>
                    <RouterLink to={`/use-cases?useCase=Promotion`}>
                      <Typography textAlign="center">Promotion</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
              {userData.modulePermissions["Ranges"] &&
                userData.modulePermissions["Ranges"].includes(
                  "Role Change"
                ) && (
                  <MenuItem>
                    <RouterLink to={`/use-cases?useCase=Role Change`}>
                      <Typography textAlign="center">Role Change</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
            </Menu>
            {userData.modulePermissions["Equity"] &&
              userData.modulePermissions["Equity"].length === 0 && (
                <Button
                  className={clsx(
                    classes.navBtn,
                    location.pathname === "/pay-equity" && classes.activeBtn
                  )}
                  onClick={() => redirect("/pay-equity")}
                  startIcon={<BalanceIcon />}>
                  Pay Equity
                </Button>
              )}
            {userData.modulePermissions["Analytics"] &&
              userData.modulePermissions["Analytics"].length > 0 && (
                <Button
                  className={clsx(
                    classes.navBtn,
                    location.pathname.includes("pay-analytics") &&
                      classes.activeBtn
                  )}
                  onClick={handleOpenPayAnalyticsMenu}
                  startIcon={<TimelineIcon />}>
                  Pay Analytics
                </Button>
              )}

            <Menu
              sx={{ mt: "60px" }}
              id="menu-appbar"
              anchorEl={payAnalyticsMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(payAnalyticsMenu)}
              onClose={handleClosePayAnalyticsMenu}>
              {userData.modulePermissions["Analytics"] &&
                userData.modulePermissions["Analytics"].includes(
                  "Optimizer"
                ) && (
                  <MenuItem>
                    <RouterLink to={`/pay-analytics/salary-optimizer`}>
                      <Typography textAlign="center">
                        Spend Optimizer
                      </Typography>
                    </RouterLink>
                  </MenuItem>
                )}
              {userData.modulePermissions["Analytics"] &&
                userData.modulePermissions["Analytics"].includes("Report") && (
                  <MenuItem>
                    <RouterLink to={`/pay-analytics/reports`}>
                      <Typography textAlign="center">Reports</Typography>
                    </RouterLink>
                  </MenuItem>
                )}
            </Menu>
            <Button
              startIcon={<SettingsIcon />}
              className={clsx(
                classes.navBtn,
                location.pathname === "/config" && classes.activeBtn
              )}
              onClick={() => redirect("/config")}>
              Configuration
            </Button>
            <IconButton onClick={handleLogout} className={classes.logoutIcon}>
              <LogoutIcon />
            </IconButton>
          </FlexBox>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
