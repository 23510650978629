import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#009EF1",
    },
  },
  typography: {
    fontFamily: ["Epilogue", "serif"].join(","),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
  shape: {
    borderRadius: 20,
  },
});

export default theme;
