import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import PayEquityTab from "./PayEquity/PayEquityTab";
import PayOptimizationTab from "./PayOptimization/PayOptimizationTab";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/slice/auth";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    color: "rgba(64, 64, 64, 1)",
    fontWeight: 600,
    fontSize: "16px",
    "&.Mui-selected": {
      color: "rgba(64, 64, 64, 1)",
      background: "#F4F7FB",
      borderRadius: "8px",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const TabsLayout = ({ totalData }) => {
  const userData = useSelector(selectUserData);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example">
        {userData.modulePermissions["Equity"] &&
          userData.modulePermissions["Equity"].length === 0 && (
            <StyledTab label="Pay Equity" />
          )}
        {userData.modulePermissions["Analytics"] &&
          userData.modulePermissions["Analytics"].length !== 0 && (
            <StyledTab label="Pay Optimization" />
          )}
      </StyledTabs>
      <TabPanel value={value} index={0}>
        {userData.modulePermissions["Equity"] &&
          userData.modulePermissions["Equity"].length === 0 && <PayEquityTab />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {userData.modulePermissions["Analytics"] &&
          userData.modulePermissions["Analytics"].length !== 0 && (
            <PayOptimizationTab totalData={totalData} />
          )}
      </TabPanel>
    </Box>
  );
};

export default React.memo(TabsLayout);
