export const styles = (theme) => ({
  payReferenceIndexCard: {
    background: "linear-gradient(180deg, #F4F7FB 0%, #DDE0FB 87.97%)",
    marginTop: "1rem",
  },
  dataContentBox: {
    flex: "1",
    background: "#FFFFFF",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "20px",
    padding: "1rem",
    marginBottom: "1rem !important",
  },
  borderRight: {
    borderRight: "1px solid rgba(168, 168, 168, 1)",
    paddingRight: "1rem",
    marginRight: "1rem",
  },
  totalCard: {
    flex: "1",
    borderRadius: "20px",
    background: "#FFFFFF",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  totalCardHeading: {
    background: "#F4F7FB",
    borderRadius: "20px 20px 0 0",
    textAlign: "center",
    width: "100%",
    height: "50px",
  },
  graphCard: {
    flex: "1",
    background: "#FFFFFF",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "20px",
  },
  tableCard: {
    background: "#FFFFFF",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "20px 20px 0px 0px",
  },
  payEquityDataWrapper: {
    height: "calc(100vh - 70px)",
    overflowY: "auto",
    padding: "0 1rem 2rem",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
  },
  existingReportCard: {
    backgroundImage:
      "linear-gradient(180deg, #F4F7FB 0%, rgba(244, 247, 251, 0.8) 100%)",
    borderRadius: "10px",
  },
});
