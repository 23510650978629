import React, { useRef, useEffect, useMemo } from "react";
import Chart from "chart.js";
import useClasses from "../../../hooks/useClasses";
import { styles } from "../home.styles";
import Card from "../../Global/Card/Card";
import { clsx } from "clsx";
import Skeleton from "react-loading-skeleton";
import { StyledText } from "../../../theme/commonStyles";
import { numberWithCommas } from "./../../../helpers/utils";

const ScatterGraphCard = ({ isDark = false, graphData, loading }) => {
  const classes = useClasses(styles);
  const canvasRef = useRef(null);
  const data = useMemo(
    () => ({
      datasets: [
        {
          label: "Scatter Dataset",
          data: graphData,
          backgroundColor: "rgba(26, 108, 205, 0.5)",
          pointBorderWidth: 1,
          pointBorderColor: "rgba(26, 108, 205, 1)",
          pointRadius: 5,
        },
      ],
    }),
    [graphData]
  );

  useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx && !loading) {
      new Chart(ctx, {
        type: "scatter",
        data: data,
        responsive: true,
        maintainAspectRatio: true,
        options: {
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                let label = `Actual Compensation: ${numberWithCommas(
                  tooltipItem.yLabel
                )} \n Predicted Compensation: ${numberWithCommas(
                  tooltipItem.xLabel
                )}`;
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                scaleLabel: {
                  display: true,
                  labelString: "Actual Compensation ⟶",
                },
                ticks: {
                  beginAtZero: true,
                  // stepSize: 5000,
                  callback: function (value, index, values) {
                    return value;
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                type: "linear",
                position: "bottom",
                scaleLabel: {
                  display: true,
                  labelString: "Predicted Compensation ⟶",
                },
                ticks: {
                  beginAtZero: true,
                  // stepSize: 5000,
                  callback: function (value, index, values) {
                    return value;
                  },
                },
              },
            ],
          },
        },
      });
    }
  }, [data, graphData, loading]);
  return (
    <Card
      heading={"Pay Parity Trend"}
      customCssClassName={clsx(
        classes.graphCard,
        isDark && classes.graphCardDark
      )}
    >
      {loading ? (
        <Skeleton sx={{ width: "100%", height: "300px", marginTop: "1rem" }} />
      ) : graphData.length > 0 ? (
        <canvas
          ref={canvasRef}
          style={{ width: "100%", minHeight: "300px", marginTop: "1rem" }}
        ></canvas>
      ) : (
        <StyledText fs={18} clr="text5" textAlign="center" mt={2}>
          Opps! something went wrong
        </StyledText>
      )}
    </Card>
  );
};

export default ScatterGraphCard;
