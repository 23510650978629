export const styles = (theme) => ({
  headerText: {
    fontWeight: 100,
  },
  subTitleText: {
    color: theme.colors.gray,
    fontWeight: 500,
  },
  heroImg: {
    width: "40%",
    height: "40%",
  },
});
