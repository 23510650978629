import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";
import useClasses from "../../hooks/useClasses";
import { StyledText } from "../../theme/commonStyles";
import { styles } from "./styles";
function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const CompensationRangesGraph = ({
  marketMin = 10000,
  marketMax = 20000,
  rMin = 17500,
  rMax = 20000,
  rCompa = 20000,
}) => {
  const classes = useClasses(styles);

  const LeftPosition = useMemo(
    () => ((rMin - marketMin) * 100) / (marketMax - marketMin),
    [marketMax, marketMin, rMin]
  );
  const RightPosition = useMemo(
    () => ((rMax - marketMin) * 100) / (marketMax - marketMin),
    [marketMax, marketMin, rMax]
  );

  const BoxWidth = useMemo(
    () => RightPosition - LeftPosition,
    [LeftPosition, RightPosition]
  );

  return (
    <Box mt={2}>
      <Stack direction="row" justifyContent="space-between">
        <StyledText clr="text4" fs={14} fontWeight={600}>
          Market Min ({numberWithCommas(marketMin)})
        </StyledText>
        <StyledText clr="text4" fs={14} fontWeight={600}>
          ({numberWithCommas(marketMax)}) Market Max
        </StyledText>
      </Stack>
      <Stack className={classes.graphWrapper} mt={1}>
        <Stack
          className={classes.recommendedQuadrant}
          sx={{
            left: LeftPosition + "%",
            width: BoxWidth + "%",
          }}>
          <StyledText
            fs={14}
            clr="text4"
            className={classes.rLeftText}
            fontWeight={600}>
            R min:
            <br />
            {numberWithCommas(rMin)}
          </StyledText>
          <StyledText
            fs={14}
            clr="text4"
            className={classes.rRightText}
            fontWeight={600}>
            R max:
            <br />
            {numberWithCommas(rMax)}
          </StyledText>
        </Stack>
        <Box
          className={classes.rDivider}
          sx={{
            left:
              rCompa === marketMin
                ? 0
                : rCompa === marketMax
                ? "unset"
                : ((rCompa - marketMin) * 100) / (marketMax - marketMin) + "%",
            right: rCompa === marketMax ? 0 : "unset",
            "&::before": {
              content: `'${numberWithCommas(rCompa)}'`,
              fontFamily: "Epilouge",
              fontSize: "16px",
              color: "#009EF1",
              // background: "#fff",
              fontWeight: 600,
              position: "absolute",
              top: "-10px",
              left: "-20px",
              zIndex: 10,
            },
          }}></Box>
        {/* <Box className={classes.rDivider2}></Box>
        <Box className={classes.rDivider3}></Box> */}
      </Stack>
    </Box>
  );
};

export default CompensationRangesGraph;
