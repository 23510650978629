import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setOrgDetails, setUser } from "../store/slice/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoggedInMemberInfo, registerOrg, signIn } from "../api";
import {
  modulePermissionsCheck,
  setCookie,
  // hashPassword,
} from "../helpers/utils";

function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const formik = useFormik({
    initialValues: {
      name: "",
      admin: {
        user: {
          first_name: "",
          last_name: "",
          email: "",
          username: "",
          password: "",
          password2: "",
        },
      },
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let response = await registerOrg(values);
        if (!response.ok) {
          response.json().then((errors) => {
            if (response.status === 400) setErrors(errors);
            else throw new Error(errors);
          });
          return;
        }
        const orgData = await response.json();

        response = await signIn({
          username: values.admin.user.username,
          password: values.admin.user.password,
        });
        const signInData = await response.json();
        // Setting Cookies...
        const d = new Date();
        d.setTime(d.getTime() + 30 * 60 * 1000);
        setCookie("sessionid", signInData.sid, d);
        const authData = {
          token: signInData.token,
          authToken: signInData.auth,
          sid: signInData.sid,
          sidExp: signInData.sidexp,
        };
        const memberInfo = await getLoggedInMemberInfo(authData);

        modulePermissionsCheck(memberInfo?.module_permissions);
        modulePermissionsCheck(orgData?.module_permissions);
        //@ Redux Storage
        dispatch(setOrgDetails(orgData));
        dispatch(
          setUser({
            ...authData,
            id: memberInfo?.id,
            role: memberInfo?.role,
            details: memberInfo?.user,
            dataPermissions: memberInfo?.data_permissions,
            modulePermissions:
              memberInfo?.module_permissions || orgData?.module_permissions,
          })
        );
        navigate(from, { replace: true });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      <TextField
        margin="dense"
        required
        fullWidth
        id="name"
        label="Organization Name"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        id="user-email"
        label="Your Email"
        name="admin.user.email"
        type="email"
        autoComplete="email"
        value={formik.values.admin.user.email}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.email &&
          Boolean(formik.errors.admin?.user?.email)
        }
        helperText={
          formik.touched.admin?.user?.email && formik.errors.admin?.user?.email
        }
      />

      <TextField
        margin="dense"
        required
        fullWidth
        id="user-username"
        label="Your Username"
        name="admin.user.username"
        value={formik.values.admin.user.username}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.username &&
          Boolean(formik.errors.admin?.user?.username)
        }
        helperText={
          formik.touched.admin?.user?.username &&
          formik.errors.admin?.user?.username
        }
      />

      <TextField
        margin="dense"
        required
        fullWidth
        id="user-firstname"
        label="Your First Name"
        name="admin.user.first_name"
        value={formik.values.admin.user.first_name}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.first_name &&
          Boolean(formik.errors.admin?.user?.first_name)
        }
        helperText={
          formik.touched.admin?.user?.first_name &&
          formik.errors.admin?.user?.first_name
        }
      />

      <TextField
        margin="dense"
        required
        fullWidth
        id="user-lastname"
        label="Your Last Name"
        name="admin.user.last_name"
        value={formik.values.admin.user.last_name}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.last_name &&
          Boolean(formik.errors.admin?.user?.last_name)
        }
        helperText={
          formik.touched.admin?.user?.last_name &&
          formik.errors.admin?.user?.last_name
        }
      />

      <TextField
        margin="dense"
        required
        fullWidth
        id="user-password"
        label="Password"
        name="admin.user.password"
        type="password"
        value={formik.values.admin.user.password}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.password &&
          Boolean(formik.errors.admin?.user?.password)
        }
        helperText={
          formik.touched.admin?.user?.password &&
          formik.errors.admin?.user?.password
        }
      />

      <TextField
        margin="dense"
        required
        fullWidth
        id="user-confirm-password"
        label="Confirm Password"
        name="admin.user.password2"
        type="password"
        value={formik.values.admin.user.password2}
        onChange={formik.handleChange}
        error={
          formik.touched.admin?.user?.password2 &&
          Boolean(formik.errors.admin?.user?.password2)
        }
        helperText={
          formik.touched.admin?.user?.password2 &&
          formik.errors.admin?.user?.password2
        }
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={formik.isSubmitting}>
        Register
      </Button>
    </Box>
  );
}

export default RegisterForm;
