import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSetupStage } from "../store/slice/auth";
import { useEffect } from "react";
import ErrorService from "../helpers/ErrorService";
import SetupRequired from "../components/Global/SetupRequired/SetupRequired";

const STAGES = {
  incomplete: 0,
  internalDataUploaded: 1,
  marketDataUploaded: 2,
};

function RequireSetup() {
  const setupStage = useSelector(selectSetupStage);

  useEffect(() => {
    if (setupStage === STAGES.incomplete) {
      ErrorService.sendWarnMessage({
        message: "please complete the setup process",
      });
    }
  }, [setupStage]);

  if (setupStage === STAGES.incomplete) {
    return <SetupRequired />;
  }

  return <Outlet />;
}

export default RequireSetup;
