import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useClasses from "../../hooks/useClasses";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../theme/commonStyles";
import Card from "../Global/Card/Card";
import { styles } from "./styles";
import CompensationRangesGraph from "./CompensationRangesGraph";
import {
  downloadUseCasesExcel,
  fetchCurrentCompensation,
  getComparatorPercentils,
  getUseCasesJson,
} from "../../api";
import { selectLatestTraining } from "../../store/slice/auth";
import { useSelector } from "react-redux";
import CohortWiseDataTables from "./CohortWiseDataTables";
import { selectPredictedResult } from "../../store/slice/payRanges";
import { numberWithCommas } from "../../helpers/utils";
import DownloadIcon from "@mui/icons-material/Download";
import { ReactComponent as ExcelSvg } from "../../assets/excel.svg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "jspdf-autotable";
import jsPDF from "jspdf";

const PayRangesResult = ({ useCase, trainingId }) => {
  const predictedResult = useSelector(selectPredictedResult);
  const classes = useClasses(styles);
  const latestTraining = useSelector(selectLatestTraining);
  const [comparatorPercentile, setComparatorPercentiles] = useState({});
  const [isDownloading, setDownloading] = useState(false);
  const [currentCompensationData, setCurrentCompensation] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { levelsData } = predictedResult;

  const handleOpenDownloadMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorElUser(null);
  };
  const fetchComparatorPercentiles = useCallback(async () => {
    if (latestTraining?.id) {
      let comparatorPercentilesResponse = await getComparatorPercentils(
        latestTraining.id,
        predictedResult.id
      );
      if (comparatorPercentilesResponse) {
        setComparatorPercentiles(comparatorPercentilesResponse);
      }
    }
  }, [latestTraining.id, predictedResult.id]);

  const fetchCurrentCompensationData = useCallback(async () => {
    if (latestTraining?.id) {
      const data = await fetchCurrentCompensation(
        latestTraining?.id,
        predictedResult.selectedEmployeeId[predictedResult.employeeIdField]
      );
      if (data) setCurrentCompensation(data);
    }
  }, [
    latestTraining?.id,
    predictedResult.employeeIdField,
    predictedResult.selectedEmployeeId,
  ]);

  const downloadData = async () => {
    handleCloseDownloadMenu();
    setDownloading(true);
    try {
      const downloadFile = await downloadUseCasesExcel(
        latestTraining.id,
        predictedResult.id
      );
      if (downloadFile) {
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement("a");
        a.href = url;
        a.download = "employee_list.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } finally {
      setDownloading(false);
    }
  };

  const downloadPdfData = async () => {
    handleCloseDownloadMenu();
    setDownloading(true);
    try {
      const response = await getUseCasesJson(
        latestTraining.id,
        predictedResult.id
      );
      if (response.data) {
        const pdf = new jsPDF("l", "pt", "a3");
        //@ Params
        const paramsCol = Object.keys(response?.data?.Parameters[0]);
        const paramsRow = [];
        paramsRow.push(Object.values(response?.data?.Parameters[0]));
        pdf.text(500, 40, "Pay Ranges Compensable Factors");
        pdf.autoTable(paramsCol, paramsRow, {
          startY: 65,
          theme: "grid",
          styles: {
            font: "times",
            halign: "center",
            cellPadding: 3.5,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
          },
          headStyles: {
            textColor: [0, 0, 0],
            fontStyle: "normal",
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [166, 204, 247],
          },
          rowStyles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
          },
          tableLineColor: [0, 0, 0],
        });
        const resultCol = Object.keys(response?.data?.Results[0]);
        const resultRow = [];
        resultRow.push(Object.values(response?.data?.Results[0]));
        pdf.text(500, 150, "Pay Ranges Result");
        pdf.autoTable(resultCol, resultRow, {
          startY: 170,
          theme: "grid",
          styles: {
            font: "times",
            halign: "center",
            cellPadding: 3.5,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
          },
          headStyles: {
            textColor: [0, 0, 0],
            fontStyle: "normal",
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [166, 204, 247],
          },
          rowStyles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
          },
          tableLineColor: [0, 0, 0],
        });
        if (response.data["Comparator Percentiles"]) {
          const compPercentileCol = ["Cohort", "Peer Percentile", "Median"];
          const compPercentileRow = [];
          Object.keys(response.data["Comparator Percentiles"][0]).forEach(
            (key, i) => {
              const temp = [
                `Cohort ${i + 1} (${levelsData[i].variables.join(", ")})`,
              ];
              const temp2 = Object.values(
                response.data["Comparator Percentiles"][0][key]
              );

              compPercentileRow.push(temp.concat(temp2));
            }
          );
          pdf.text(500, 250, "Comparator Percentile");
          pdf.autoTable(compPercentileCol, compPercentileRow, {
            startY: 270,
            theme: "grid",
            styles: {
              font: "times",
              halign: "center",
              cellPadding: 3.5,
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
            },
            headStyles: {
              textColor: [0, 0, 0],
              fontStyle: "normal",
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [166, 204, 247],
            },
            rowStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            },
            tableLineColor: [0, 0, 0],
          });
        }

        levelsData.forEach((item, i) => {
          if (response.data[`Comparator Data ${i + 1}`]) {
            pdf.addPage();
            const col = Object.keys(
              response.data[`Comparator Data ${i + 1}`][0]
            );
            const row = [];

            response.data[`Comparator Data ${i + 1}`].forEach((item) => {
              row.push(Object.values(item));
            });
            pdf.text(
              500,
              50,
              `Comparator Analysis Overview-Cohort ${i + 1} (${levelsData[
                i
              ].variables.join(", ")})`
            );
            pdf.autoTable(col, row, {
              startY: 65,
              theme: "grid",
              styles: {
                font: "times",
                halign: "center",
                cellPadding: 3.5,
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0],
              },
              headStyles: {
                textColor: [0, 0, 0],
                fontStyle: "normal",
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                fillColor: [166, 204, 247],
              },
              rowStyles: {
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              },
              tableLineColor: [0, 0, 0],
            });
          }
        });

        pdf.save("PayRangesResult");
      }
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    fetchComparatorPercentiles();
    if (["Retention", "Promotion", "Transfer", "Role Change"].includes(useCase))
      fetchCurrentCompensationData();
  }, [fetchComparatorPercentiles, fetchCurrentCompensationData, useCase]);

  return (
    <Box className={classes.payRangeResultWrapper}>
      <FlexBox mb={2} width="100%" justifyContent="flex-end">
        <StyledButton onClick={handleOpenDownloadMenu} loading={isDownloading}>
          Download &nbsp;
          <DownloadIcon />
        </StyledButton>
        <Menu
          sx={{ mt: "60px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseDownloadMenu}>
          <MenuItem
            onClick={downloadData}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            EXCEL &nbsp;
            <ExcelSvg />
          </MenuItem>
          <MenuItem
            onClick={downloadPdfData}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            PDF
            <PictureAsPdfIcon />
          </MenuItem>
        </Menu>
      </FlexBox>
      {/* Data */}
      <FlexBox alignItems="stretch">
        <Card
          isHeadingCenter={false}
          heading={"Compensable Factors"}
          customCssClassName={classes.compensableFactorCard}>
          {predictedResult?.values && (
            <Table
              sx={{
                mt: 2,
                borderCollapse: "separate",
                borderSpacing: "0px 4px",
                tableLayout: "fixed",
              }}>
              <TableBody>
                {Object.keys(predictedResult.values).map((key, i) => (
                  <TableRow className={classes.tableRow} key={i}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{predictedResult.values[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
        <Stack width="100%" ml={3} spacing={5} flex={1}>
          <Stack direction="row" sx={{ flex: 1 }} spacing={3}>
            {["Retention", "Promotion", "Transfer", "Role Change"].includes(
              useCase
            ) && (
              <Card
                heading={"Current Compensation"}
                customCssClassName={classes.dataCards}>
                <Stack direction="row">
                  <Box className={classes.dataCardsContentBox}>
                    <StyledText clr="text3" fs={14}>
                      Compensation (
                      {predictedResult?.currency
                        ? predictedResult?.currency
                        : ""}
                      )
                    </StyledText>
                    <StyledText
                      fs={28}
                      clr="primary"
                      fontWeight={600}
                      my={2}
                      mb={4}>
                      {currentCompensationData?.compensation
                        ? numberWithCommas(
                            Math.round(currentCompensationData?.compensation)
                          )
                        : 0}
                    </StyledText>
                  </Box>
                  <Box className={classes.dataCardsContentBox}>
                    <StyledText clr="text3" fs={14}>
                      Compa Ratio
                    </StyledText>
                    <StyledText
                      fs={28}
                      clr="primary"
                      fontWeight={600}
                      my={2}
                      mb={4}>
                      {currentCompensationData?.comparator_ratio
                        ? numberWithCommas(
                            currentCompensationData.comparator_ratio.toFixed(2)
                          )
                        : 0}
                    </StyledText>
                  </Box>
                </Stack>
              </Card>
            )}
            <Card
              heading={"Market Data"}
              customCssClassName={classes.dataCards}>
              <Stack direction="row">
                <Box className={classes.dataCardsContentBox}>
                  <StyledText clr="text3" fs={14}>
                    Min
                  </StyledText>
                  <StyledText
                    fs={28}
                    clr="primary"
                    fontWeight={600}
                    my={2}
                    mb={4}>
                    {predictedResult.market_min ? (
                      <>
                        {numberWithCommas(
                          Math.round(predictedResult.market_min)
                        )}
                      </>
                    ) : (
                      0
                    )}
                  </StyledText>
                </Box>
                <Box className={classes.dataCardsContentBox}>
                  <StyledText clr="text3" fs={14}>
                    Max
                  </StyledText>
                  <StyledText
                    fs={28}
                    clr="primary"
                    fontWeight={600}
                    my={2}
                    mb={4}>
                    {predictedResult.market_max ? (
                      <>
                        {numberWithCommas(
                          Math.round(predictedResult.market_max)
                        )}
                      </>
                    ) : (
                      0
                    )}
                  </StyledText>
                </Box>
              </Stack>
            </Card>
          </Stack>
          <Card
            heading={"Recommendation"}
            customCssClassName={classes.dataCards}>
            <Stack direction="row" mt={2}>
              <Box className={classes.recommendationContextBox}>
                <StyledText clr="text3" fs={14}>
                  Compensation (
                  {predictedResult?.currency ? predictedResult?.currency : ""})
                </StyledText>
                <StyledText
                  fs={28}
                  clr="primary"
                  fontWeight={600}
                  mt={1}
                  mb={4}>
                  {predictedResult.p2
                    ? numberWithCommas(Math.round(predictedResult.p2))
                    : 0}
                </StyledText>
              </Box>
              <Box className={classes.recommendationContextBox}>
                <StyledText clr="text3" fs={14}>
                  Compa Ratio
                </StyledText>
                <StyledText
                  fs={28}
                  clr="primary"
                  fontWeight={600}
                  mt={1}
                  mb={4}>
                  {predictedResult.comp_ratio
                    ? numberWithCommas(predictedResult.comp_ratio.toFixed(2))
                    : 0}
                </StyledText>
              </Box>
              <Box className={classes.recommendationContextBox}>
                <StyledText clr="text3" fs={14}>
                  R Min
                </StyledText>
                <StyledText
                  fs={28}
                  clr="primary"
                  fontWeight={600}
                  mt={1}
                  mb={4}>
                  {numberWithCommas(
                    Math.round(
                      predictedResult?.p3_start ? predictedResult.p3_start : 0
                    )
                  )}
                </StyledText>
              </Box>
              <Box className={classes.recommendationContextBox}>
                <StyledText clr="text3" fs={14}>
                  R Max
                </StyledText>
                <StyledText
                  fs={28}
                  clr="primary"
                  fontWeight={600}
                  mt={1}
                  mb={4}>
                  {numberWithCommas(
                    Math.round(
                      predictedResult?.p3_end ? predictedResult.p3_end : 0
                    )
                  )}
                </StyledText>
              </Box>
            </Stack>
            <Box mb={5}>
              <StyledText
                fs={18}
                clr="text3"
                textAlign="center"
                fontWeight={500}>
                Compensation Ranges
              </StyledText>
              <StyledDivider size={1.5} mt={1} />
              <CompensationRangesGraph
                marketMin={Math.round(predictedResult.market_min)}
                marketMax={Math.round(predictedResult.market_max)}
                rMin={Math.round(
                  predictedResult?.p3_start ? predictedResult.p3_start : 0
                )}
                rMax={Math.round(
                  predictedResult?.p3_end ? predictedResult.p3_end : 0
                )}
                rCompa={Math.round(predictedResult.p2)}
              />
            </Box>
          </Card>
        </Stack>
      </FlexBox>
      {/* Peer Percentile View */}
      <Card
        heading="Comparator Analysis"
        customCssClassName={classes.dataCards}
        customContainerCss={{ mt: 5 }}>
        {Object.keys(comparatorPercentile).length > 0 ? (
          <Table
            sx={{
              mt: 2,
              borderCollapse: "separate",
              borderSpacing: "0px 4px",
              tableLayout: "fixed",
            }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  <StyledText
                    fs={14}
                    clr="text3"
                    fontWeight={600}
                    textAlign="center">
                    Cohort
                  </StyledText>
                </TableCell>
                <TableCell className={classes.tableHead}>
                  <StyledText
                    fs={14}
                    clr="text3"
                    fontWeight={600}
                    textAlign="center">
                    Peer Percentile
                  </StyledText>
                </TableCell>
                <TableCell className={classes.tableHead}>
                  <StyledText
                    fs={14}
                    clr="text3"
                    fontWeight={600}
                    textAlign="center">
                    Median
                  </StyledText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levelsData.map((key, i) => (
                <TableRow className={classes.tableRow} key={i}>
                  <TableCell>
                    Cohort {i + 1}
                    {levelsData[i].variables.length > 0 && (
                      <StyledText fs={12}>
                        ({levelsData[i].variables.join(", ")})
                      </StyledText>
                    )}
                  </TableCell>
                  <TableCell>
                    {Math.round(comparatorPercentile[i + 1].percentile * 100)}
                  </TableCell>
                  <TableCell>
                    {Math.round(comparatorPercentile[i + 1].median)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <StyledText fs={14} mt={2} clr="text4">
            No cohorts found in the system
          </StyledText>
        )}
      </Card>
      {/* Level-wise employee table */}
      {levelsData.map((item, i) => (
        <Box key={i}>
          <CohortWiseDataTables
            predictedResult={predictedResult}
            trainingId={trainingId}
            level={i + 1}
            levelVariables={item.variables}
          />
        </Box>
      ))}
    </Box>
  );
};

export default PayRangesResult;
