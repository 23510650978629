import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changePassword } from "../../api";
import { useDispatch } from "react-redux";
import { resetUser } from "../../store/slice/auth";
import { Link } from "@mui/material";

function PasswordResetConfirmForm() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let response = await changePassword(values, params.uid, params.token);
        if (!response.ok) {
          const errors = await response.json();
          if (response.status === 400) setErrors(errors);
          else throw new Error(errors);
          return;
        }
        setSubmitted(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleClick = (e) => {
    dispatch(resetUser());
    nav("/login");
  };

  return (
    <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
      {Boolean(formik.errors.non_field_errors) && (
        <Alert severity="error">{formik.errors.non_field_errors}</Alert>
      )}
      {submitted && (
        <Alert severity="success">
          Your password changed successfully. Please{" "}
          <Link component="button" variant="body2" onClick={handleClick}>
            Login
          </Link>
          with your new password to proceed.
        </Alert>
      )}
      <TextField
        margin="dense"
        required
        fullWidth
        id="new_password1"
        label="New Password"
        name="new_password1"
        type="password"
        value={formik.values.new_password1}
        onChange={formik.handleChange}
        error={
          formik.touched.new_password1 && Boolean(formik.errors.new_password1)
        }
        helperText={formik.touched.new_password1 && formik.errors.new_password1}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        id="new_password2"
        label="Confirm Password"
        name="new_password2"
        type="password"
        value={formik.values.new_password2}
        onChange={formik.handleChange}
        error={
          formik.touched.new_password2 && Boolean(formik.errors.new_password2)
        }
        helperText={formik.touched.new_password2 && formik.errors.new_password2}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={formik.isSubmitting}
        disabled={submitted}>
        Reset password
      </Button>
    </Box>
  );
}

export default PasswordResetConfirmForm;
