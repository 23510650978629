export const styles = (theme) => ({
  uploadButton: {
    width: "100%",
    "& #input-file": {
      display: "none",
    },
    "& .upload-btn": {
      width: "100%",
      padding: "2rem",
      backgroundImage:
        "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e\")",
      flexDirection: "column",
      opacity: "0.5",
      "& .upload-icon": {
        width: "96px",
        height: "96px",
      },
      "& .upload-text": {
        textTransform: "capitalize",
        fontWeight: 600,
        marginTop: "1rem",
      },
      "&:hover": {
        opacity: "1",
      },
    },
  },
  advanceSetupWrapper: {
    background: "#F4F7FB",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "15px",
    padding: "1rem",
    marginTop: "1rem",
  },
  optionCard: {
    background: "#FFFFFF",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "1rem",
  },
  optionCardDisabled: {
    opacity: 0.6,
  },
  dialogWrapper: {
    "& .MuiPaper-root": {
      background: "#F4F7FB !important",
      borderRadius: "15px",
      padding: "0 1rem",
      minWidth: "300px",
    },

    "& .fieldsWrapper": {
      margin: "1rem",
      padding: "1rem",
      background: "#FFFFFF",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
      borderRadius: "10px",
      maxHeight: "400px",
      overflowY: "auto",
    },
    "& .variableButton": {
      background: "#009EF1",
      border: "1px solid #D9D9D9",
      borderRadius: "8px",
      color: "#fff",
      textTransform: "capitalize",
      marginBottom: "1rem",
      padding: "0.2rem",
      width: "100%",
    },
    "& .actionButton": {
      borderRadius: "5px",
      padding: "0.2rem",
      textTransform: "capitalize",
    },
  },
});
