export const styles = (theme) => ({
  dataWrapper: {
    flex: 1,
    padding: "0 1rem 2rem",
    height: "calc(100vh - 70px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
  },
  yAxisParams: {
    textAlign: "center",
    flex: 1,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 2rem",
    width: "130px",
    height: "60px",
  },
  dataBlock: {
    backgroundImage:
      "linear-gradient(180deg, #F4F7FB 0%, rgba(244, 247, 251, 0.8) 100%)",
    width: "117px",
    height: "60px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.hover,
    },
  },
  graphCard: {
    flex: "1",
    background: "#FFFFFF",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "20px",
  },
  overViewCard: {
    background: "#FFFFFF",
    boxShadow: "4px 4px 10px #D9D9D9",
    borderRadius: "20px",
  },
  labelBlock: {
    padding: 0,
    height: "100%",
  },
});
