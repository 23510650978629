import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { fetchOrgDetails } from "../../../api";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Stack } from "@mui/material";

const MyOrg = () => {
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(null);

  const getData = useCallback(async () => {
    setLoading(true);
    let getData = await fetchOrgDetails();
    if (getData) {
      setOrg(getData);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <FlexBox col>
      <Stack direction="row" spacing={1} mb={2}>
        <StyledText fontWeight={600} fs={18} width={"110px"}>
          Org Name:
        </StyledText>
        <StyledText fontWeight={600} fs={18} clr="text3" width={"100%"}>
          {loading ? <Skeleton /> : org?.name}
        </StyledText>
      </Stack>
      <Stack direction="row" spacing={1} mb={2}>
        <StyledText fontWeight={600} fs={18} width={"110px"}>
          Org Id:
        </StyledText>
        <StyledText fontWeight={600} fs={18} clr="text3" width={"100%"}>
          {loading ? <Skeleton /> : org?.org_id}
        </StyledText>
      </Stack>
      <Stack direction="row" spacing={1} mb={2}>
        <StyledText fontWeight={600} fs={18} width={"110px"}>
          Trial Valid:
        </StyledText>
        <StyledText fontWeight={600} fs={18} clr="text3" width={"100%"}>
          {loading ? (
            <Skeleton />
          ) : org ? (
            moment(org?.trial_valid_till).format("MMM Do YYYY")
          ) : (
            ""
          )}
        </StyledText>
      </Stack>
    </FlexBox>
  );
};

export default MyOrg;
