import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { fetchEmployeesIdByTrainingId } from "../../../api";
import { StyledSelect, StyledTextfield } from "../../../theme/commonStyles";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../../Global/Loading/Loading";

const EmployeeIdSelect = ({
  trainingId,
  employeeIdField,
  selectedEmployeeId,
  setSelectedEmployeeId,
  initialEmpId,
}) => {
  const initialUrl = `/api/training/${trainingId}/employees/?search=`;
  const [empId, setEmpId] = useState(initialEmpId || "");
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [listItemLoading, setListItemLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeIdUrl, setEmployeeIdUrl] = useState(initialUrl);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!selectedEmployeeId) setEmpId("");
  }, [selectedEmployeeId]);

  //@ Functions:
  const fetchEmployeesId = async () => {
    if (employeeIdUrl !== null) {
      employeeData.length === 0
        ? setLoadingEmployee(true)
        : setListItemLoading(true);

      const result = await fetchEmployeesIdByTrainingId(employeeIdUrl);
      if (result) {
        setEmployeeData([...employeeData, ...result.results]);
        setEmployeeIdUrl(result.next);
      }
      employeeData.length === 0
        ? setLoadingEmployee(false)
        : setListItemLoading(false);
    }
  };

  const handleSelectEmployeeId = () => {
    if (employeeData.length === 0) fetchEmployeesId();
  };

  const loadMoreOptions = (event) => {
    if (
      Math.abs(
        event.target.scrollHeight -
          event.target.clientHeight -
          event.target.scrollTop
      ) < 1
    ) {
      fetchEmployeesId();
    }
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    setLoadingEmployee(true);
    const result = await fetchEmployeesIdByTrainingId(
      initialUrl + e.target.value
    );
    if (result) {
      setEmployeeData([...result.results]);
      setEmployeeIdUrl(result.next);
    }
    setLoadingEmployee(false);
  };

  const handleResetFields = () => {
    setEmployeeData([]);
    setEmployeeIdUrl(initialUrl);
    setSearchText("");
  };

  return (
    <FormControl sx={{ mt: 2 }} fullWidth>
      <InputLabel sx={{ left: "-14px" }} id="employeeId-select-label">
        Employee Ids
      </InputLabel>
      <StyledSelect
        variant="standard"
        labelId="employeeId-select-label"
        id="employeeId-select"
        label="Employee Ids"
        value={empId}
        onChange={(e) => setEmpId(e.target.value)}
        onOpen={handleSelectEmployeeId}
        onClose={handleResetFields}
        name="employeeId"
        renderValue={() => empId}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 500 },
            onScroll: loadMoreOptions,
          },
          autoFocus: false,
        }}
        required>
        <ListSubheader>
          <StyledTextfield
            fullWidth
            autoFocus
            sx={{ my: 2 }}
            variant="standard"
            size="small"
            label="Search"
            placeholder="Type to search..."
            value={searchText}
            onChange={handleSearch}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListSubheader>
        {loadingEmployee ? (
          <Loading css={{ py: 3 }} />
        ) : employeeData.length >= 1 ? (
          employeeData.map((item, i) => (
            <MenuItem
              key={i}
              value={item[employeeIdField]}
              onClick={() => setSelectedEmployeeId(item)}>
              {item[employeeIdField]}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Employee Id Found</MenuItem>
        )}
        {listItemLoading && <Loading />}
      </StyledSelect>
    </FormControl>
  );
};

export default React.memo(EmployeeIdSelect);
