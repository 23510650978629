import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { StyledSelect, StyledText } from "../../theme/commonStyles";

const DataRefreshTrainingSelect = ({
  selectedTraining,
  setSelectedTraining,
  trainings,
}) => {
  return (
    <FormControl sx={{ mt: 2 }} fullWidth key={uuidv4()}>
      <InputLabel sx={{ left: "-14px" }} id="training-list-label">
        Data Refresh/Training Epoch(Date/Time)
      </InputLabel>
      <StyledSelect
        labelId={`training-list-label`}
        id={`training-list`}
        label=""
        value={selectedTraining.id || 0}
        onChange={() => {}}
        name="training-list"
        required
        variant="standard"
        sx={{ textAlign: "center" }}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 300 },
          },
          autoFocus: false,
        }}
      >
        {trainings.length > 0 ? (
          trainings.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => setSelectedTraining(item)}
            >
              {new Date(item.modified).toLocaleString()}
              <StyledText clr="text4" fs={12}>
                &nbsp; (Training Id- {item.id})
              </StyledText>
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0}>No Training available</MenuItem>
        )}
      </StyledSelect>
    </FormControl>
  );
};

export default DataRefreshTrainingSelect;
