import { Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getInternalPayRefIndex, getOptimizationPayRefIndex } from "../../api";
import useClasses from "../../hooks/useClasses";
import Card from "../Global/Card/Card";
import { styles } from "./styles";
import { numberWithCommas } from "../../helpers/utils";
import DataCards from "./DataCards/DataCards";
import TotalCards from "./DataCards/TotalCards";

const PayEquityMainData = ({ latestTraining, isPayAnalytics }) => {
  const classes = useClasses(styles);
  const [pRIData, setPRIData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await (isPayAnalytics
      ? getOptimizationPayRefIndex(latestTraining.id)
      : getInternalPayRefIndex(latestTraining.id));

    if (res) setPRIData(res);
    setLoading(false);
  }, [isPayAnalytics, latestTraining.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Card
        heading="Pay Parity"
        customCssClassName={classes.payReferenceIndexCard}>
        <Stack direction="row" mt={3} width="100%" spacing={4}>
          <DataCards
            title1={`Pay Parity`}
            title1_value={`${
              Math.round(pRIData?.pay_reference_index * 100) || 0
            }%`}
            title2=" Within Range"
            title2_value={pRIData?.within_range || 0}
            title3="Total Employees"
            title3_value={pRIData?.total_employees || 0}
            loading={loading}
          />
          <DataCards
            title1={`Outliers`}
            title1_value={pRIData?.outliers || 0}
            subTitle1={`(${
              Math.round(
                (pRIData?.outliers / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            title2="Above Max"
            title2_value={pRIData?.above_max}
            subTitle2={`(${
              Math.round(
                (pRIData?.above_max / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            title3="Below Min"
            title3_value={pRIData?.below_min || 0}
            subTitle3={`(${
              Math.round(
                (pRIData?.below_min / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            loading={loading}
          />
        </Stack>
        <Stack direction="row" spacing={4} mt={1}>
          <TotalCards
            heading="Total Excess Amount"
            value={numberWithCommas(Math.round(pRIData?.sum_of_excess))}
            subValue={
              latestTraining?.multi_currency_params ? "USD" : "Local Currency"
            }
            loading={loading}
          />
          <TotalCards
            heading=" Total Correction Amount"
            value={numberWithCommas(Math.round(pRIData?.sum_of_correction))}
            subValue={
              latestTraining?.multi_currency_params ? "USD" : "Local Currency"
            }
            loading={loading}
          />
        </Stack>
      </Card>
    </>
  );
};

export default React.memo(PayEquityMainData);
