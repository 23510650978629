import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FlexBox, StyledButton, StyledText } from "../../../theme/commonStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { addUser, updateUser, updateUserPatch } from "../../../api";
import ErrorService from "../../../helpers/ErrorService";
import {
  compareObjects,
  compareObjectsArr,
  noMatchObj,
  noMatchObjArr,
} from "../../../helpers/utils";
import QontoStepIcon, { QontoConnector } from "./StyledStepper";
import UserForm from "./UserForm";
import DataModule from "./DataModue";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../store/slice/auth";

const steps = ["Add User", "User Permission"];

const AddUserModal = ({
  addUserModalOpen,
  setAddUserModalOpen,
  updateData,
  setUserToBeUpdated,
  fetchAllUsers,
}) => {
  const userData = useSelector(selectUserData);
  const [activeStep, setActiveStep] = useState(0);
  const [dataPermission, setDataPermission] = useState({});
  const [modulePermissions, setModulePermissions] = useState(() => {
    if (userData?.modulePermissions) return userData.modulePermissions;
    return {};
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      first_name: "",
      last_name: "",
      role: "",
    },
  });

  const handleAddUserModalClose = () => {
    formik.resetForm();
    setActiveStep(0);
    setModulePermissions(() => {
      if (userData?.modulePermissions) return userData.modulePermissions;
      return {};
    });
    setDataPermission({});
    setAddUserModalOpen(false);
    setUserToBeUpdated(null);
  };

  const submitForm = async () => {
    const { setSubmitting, values, setErrors } = formik;
    try {
      setSubmitting(true);
      let response;
      if (updateData) {
        const tempObj = {
          ...updateData.user,
          role: updateData.role,
        };
        let finalObj = {
          user: {},
        };
        let dataPermissionObj = {};
        let modulePermissionObj = {};
        if (updateData?.data_permissions) {
          dataPermissionObj = { ...updateData.data_permissions };
        }
        if (updateData?.module_permissions) {
          modulePermissionObj = { ...updateData.module_permissions };
        }
        if (
          compareObjects(values, tempObj) &&
          compareObjectsArr(dataPermission, dataPermissionObj) &&
          compareObjectsArr(modulePermissions, modulePermissionObj)
        ) {
          ErrorService.sendWarnMessage({
            message: "No Values are changed to be update",
          });
        } else if (
          noMatchObj(values, tempObj) &&
          noMatchObjArr(dataPermission, dataPermissionObj) &&
          noMatchObjArr(modulePermissions, modulePermissionObj)
        ) {
          response = await updateUser(updateData.id, {
            user: {
              username: values.username,
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
            },
            role: values.role,
            data_permissions: dataPermission,
            module_permissions: modulePermissions,
          });
        } else {
          if (tempObj.username !== values.username)
            finalObj.user.username = values.username;
          if (tempObj.email !== values.email)
            finalObj.user.email = values.email;
          if (tempObj.first_name !== values.first_name)
            finalObj.user.first_name = values.first_name;
          if (tempObj.last_name !== values.last_name)
            finalObj.user.last_name = values.last_name;
          if (tempObj.role !== values.role) finalObj.role = values.role;
          if (
            JSON.stringify(dataPermission) !== JSON.stringify(dataPermissionObj)
          )
            finalObj["data_permissions"] = dataPermission;
          if (
            JSON.stringify(modulePermissions) !==
            JSON.stringify(modulePermissionObj)
          )
            finalObj["module_permissions"] = modulePermissions;
          response = await updateUserPatch(updateData.id, finalObj);
        }
      } else {
        response = await addUser({
          user: {
            username: values.username,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
          },
          role: values.role,
          data_permissions: dataPermission,
          module_permissions: modulePermissions,
        });
      }

      if (!response.ok) {
        const errors = await response.json();
        setModulePermissions({});
        setDataPermission({});
        if (response.status === 400) setErrors(errors);
        else throw new Error(errors);
        return;
      } else if (response.ok) {
        ErrorService.sendOkMessage(
          updateData ? " User Updated Successfully" : "User Added Successfully"
        );
        formik.resetForm();
        setModulePermissions({});
        setDataPermission({});
        setAddUserModalOpen(false);
        fetchAllUsers();
        setActiveStep(0);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const validate = () => {
    const { values, setErrors } = formik;
    let errors = {
      role: "",
      user: {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    };
    if (!values.username) {
      errors.user.username = "Required!";
    }
    if (!values.email) {
      errors.user.email = "Required!";
    }
    if (!values.first_name) {
      errors.user.first_name = "Required!";
    }
    if (!values.last_name) {
      errors.user.last_name = "Required!";
    }
    if (!values.role) {
      errors.role = "Required!";
    }
    setErrors(errors);
    return values;
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      submitForm();
    } else {
      const validValues = validate();
      const isValid = Object.keys(validValues).every(
        (item) => validValues[item] !== ""
      );
      if (isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!updateData) return;
    formik.setFieldValue("username", updateData.user.username);
    formik.setFieldValue("email", updateData.user.email);
    formik.setFieldValue("first_name", updateData.user.first_name);
    formik.setFieldValue("last_name", updateData.user.last_name);
    formik.setFieldValue("role", updateData.role);

    if (updateData?.module_permissions) {
      if (updateData.module_permissions["Ranges"].length === 0)
        updateData.module_permissions["Ranges"] = [
          "Hiring",
          "Transfer",
          "Retention",
          "Promotion",
          "Role Change",
        ];
      setModulePermissions(updateData?.module_permissions);
    }
    if (updateData?.data_permissions) {
      if (updateData?.module_permissions["Analytics"].length === 0)
        updateData.module_permissions["Analytics"] = ["Optimizer", "Report"];
      setDataPermission(updateData?.data_permissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <Modal
      open={addUserModalOpen}
      onClose={handleAddUserModalClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Box
        sx={{ width: "50%", backgroundColor: "white", borderRadius: "10px" }}
        component={Paper}
        p={3}>
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}>
          <StyledText textAlign="center" variant="h6" fontWeight="bold">
            {updateData ? "Update User" : "Add New User"}
          </StyledText>
          <IconButton
            aria-label="close-modal"
            onClick={handleAddUserModalClose}>
            <CloseIcon />
          </IconButton>
        </FlexBox>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && <UserForm formik={formik} />}
        {activeStep === 1 && (
          <DataModule
            dataPermission={dataPermission}
            setDataPermission={setDataPermission}
            modulePermissions={modulePermissions}
            setModulePermissions={setModulePermissions}
            updateData={updateData}
          />
        )}
        <Stack
          mt={3}
          width="100%"
          direction="row"
          justifyContent={activeStep >= 1 ? "space-between" : "flex-end"}>
          {activeStep !== 0 && (
            <StyledButton onClick={handleBack}>Back</StyledButton>
          )}

          <StyledButton onClick={handleNext} loading={formik.isSubmitting}>
            {activeStep >= 1 ? "Save" : " Next"}
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default React.memo(AddUserModal);
