import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPayEquityReport, getSavedReport, saveReport } from "../../api";
import ErrorService from "../../helpers/ErrorService";
import { changeColumnName } from "../../helpers/utils";
import useClasses from "../../hooks/useClasses";
import { selectDataPermissions, selectUserData } from "../../store/slice/auth";
import {
  StyledButton,
  StyledSelect,
  StyledText,
  StyledTextfield,
} from "../../theme/commonStyles";
import Card from "../Global/Card/Card";
import { styles } from "./styles";
import StyledDataGridPagination from "./../DataGrids/StyledDataGridPagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import jsPDF from "jspdf";

const SaveReportTables = ({
  latestTraining,
  filters,
  cohortDataLoading,
  heading,
}) => {
  const [showColumns, setShowColumns] = useState([]);
  const [savedReportName, setSavedReportName] = useState("");
  const [savedReportOptions, setSavedReportOptions] = useState([]);
  const [selectedSavedReport, setSelectedSavedReport] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector(selectUserData);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const classes = useClasses(styles);
  const userDataPermission = useSelector(selectDataPermissions);

  const fetchData = useCallback(async () => {
    const res = await getPayEquityReport(latestTraining.id, {
      filters: filters,
      fields: selectedSavedReport?.fields || [],
      tile: {},
      currentPage: currentPage + 1,
    });
    if (res) {
      // need to find some better approach...
      const temp3 = res.data.map((item) => {
        const obj = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            obj[changeColumnName(key)] = item[key];
          }
        }
        return obj;
      });
      setReportData(temp3);
      setTotalRowCount(temp3.length * res.total_pages);
    }
  }, [currentPage, filters, latestTraining.id, selectedSavedReport?.fields]);

  const fetchSavedReport = useCallback(async () => {
    const res = await getSavedReport(latestTraining.id);
    if (res.success) {
      setSavedReportOptions(res.data);
    }
  }, [latestTraining.id]);

  useEffect(() => {
    if (!cohortDataLoading) fetchData();
  }, [cohortDataLoading, fetchData, selectedSavedReport]);

  useEffect(() => {
    fetchSavedReport();
  }, [fetchSavedReport]);

  const saveReportAPI = async () => {
    if (savedReportName !== "" && showColumns.length >= 1) {
      setLoading(true);
      const res = await saveReport(latestTraining.id, {
        report_name: savedReportName,
        user_id: userData.id,
        fields: showColumns,
      });
      if (res) ErrorService.sendOkMessage("Report Saved Successfully");
      fetchSavedReport();
      setLoading(false);
    } else {
      ErrorService.sendWarnMessage({
        message: "Please Select columns and enter report name",
      });
    }
  };

  useEffect(() => {
    if (selectedSavedReport) {
      setShowColumns(selectedSavedReport?.fields || []);
      setSavedReportName(selectedSavedReport?.report_name || "");
    }
  }, [selectedSavedReport]);

  const pageChangeHandler = (value) => setCurrentPage(value);

  const excelDownloadHandler = async() => {

    const res = await getPayEquityReport(latestTraining.id, {
      filters: filters,
      fields: selectedSavedReport?.fields || [],
      tile: {},
      currentPage: 0,
    });
    if (res.data.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(res.data);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Employee Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "Employee.xlsx");
    }
  };

  const pdfDownloadHandler = async () => {
    const res = await getPayEquityReport(latestTraining.id, {
      filters: filters,
      fields: selectedSavedReport?.fields || [],
      tile: {},
      currentPage: 0,
    });
    if (res) {
      const pdf = new jsPDF("l", "pt", "a3");
      const columns = Object.keys(res.data[0]);
      let rows = [];
      for (let i = 0; i < res.data.length; i++) {
        var temp = Object.values(res.data[i]);
        rows.push(temp);
      }
      pdf.text(500, 40, "Compensation Risk Overview");
      pdf.autoTable(columns, rows, {
        startY: 65,
        theme: "grid",
        styles: {
          font: "times",
          halign: "center",
          cellPadding: 3.5,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
        },
        headStyles: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [166, 204, 247],
        },
        rowStyles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
        },
        tableLineColor: [0, 0, 0],
      });
      pdf.save("CompensationRiskOverview_a3");
    }
  };

  console.log();

  return (
    <>
      <Box mt={5}>
        <Card
          heading={heading ? heading : "Compensation Risk Overview"}
          customCssClassName={classes.tableCard}
          showDownload
          downloadHandler={excelDownloadHandler}
          pdfDownloadHandler={pdfDownloadHandler}>
          <Box mt={3} className={classes.existingReportCard} px={2} py={4}>
            <StyledText clr="text4" textAlign="center" fontWeight={600} fs={15}>
              View Existing Reports
            </StyledText>
            <FormControl fullWidth>
              <InputLabel id="saved-report-label" sx={{ left: "-14px" }}>
                Select Saved Report
              </InputLabel>
              <StyledSelect
                labelId={`saved-report`}
                id={`saved-report`}
                label="Select Saved Report"
                value={selectedSavedReport?.report_name || ""}
                variant="standard"
                required>
                {savedReportOptions.length >= 1 ? (
                  savedReportOptions.map((col, i) => (
                    <MenuItem
                      key={i}
                      value={col.report_name}
                      onClick={() => setSelectedSavedReport(col)}>
                      Report Name- {col.report_name}
                      <StyledText clr="text4" fs={12}>
                        &nbsp; ({new Date(col.modified).toLocaleString()})
                      </StyledText>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Saved Reports</MenuItem>
                )}
              </StyledSelect>
            </FormControl>
          </Box>
          <StyledDataGridPagination
            data={reportData}
            currentPage={currentPage}
            pageChangeHandler={pageChangeHandler}
            totalRowCount={totalRowCount}
            loading={loading}
          />
        </Card>
      </Box>
      <Box mt={5}>
        <Card heading={"Create New Report"}>
          <Stack direction="row" alignItems="center" spacing={3} my={5}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id={`variable-list`}
                options={Object.keys(userDataPermission)}
                getOptionLabel={(option) => option}
                name="selectedVariables"
                value={showColumns || []}
                onChange={(e, value) => setShowColumns(value)}
                renderInput={(params) => (
                  <StyledTextfield
                    {...params}
                    variant="outlined"
                    label="Select Fields"
                    placeholder="Fields"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <StyledTextfield
              id={`continuos-field`}
              label={"Enter Report Name"}
              variant="standard"
              value={savedReportName}
              onChange={(e) => setSavedReportName(e.target.value)}
              required
              fullWidth
            />
            <StyledButton onClick={saveReportAPI} loading={loading}>
              Save Report
            </StyledButton>
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default SaveReportTables;
