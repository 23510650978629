import { Box, Grid, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  getInternalCohort,
  getInternalPayRefIndex,
  getPayParityTrend,
} from "../../../api";
import PayParitySpeedometer from "../Graphs/PayParitySpeedometer";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "../../../store/slice/auth";
import DataCards from "../../PayEquity/DataCards/DataCards";
import useClasses from "../../../hooks/useClasses";
import { styles } from "../home.styles";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import Skeleton from "react-loading-skeleton";
import { numberWithCommas } from "../../../helpers/utils";
import BarGraphCard from "../Graphs/BarGraphCard";
import ScatterGraphCard from "../Graphs/ScatterGraphCard";
import ErrorService from "./../../../helpers/ErrorService";

const PayEquityTab = () => {
  const classes = useClasses(styles);
  const latestTraining = useSelector(selectLatestTraining);
  const [pRIData, setPRIData] = useState(null);
  const [barGraphData, setBarGraphData] = useState({ labels: [], data: [] });
  const [scatterGraphData, setScatterGraphData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await getInternalPayRefIndex(latestTraining.id);
    const res2 = await getInternalCohort(latestTraining.id, {
      cohort: latestTraining.training_params.performanceField,
      filters: {},
    });
    const res3 = await getPayParityTrend(latestTraining.id, "P1");

    if (res && res2 && res3.data) {
      setPRIData(res);
      const hierarchy =
        latestTraining.training_params.performance_field_hierarchy;
      setBarGraphData({
        data: Object.values(hierarchy).map(
          (item) => Math.round(res2[item]?.pay_reference_index * 100) || 0
        ),
        labels: Object.values(hierarchy),
      });
      const temp = res3.data.map((item) => ({
        x: Math.round(item.predicted_comp),
        y: Math.round(item.current_comp),
      }));
      setScatterGraphData(temp);
    }
    setLoading(false);
  }, [
    latestTraining.id,
    latestTraining.training_params.performanceField,
    latestTraining.training_params.performance_field_hierarchy,
  ]);

  useEffect(() => {
    if (latestTraining?.status === "In Progress") {
      ErrorService.sendWarnMessage({
        message: "Please wait training process to complete!",
      });
    } else fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const TotalCards = ({ title, value, subValue }) => {
    return (
      <FlexBox col flexcenter="true" className={classes.cardWrapper}>
        <StyledText fs={18} clr="text3" fontWeight={500} mb={2}>
          {title}
        </StyledText>

        {loading ? (
          <>
            <Skeleton style={{ width: "100px", height: "50px" }} />
            <Skeleton style={{ width: "100px" }} />
          </>
        ) : (
          <>
            <StyledText clr="primary" variant="h3">
              {value}
            </StyledText>
            <StyledText clr="text5" fs={14}>
              ({subValue})
            </StyledText>
          </>
        )}
      </FlexBox>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <PayParitySpeedometer
          percentileValue={Math.round(pRIData?.pay_reference_index * 100) || 0}
          loading={loading}
        />
        <Box mt={4}>
          <DataCards
            customContainerCss={classes.cardWrapper}
            title1={`Outliers`}
            title1_value={pRIData?.outliers || 0}
            subTitle1={`(${
              Math.round(
                (pRIData?.outliers / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            title2="Above Max"
            title2_value={pRIData?.above_max}
            subTitle2={`(${
              Math.round(
                (pRIData?.above_max / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            title3="Below Min"
            title3_value={pRIData?.below_min || 0}
            subTitle3={`(${
              Math.round(
                (pRIData?.below_min / pRIData?.total_employees) * 100
              ) || 0
            }% of total employees)`}
            loading={loading}
          />
        </Box>
        <Stack mt={4} direction="row" spacing={4}>
          <TotalCards
            title="Total Excess Amount"
            value={
              pRIData?.sum_of_excess
                ? numberWithCommas(Math.round(pRIData?.sum_of_excess))
                : 0
            }
            subValue={
              latestTraining?.multi_currency_params ? "USD" : "Local Currency"
            }
          />
          <TotalCards
            title="Total Correction Amount"
            value={
              pRIData?.sum_of_correction
                ? numberWithCommas(Math.round(pRIData?.sum_of_correction))
                : 0
            }
            subValue={
              latestTraining?.multi_currency_params ? "USD" : "Local Currency"
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="column" spacing={4}>
          <BarGraphCard graphData={barGraphData} loading={loading} />
          <ScatterGraphCard graphData={scatterGraphData} loading={loading} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(PayEquityTab);
