import React, { useCallback, useEffect, useState } from "react";
import { performanceGrid } from "../../../api";
import { styles } from "../home.styles";
import useClasses from "./../../../hooks/useClasses";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "./../../../store/slice/auth";
import Card from "../../Global/Card/Card";
import clsx from "clsx";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import Skeleton from "react-loading-skeleton";
import { Button, Stack } from "@mui/material";
import { numberWithCommas } from "../../../helpers/utils";

const OptimizerGridCard = ({ totalData }) => {
  const latestTraining = useSelector(selectLatestTraining);
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useClasses(styles);

  const fetchData = useCallback(async () => {
    const data = await performanceGrid(latestTraining.id, {
      filters: {},
    });
    if (data) {
      let tempObj = {};
      data.forEach((obj) => {
        tempObj[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]];
      });
      setPerformanceData(tempObj);
    }
    setLoading(false);
  }, [latestTraining]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card
      heading="Optimizer Grid"
      customCssClassName={clsx(classes.graphCard, classes.graphCardDark)}>
      <FlexBox pt={2} pb={0}>
        {loading ? (
          <Skeleton style={{ width: "500px", height: "320px" }} />
        ) : (
          <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={2}
            width="100%">
            <FlexBox flexcenter="true" width="20px">
              <StyledText
                clr="text3"
                fs={14}
                sx={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  transform: "rotate(-90deg)",
                }}>
                Prediction Range ⟶
              </StyledText>
            </FlexBox>
            <Stack>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={12} clr="gray2" fontWeight={600}>
                  Above
                  <br />
                  Outlier
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={12} clr="gray2" fontWeight={600}>
                  Within
                  <br />
                  Range
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={12} clr="gray2" fontWeight={600}>
                  Below
                  <br />
                  Outlier
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={12} clr="gray2" fontWeight={600}>
                  &nbsp;
                </StyledText>
              </FlexBox>
            </Stack>
            <Stack direction="column" width="83%">
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    background: "transparent",
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#d9d9d9",
                    borderRadius: "10px",
                  },
                }}>
                {performanceData &&
                  Object.values(
                    latestTraining.training_params.performance_field_hierarchy
                  ).map((item, i) => (
                    <Stack key={`_key${item}_${i}`} spacing={1}>
                      <Button
                        className={clsx(
                          classes.yAxisParams,
                          classes.dataBlock
                        )}>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].above_max_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].above_max_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_excess_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_excess_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <Button
                        className={clsx(
                          classes.yAxisParams,
                          classes.dataBlock
                        )}>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].within_range_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].within_range_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_within_range_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_within_range_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <Button
                        className={clsx(
                          classes.yAxisParams,
                          classes.dataBlock
                        )}>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].below_min_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].below_min_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_correction_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_correction_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <FlexBox
                        flexcenter="true"
                        className={clsx(
                          classes.yAxisParams,
                          classes.labelBlock,
                          classes.xAxisBlock
                        )}>
                        <StyledText fs={12} clr="gray2" fontWeight={600}>
                          {item}
                        </StyledText>
                      </FlexBox>
                    </Stack>
                  ))}
              </Stack>
              <StyledText
                textAlign="center"
                clr="text3"
                fs={14}
                whiteSpace="nowrap">
                {latestTraining.training_params.performanceField + "⟶"}
              </StyledText>
            </Stack>
          </Stack>
        )}
      </FlexBox>
    </Card>
  );
};

export default OptimizerGridCard;
