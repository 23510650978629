import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import React from "react";
import useClasses from "./../../hooks/useClasses";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { StyledText } from "./../../theme/commonStyles";
import { selectSetupStage } from "../../store/slice/auth";
import { useSelector } from "react-redux";

const SetupContainer = ({ toggleModal }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const setupStage = useSelector(selectSetupStage);

  const closeModal = () => {
    toggleModal && toggleModal();
  };

  const redirectForm = () => {
    closeModal();
    navigate("/config?tabValue=0");
  };
  const redirectEquity = () => {
    closeModal();
    navigate("/pay-equity");
  };
  const redirectToMarketData = () => {
    closeModal();
    navigate("/config?tabValue=1");
  };
  return (
    <Box className={classes.modalBox} component={Paper} p={4}>
      <StyledText variant="h3" uppercase="true">
        Welcome
      </StyledText>
      <StyledText>It's easy to use Paylens</StyledText>
      <Grid container mt={4} spacing={2}>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "column" }}
          alignItems="center">
          <Button variant="contained" onClick={redirectForm}>
            {setupStage === 0 ? "Go" : <CheckIcon />}
          </Button>
          <StyledText variant="h6" mt={1} textAlign="center">
            Internal Data
          </StyledText>
          <StyledText textAlign="center" color="primary" fontSize={14}>
            Upload Internal Data and Select Important Fields
          </StyledText>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "column" }}
          alignItems="center">
          <Button
            variant="contained"
            onClick={redirectForm}
            disabled={setupStage < 1 ? true : false}>
            {setupStage <= 1 ? "Go" : <CheckIcon />}
          </Button>
          <StyledText variant="h6" mt={1} textAlign="center">
            Train Model
          </StyledText>
          <StyledText textAlign="center" color="primary" fontSize={14}>
            Train Model after uploading the selected fields
          </StyledText>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "column" }}
          alignItems="center">
          <Button
            variant="contained"
            onClick={redirectEquity}
            disabled={setupStage < 2 ? true : false}>
            {setupStage <= 2 ? "Go" : <CheckIcon />}
          </Button>
          <StyledText variant="h6" mt={1}>
            Audit Data
          </StyledText>
          <StyledText textAlign="center" color="primary" fontSize={14}>
            Checkout Pay Equity audit after training the model
          </StyledText>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "column" }}
          alignItems="center">
          <Button
            variant="contained"
            onClick={redirectToMarketData}
            disabled={setupStage < 3 ? true : false}>
            {setupStage <= 3 ? "Go" : <CheckIcon />}
          </Button>
          <StyledText variant="h6" mt={1} textAlign="center">
            Upload Market Data
          </StyledText>
          <StyledText textAlign="center" color="primary" fontSize={14}>
            Upload market data to use features like pay optimization
          </StyledText>
        </Grid>
      </Grid>
      <Divider className={classes.linex} />
      <Button className="btn btn-secondary" onClick={redirectToMarketData}>
        Market Value Configuration
      </Button>
    </Box>
  );
};

export default React.memo(SetupContainer);
