import LayoutAuth from "../Layout/LayoutAuth";
import PasswordResetConfirmForm from "./PasswordResetConfirmForm";
import PasswordResetForm from "./PasswordResetForm";

export function PasswordReset() {
  return (
    <LayoutAuth title="Reset Password">
      <PasswordResetForm />
    </LayoutAuth>
  );
}

export function PasswordResetConfirm() {
  return (
    <LayoutAuth title="Change Password">
      <PasswordResetConfirmForm />
    </LayoutAuth>
  );
}
