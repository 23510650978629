export const styles = (theme) => ({
  cardText: {
    color: "rgba(64, 64, 64, 0.8)",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "26px",
  },
  divider: {
    width: "100%",
    height: "1.5px",
    background: "#A8A8A8",
  },
  excelIcon: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    marginLeft: "4px",
  },
});
