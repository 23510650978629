import React from "react";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import LayoutDashboard from "../../Layout/LayoutDashboard";
import { ReactComponent as ConfigRequired } from "../../../assets/configRequired.svg";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./setupRequired.styles";
import { useNavigate } from "react-router-dom";

const SetupRequired = () => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  return (
    <LayoutDashboard>
      <FlexBox col alignItems="center" justifyContent="center" height="80vh">
        <FlexBox col alignItems="center" justifyContent="center" mt={3}>
          <StyledText variant="h5">
            Setup is required to access this data
          </StyledText>
          <StyledDivider my={2} />
          <StyledButton
            onClick={() => {
              navigate("/config?tabValue=0");
            }}
            sx={{
              mb: 2,
            }}>
            Internal Configuration
          </StyledButton>
        </FlexBox>
        <ConfigRequired className={classes.heroImg} />
      </FlexBox>
    </LayoutDashboard>
  );
};

export default SetupRequired;
