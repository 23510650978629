import { toast } from "react-toastify";
import history from "../helpers/history";

export default class ErrorService {
  static handleResponse(response, data) {
    if (response && response.status >= 400 && response.status <= 499) {
      //Some special case according to api.
      if (response.status === 404 && data?.detail === "No training found.") {
        ErrorService.sendWarnMessage({ message: data?.detail });
        return false;
      }

      if (response.status === 409) {
        ErrorService.sendWarnMessage(data);
        return false;
      }
      if (response.status === 403) {
        history.push("/trial-end");
        window.location.reload();
        return false;
      }
      ErrorService.sendErrorMessage(data);
      return false;
    }

    if (response.status === 500) {
      const newData = data;
      newData.message = "Something went wrong!";

      ErrorService.sendErrorMessage(newData);
      return false;
    }

    if (response.status === 200 && data.message === undefined) return true;
    return true;
  }

  static displayAlert(message = "Something went wrong", type) {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  static logError(msg) {
    this.displayAlert(msg, "error");
  }

  static sendErrorMessage(data) {
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "DEMO") {
      this.displayAlert(data.message || data.non_field_errors, "error");
    } else {
      console.error(data.message);
    }
  }

  static sendWarnMessage(data) {
    this.displayAlert(data.message, "warn");
  }

  static sendOkMessage(data) {
    this.displayAlert(data, "success");
  }
}
