export const styles = (theme) => ({
  appBarWrapper: {
    background: theme.colors.text1,
  },
  toolBarWrapper: {
    height: "70px",
    padding: "0 1rem",
  },
  logo: {
    width: "85px",
    height: "60px",
    marginTop: "10px",
  },
  navBtn: {
    fontSize: "14px",
    height: "100%",
    color: theme.colors.text2,
    lineHeight: "20.2px",
    fontWeight: 600,
    textTransform: "capitalize",
    letterSpacing: "0.05em",
    borderRadius: 0,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginRight: "1rem",

    "&:last-child": {
      marginRight: "0",
    },
  },
  activeBtn: {
    background: "#BBD2EE",
  },
  logoutIcon: {
    width: "45px",
  },
});
