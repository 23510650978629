import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVariablesForUserCases,
  getComparatorLevelConfigVariables,
} from "../../api";
import { useQuery } from "../../hooks/useQuery";
import { selectLatestTraining } from "../../store/slice/auth";
import {
  selectPredictedResult,
  setPredictedResult,
} from "../../store/slice/payRanges";
import { FlexBox, LeftPaneWrapper, StyledText } from "../../theme/commonStyles";
import Banner from "../Global/Banner/Banner";
import LayoutDashboard from "../Layout/LayoutDashboard";
import HiringForm from "./PayRangesForm/HiringForm";
import InternalForm from "./PayRangesForm/InternalForm";
import PayRangesResult from "./PayRangesResult";

const PayRanges = () => {
  const query = useQuery();
  const useCase = query.get("useCase");
  const dispatch = useDispatch();

  const predictedResult = useSelector(selectPredictedResult);
  const [useCaseData, setUseCaseData] = useState({
    variables: [],
    latestTrainingModelId: "",
    employeeIdField: "",
  });
  const [levels, setLevels] = useState([]);
  const latestTraining = useSelector(selectLatestTraining);

  useEffect(() => {
    dispatch(setPredictedResult(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase]);

  useEffect(() => {
    if (predictedResult?.levelsData) setLevels(predictedResult?.levelsData);
  }, [predictedResult]);

  const fetchLatest = useCallback(async () => {
    // setLoading(true);
    if (latestTraining?.id) {
      let getVariables = await fetchVariablesForUserCases(
        latestTraining.id,
        useCase === "Transfer" ||
          useCase === "Retention" ||
          useCase === "Promotion" ||
          useCase === "Role Change"
          ? "Internal Change"
          : useCase
      );

      const data = await getComparatorLevelConfigVariables(latestTraining.id);
      if (data) {
        data.results.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
        setLevels(data.results);
      }

      if (latestTraining && latestTraining?.training_params && getVariables) {
        setUseCaseData({
          variables: getVariables.fields,
          latestTrainingModelId: latestTraining.id,
          employeeIdField: latestTraining.training_params.employeeIdField,
        });
      }
    }
    // setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase]);

  useEffect(() => {
    fetchLatest();
  }, [fetchLatest]);

  const whichUseCase = () => {
    switch (useCase) {
      case "Hiring":
        return (
          <HiringForm
            variables={useCaseData.variables}
            trainingId={useCaseData.latestTrainingModelId}
            levels={levels}
          />
        );

      case "Transfer":
        return (
          <InternalForm
            variables={useCaseData.variables}
            trainingId={useCaseData.latestTrainingModelId}
            employeeIdField={useCaseData.employeeIdField}
            levels={levels}
            useCase={useCase}
          />
        );
      case "Retention":
        return (
          <InternalForm
            variables={useCaseData.variables}
            trainingId={useCaseData.latestTrainingModelId}
            employeeIdField={useCaseData.employeeIdField}
            levels={levels}
            useCase={useCase}
          />
        );
      case "Promotion":
        return (
          <InternalForm
            variables={useCaseData.variables}
            trainingId={useCaseData.latestTrainingModelId}
            employeeIdField={useCaseData.employeeIdField}
            levels={levels}
            useCase={useCase}
          />
        );
      case "Role Change":
        return (
          <InternalForm
            variables={useCaseData.variables}
            trainingId={useCaseData.latestTrainingModelId}
            employeeIdField={useCaseData.employeeIdField}
            levels={levels}
            useCase={useCase}
          />
        );

      default:
        return <>Opps</>;
    }
  };


  const LeftPane = () => {
    return <LeftPaneWrapper>{whichUseCase()}</LeftPaneWrapper>;
  };

  return (
    <LayoutDashboard>
      <Banner heading={`Pay Ranges: ${useCase}`} />
      <FlexBox>
        <LeftPane />
        {predictedResult && (
          <PayRangesResult
            useCase={useCase}
            trainingId={useCaseData.latestTrainingModelId}
          />
        )}
        {!predictedResult && (
          <FlexBox
            sx={{
              width: "calc(100% - 320px)",
              p: 4,
            }}
            alignItems="center"
            justifyContent="center">
            <StyledText variant="h5" textAlign="center">
              Please predict 🤔 the data to see the predicted results.
            </StyledText>
          </FlexBox>
        )}
      </FlexBox>
    </LayoutDashboard>
  );
};

export default React.memo(PayRanges);
