import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/auth";
import payRangesReducer from "./slice/payRanges";
import { saveState } from "./localStorage";
import { loadState } from "./localStorage";

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    payRanges: payRangesReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
});
