import { Box } from "@mui/material";
import useClasses from "../../hooks/useClasses";
import StyledDataGrid from "../DataGrids/StyledDataGrid";
import Card from "../Global/Card/Card";
import { styles } from "./styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PayEquityTables = ({ data = [], loading }) => {
  const classes = useClasses(styles);

  const excelDownloadHandler = () => {
    if (data.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(data);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Employee Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "Employee.xlsx");
    }
  };

  const pdfDownloadHandler = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = Object.keys(data[0]);
    let rows = [];
    for (let i = 0; i < data.length; i++) {
      var temp = Object.values(data[i]);
      rows.push(temp);
    }
    pdf.text(235, 40, "Summary View");
    pdf.autoTable(columns, rows, {
      startY: 65,
      theme: "grid",
      styles: {
        font: "times",
        halign: "center",
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      headStyles: {
        textColor: [0, 0, 0],
        fontStyle: "normal",
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [166, 204, 247],
      },
      rowStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });
    pdf.save("SummaryView");
  };

  return (
    <Box mt={5}>
      <Card
        heading={`Summary View`}
        customCssClassName={classes.tableCard}
        showDownload
        showPdfDownload
        downloadHandler={excelDownloadHandler}
        pdfDownloadHandler={pdfDownloadHandler}>
        <StyledDataGrid data={data} loading={loading} />
      </Card>
    </Box>
  );
};

export default PayEquityTables;
