import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Skeleton from "react-loading-skeleton";
import useClasses from "../../../hooks/useClasses";
import {
  FlexBox,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import { styles } from "../styles";
import { clsx } from "clsx";

const DataCards = ({
  title1,
  title1_value,
  subTitle1,
  title2,
  title2_value,
  subTitle2,
  title3,
  title3_value,
  subTitle3,
  loading,
  customContainerCss,
}) => {
  const classes = useClasses(styles);
  return (
    <Stack
      className={clsx(classes.dataContentBox, customContainerCss)}
      direction="row"
      alignItems="stretch">
      <Box flex={1} className={classes.borderRight}>
        <StyledText variant="h5" clr="text3" fontWeight={500}>
          {title1}
        </StyledText>
        <StyledText variant="h2" clr="primary" fontWeight={500} mt={2}>
          {loading ? <Skeleton /> : title1_value}
        </StyledText>
        {subTitle1 && (
          <StyledText clr="text5" fs={14}>
            {loading ? <Skeleton /> : subTitle1}
          </StyledText>
        )}
      </Box>
      <Stack direction="column" flex={1}>
        <Box flex={1}>
          <StyledText variant="h5" clr="text3">
            {title2}
          </StyledText>
          {loading ? (
            <Skeleton />
          ) : (
            <FlexBox alignItems="center">
              <StyledText fs={24} clr="primary">
                {loading ? <Skeleton /> : title2_value}
              </StyledText>
              {subTitle2 && (
                <StyledText fs={14} clr="text5">
                  {subTitle2}
                </StyledText>
              )}
            </FlexBox>
          )}
        </Box>
        <StyledDivider clr="text5" mb={1} />
        <Box flex={1}>
          <StyledText variant="h5" clr="text3">
            {title3}
          </StyledText>
          {loading ? (
            <Skeleton />
          ) : (
            <FlexBox alignItems="center">
              <StyledText fs={24} clr="primary">
                {loading ? <Skeleton /> : title3_value}
              </StyledText>
              {subTitle3 && (
                <StyledText fs={14} clr="text5">
                  {subTitle3}
                </StyledText>
              )}
            </FlexBox>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default DataCards;
