import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import MisNavbar from "./MisNavbar";

const MisDashboard = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MisNavbar />
      <Box px={4} py={2}>
        <Breadcrumbs />
        <Box mt={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MisDashboard;
