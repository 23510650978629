import { FormControl, InputLabel, MenuItem, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  getInternalCohort,
  getInternalPayRefIndexPost,
  getMarketCohort,
  getOptimizationPayRefIndexPost,
} from "../../api";
import { changeColumnName, numberWithCommas } from "../../helpers/utils";
import useClasses from "../../hooks/useClasses";
import { StyledSelect, StyledText } from "../../theme/commonStyles";
import Card from "../Global/Card/Card";
import DataCards from "./DataCards/DataCards";
import TotalCards from "./DataCards/TotalCards";
import PayEquityTables from "./PayEquityTables";
import PayRefIndexGraph from "./PayRefIndexGraph";
import SaveReportTables from "./SaveReportTables";
import { styles } from "./styles";

const PayEquityGraphData = ({
  latestTraining,
  filters,
  trainingFieldsData,
  showReports = true,
  isPayAnalytics,
}) => {
  const [selectedCohort, setSelectedCohort] = useState("");
  const [pRIDataLoading, setPRIDataLoading] = useState(false);
  const [pRIData, setPRIData] = useState(null);
  const [cohortDataLoading, setCohortDataLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState({
    labels: [],
    correction: [],
    excess: [],
  });
  const classes = useClasses(styles);

  useEffect(() => {
    if (trainingFieldsData.length > 0) {
      const temp = trainingFieldsData.filter(
        (item) => item.name === latestTraining.training_params.performanceField
      );
      setSelectedCohort(temp[0].name);
    }
  }, [latestTraining.training_params.performanceField, trainingFieldsData]);

  const fetchData = useCallback(async () => {
    setPRIDataLoading(true);
    const data = await (isPayAnalytics
      ? getOptimizationPayRefIndexPost(latestTraining.id, {
          cohort: selectedCohort,
          filters,
        })
      : getInternalPayRefIndexPost(latestTraining.id, {
          cohort: selectedCohort,
          filters,
        }));
    if (data) {
      setPRIData(data);
    }
    setPRIDataLoading(false);
  }, [filters, isPayAnalytics, latestTraining.id, selectedCohort]);

  const fetchCohortData = useCallback(async () => {
    setCohortDataLoading(true);
    const res = await (isPayAnalytics
      ? getMarketCohort(latestTraining.id, {
          cohort: selectedCohort,
          filters,
        })
      : getInternalCohort(latestTraining.id, {
          cohort: selectedCohort,
          filters,
        }));
    if (res?.message !== "Invalid User") {
      const temp1 = { labels: [], correction: [], excess: [] };
      temp1.labels = Object.values(
        latestTraining.training_params.performance_field_hierarchy
      );
      temp1.labels.forEach((item) => {
        temp1.correction.push(Math.round(res[item].below_min));
        temp1.excess.push(Math.round(res[item].above_max));
      });
      const temp = Object.values(
        latestTraining.training_params.performance_field_hierarchy
      ).map((key) => {
        const obj = {
          [selectedCohort]: key,
          ...res[key],
        };
        obj.pay_reference_index =
          (Math.round(obj.pay_reference_index * 100) || 0) + "%";
        obj.sum_of_correction = Math.round(obj.sum_of_correction);
        obj.sum_of_excess = Math.round(obj.sum_of_excess);
        return obj;
      });

      setGraphData(temp1);

      // need to find some better approach...
      const temp3 = temp.map((item) => {
        const obj = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            obj[changeColumnName(key)] = item[key];
          }
        }
        return obj;
      });
      setTableData(temp3);
    }
    setCohortDataLoading(false);
  }, [
    filters,
    isPayAnalytics,
    latestTraining.id,
    latestTraining.training_params.performance_field_hierarchy,
    selectedCohort,
  ]);

  useEffect(() => {
    if (selectedCohort) {
      fetchData();
      fetchCohortData();
    }
  }, [fetchCohortData, fetchData, latestTraining, selectedCohort]);

  return (
    <>
      {/* Cohort Dropdown */}
      <FormControl sx={{ mb: 2, mt: 2 }} fullWidth>
        <InputLabel sx={{ left: "-14px" }} id="cohort-list-label">
          Select Cohort
        </InputLabel>
        <StyledSelect
          labelId={`cohort-list-label`}
          id={`cohort-list`}
          label="Select Cohort"
          value={selectedCohort || ""}
          onChange={(e) => {
            setSelectedCohort(e.target.value);
          }}
          name="cohort-list"
          required
          variant="standard">
          {trainingFieldsData.map((column, i) => (
            <MenuItem value={column.name} key={i}>
              {column.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {selectedCohort ? (
        <>
          <Stack direction="row" spacing={2}>
            {/* Graph */}
            <Card
              heading="Cohort Outliers"
              customCssClassName={classes.graphCard}>
              {cohortDataLoading ? (
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Skeleton style={{ width: "100%", height: "100%" }} />
                </Box>
              ) : (
                <PayRefIndexGraph data={graphData} />
              )}
            </Card>
            {/* Data Cards */}
            <Stack direction="column" spacing={4}>
              <Card
                heading={"Pay Parity"}
                customCssClassName={classes.graphCard}>
                <DataCards
                  title1={`Pay Parity`}
                  title1_value={`${
                    Math.round(pRIData?.pay_reference_index * 100) || 0
                  }%`}
                  title2=" Within Range"
                  title2_value={pRIData?.within_range || 0}
                  title3="Total Employees"
                  title3_value={pRIData?.total_employees || 0}
                  loading={pRIDataLoading}
                />
                <DataCards
                  title1={`Outliers`}
                  title1_value={pRIData?.outliers || 0}
                  subTitle1={`(${
                    Math.round(
                      (pRIData?.outliers / pRIData?.total_employees) * 100
                    ) || 0
                  }% of total employees)`}
                  title2="Above Max"
                  title2_value={pRIData?.above_max || 0}
                  subTitle2={`(${
                    Math.round(
                      (pRIData?.above_max / pRIData?.total_employees) * 100
                    ) || 0
                  }% of total employees)`}
                  title3="Below Min"
                  title3_value={pRIData?.below_min || 0}
                  subTitle3={`(${
                    Math.round(
                      (pRIData?.below_min / pRIData?.total_employees) * 100
                    ) || 0
                  }% of total employees)`}
                  loading={pRIDataLoading}
                />
              </Card>
              <Stack direction="row" spacing={2}>
                <TotalCards
                  heading="Total Excess Amount"
                  value={numberWithCommas(Math.round(pRIData?.sum_of_excess))}
                  subValue={
                    latestTraining?.multi_currency_params
                      ? "USD"
                      : "Local Currency"
                  }
                  loading={pRIDataLoading}
                />
                <TotalCards
                  heading=" Total Correction Amount"
                  value={numberWithCommas(
                    Math.round(pRIData?.sum_of_correction)
                  )}
                  subValue={
                    latestTraining?.multi_currency_params
                      ? "USD"
                      : "Local Currency"
                  }
                  loading={pRIDataLoading}
                />
              </Stack>
            </Stack>
          </Stack>
          <PayEquityTables data={tableData} loading={cohortDataLoading} />
        </>
      ) : (
        <StyledText textAlign="center" clr="text4" fs={14}>
          Please Select the cohort to see the statistics
        </StyledText>
      )}
      {showReports && (
        <SaveReportTables
          latestTraining={latestTraining}
          filters={filters}
          cohortDataLoading={cohortDataLoading}
        />
      )}
    </>
  );
};

export default React.memo(PayEquityGraphData);
