import { useNavigate } from "react-router-dom";
import { FlexBox, StyledButton, StyledText } from "../../theme/commonStyles";
import LayoutDashboard from "../Layout/LayoutDashboard";
import { ReactComponent as UnauthorizedSvg } from "../../assets/unauthorized.svg";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <LayoutDashboard>
      <FlexBox col alignItems="center" justifyContent="center" height="80vh">
        <UnauthorizedSvg />
        <StyledText
          variant="h4"
          textAlign="center"
          mt={4}
          textTransform="capitalize">
          You Don't have access to this page
        </StyledText>
        <StyledText variant="p" textAlign="center">
          Please contact your admin for support
        </StyledText>
        <StyledButton variant="contained" sx={{ mt: 2 }} onClick={goBack}>
          GO Back
        </StyledButton>
      </FlexBox>
    </LayoutDashboard>
  );
};

export default Unauthorized;
