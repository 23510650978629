import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import NavBar from "./NavBar/NavBar";

function LayoutDashboard({ children }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <NavBar />
        <Box
          component="main"
          sx={{
            backgroundColor: "#fff",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              borderRadius: "10px",
              background: "transparent",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",

              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#D9D9D9",
              borderRadius: "10px",
            },
          }}
        >
          <Toolbar sx={{ height: "70px" }} />
          <Box>{children}</Box>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(LayoutDashboard);
