import { Grid, LinearProgress } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import AdvanceSetup from "./AdvanceSetup";
import InternalDataConfigForm from "./InternalDataConfigForm";
import InternalDataFileUpload from "./InternalDataFileUpload";
import TrainingDetails from "./TrainingDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLatestTraining,
  selectSetupStage,
  setLatestTraining,
} from "./../../../store/slice/auth";
import {
  createTraining,
  fetchTrainings,
  latestTrainings,
  runModel,
} from "../../../api";
import { terminateCurrentTraining } from "../../../api/config.api";
import ErrorService from "./../../../helpers/ErrorService";
import { uploadData } from "./../../../api/index";

const initialVariablesCheck = {
  ordinalVariables: false,
  customTreeModel: false,
  customRegressionModel: false,
};

const InternalDataConfig = () => {
  const latestTraining = useSelector(selectLatestTraining);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [extractedColumns, setExtractedColumns] = useState([]);
  const [valueOfCol, setValueOfCol] = useState({
    data: {},
    options: {},
  });
  const [performanceFieldHierarchy, setPerformanceFieldHierarchy] = useState(
    () => {
      if (latestTraining) {
        return latestTraining.unique_values[
          latestTraining.training_params.performanceField
        ];
      }
      return [];
    }
  );

  const [isUploading, setIsUploading] = useState(false);
  const [runModelLoading, setRunModelLoading] = useState(false);
  const [isMultiCurrencySupport, setIsMultiCurrencySupport] = useState("no");
  const [trainings, setTrainings] = useState([]);
  const [advanceSetupChecks, setAdvanceSetupChecks] = useState(
    initialVariablesCheck
  );
  const org = useSelector(selectSetupStage);
  const dispatch = useDispatch();

  const handleNewTraining = (training) => {
    if (trainings.find((t) => t.id === training.id)) {
      setTrainings(trainings.map((t) => (t.id === training.id ? training : t)));
    } else {
      setTrainings([training, ...trainings]);
    }
  };

  const {
    resetForm,
    setFieldValue,
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      employeeIdField: "",
      compensationField: "",
      performanceField: "",
      exchangeRateField: "",
      currencyField: "",
      currencyDependentField: "",
      selectedVariables: [],
      hierarchy: null,
      weights: {},
    },
    onSubmit: async (values, { setSubmitting }) => {
      const latestTraining = await latestTrainings();
      if (
        latestTraining?.data?.status === "In Progress" ||
        latestTraining?.data?.status === "Queued"
      ) {
        await terminateCurrentTraining();
      }
      const training_params = (({
        employeeIdField,
        compensationField,
        performanceField,
        selectedVariables,
        hierarchy,
        weights,
      }) => ({
        employeeIdField,
        compensationField,
        performanceField,
        selectedVariables,
        hierarchy,
        weights,
      }))(values);

      const multi_currency_params = (({
        exchangeRateField,
        currencyField,
        currencyDependentField,
      }) => ({
        exchangeRateField,
        currencyField,
        currencyDependentField,
      }))(values);

      if (training_params?.weights) {
        if (Object.keys(training_params.weights).length === 0) {
          values.selectedVariables.forEach((item) => {
            training_params.weights[item] = 100;
          });
        }
        if (Object.keys(training_params.weights).length > 0) {
          const temp = Object.keys(training_params.weights);
          const temp2 = values.selectedVariables.filter(
            (item) => !temp.includes(item)
          );
          temp2.forEach((item) => {
            training_params.weights[item] = 100;
          });
        }
      }

      if (!advanceSetupChecks.customTreeModel) {
        delete training_params.hierarchy;
      }
      if (!advanceSetupChecks.customRegressionModel)
        delete training_params.weights;

      if (advanceSetupChecks.ordinalVariables) {
        const temp = Object.keys(valueOfCol.options).filter(
          (item) => valueOfCol.options[item]
        );
        const thisIsObject = Object.keys(valueOfCol.data)
          .filter((key) => temp.includes(key))
          .reduce((obj, key) => {
            obj[key] = Object.assign({}, valueOfCol.data[key]);
            return obj;
          }, {});
        training_params.ordinal_variable = thisIsObject;
      }
      training_params.performance_field_hierarchy = Object.assign(
        {},
        performanceFieldHierarchy
      );
      try {
        const response = await createTraining(
          uploadedFile,
          training_params,
          isMultiCurrencySupport === "yes" ? multi_currency_params : null
        );
        if (response) {
          const latestTraining = await latestTrainings();
          if (latestTraining)
            dispatch(
              setLatestTraining({
                ...latestTraining.data,
                fields: latestTraining.fields,
                unique_values: latestTraining.unique_values_of_columns,
              })
            );
          handleNewTraining(response);
          ErrorService.sendOkMessage("Model training initiated successfully!");
        }
      } catch (err) {
        ErrorService.sendErrorMessage(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileUpload = async (file) => {
    try {
      const training = await uploadData(file);
      resetForm();
      setExtractedColumns(training.data);
      setValueOfCol({
        options: {},
        data: training.unique_values_of_columns,
      });
      setAdvanceSetupChecks(initialVariablesCheck);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleRadioButtonChange = (e) => {
    setIsMultiCurrencySupport(e.target.value);
  };

  const handleRunModel = async () => {
    if (org <= 3) {
      ErrorService.sendWarnMessage({ message: "Please Upload Market Data" });
      return;
    }
    try {
      setRunModelLoading(true);
      const training_params = (({
        employeeIdField,
        compensationField,
        performanceField,
        selectedVariables,
        hierarchy,
        weights,
      }) => ({
        employeeIdField,
        compensationField,
        performanceField,
        selectedVariables,
        hierarchy,
        weights,
      }))(values);

      const multi_currency_params = (({
        exchangeRateField,
        currencyField,
        currencyDependentField,
      }) => ({
        exchangeRateField,
        currencyField,
        currencyDependentField,
      }))(values);

      if (training_params?.weights) {
        if (Object.keys(training_params.weights).length === 0) {
          values.selectedVariables.forEach((item) => {
            training_params.weights[item] = 100;
          });
        }
        if (Object.keys(training_params.weights).length > 0) {
          const temp = Object.keys(training_params.weights);
          const temp2 = values.selectedVariables.filter(
            (item) => !temp.includes(item)
          );
          temp2.forEach((item) => {
            training_params.weights[item] = 1;
          });
        }
      }

      if (!advanceSetupChecks.customTreeModel) {
        delete training_params.hierarchy;
      }
      if (!advanceSetupChecks.customRegressionModel)
        delete training_params.weights;

      if (advanceSetupChecks.ordinalVariables) {
        const temp = Object.keys(valueOfCol.options).filter(
          (item) => valueOfCol.options[item]
        );
        const thisIsObject = Object.keys(valueOfCol.data)
          .filter((key) => temp.includes(key))
          .reduce((obj, key) => {
            obj[key] = Object.assign({}, valueOfCol.data[key]);
            return obj;
          }, {});
        training_params.ordinal_variable = thisIsObject;
      }
      const response = await runModel(latestTraining.id, {
        uploadedFile,
        training_params,
        multi_currency_params:
          isMultiCurrencySupport === "yes" ? multi_currency_params : null,
      });
      if (response) {
        const latestTraining = await latestTrainings();
        if (latestTraining)
          dispatch(
            setLatestTraining({
              ...latestTraining.data,
              fields: latestTraining.fields,
              unique_values: latestTraining.unique_values_of_columns,
            })
          );
        handleNewTraining(response);
        ErrorService.sendOkMessage("Data refresh is started");
      }
    } catch (err) {
      ErrorService.sendErrorMessage(err);
    } finally {
      setRunModelLoading(false);
    }
  };

  useEffect(() => {
    if (!latestTraining) return;

    setUploadedFile(latestTraining.uploaded_file_detail);
    setExtractedColumns(latestTraining.uploaded_file_detail.extracted_columns);
    if (latestTraining?.training_params?.ordinal_variable) {
      let ordVar = latestTraining?.training_params?.ordinal_variable;
      let obj = {
        data: JSON.parse(JSON.stringify(latestTraining.unique_values)),
        options: {},
      };
      Object.keys(ordVar).forEach((item) => {
        obj.options[item] = true;
        obj.data[item] = Object.values(ordVar[item]);
      });
      setValueOfCol(obj);
    } else {
      setValueOfCol({
        ...valueOfCol,
        data: latestTraining.unique_values,
      });
    }

    if (latestTraining.training_params.ordinal_variable) {
      setAdvanceSetupChecks((prev) => ({ ...prev, ordinalVariables: true }));
    }

    if (latestTraining.training_params.weights) {
      setAdvanceSetupChecks((prev) => ({
        ...prev,
        customRegressionModel: true,
      }));
    }
    if (latestTraining.training_params.hierarchy) {
      setAdvanceSetupChecks((prev) => ({ ...prev, customTreeModel: true }));
    }

    if (!latestTraining.training_params) return;

    setPerformanceFieldHierarchy(
      Object.values(latestTraining.training_params.performance_field_hierarchy)
    );

    setFieldValue(
      "compensationField",
      latestTraining.training_params.compensationField
    );
    setFieldValue(
      "performanceField",
      latestTraining.training_params.performanceField
    );
    setFieldValue(
      "selectedVariables",
      latestTraining.training_params.selectedVariables
    );
    setFieldValue("weights", latestTraining.training_params.weights);

    setFieldValue(
      "employeeIdField",
      latestTraining.training_params.employeeIdField
    );
    setFieldValue("hierarchy", latestTraining.training_params.hierarchy);

    if (!latestTraining.multi_currency_params) return;
    setIsMultiCurrencySupport("yes");
    setFieldValue(
      "exchangeRateField",
      latestTraining.multi_currency_params.exchangeRateField
    );
    setFieldValue(
      "currencyField",
      latestTraining.multi_currency_params.currencyField
    );
    setFieldValue(
      "currencyDependentField",
      latestTraining.multi_currency_params.currencyDependentField
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestTraining, setFieldValue]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTrainings();
      if (data) setTrainings(data);
    };
    fetchData();
  }, []);

  return (
    <Grid container width={"100%"} spacing={2}>
      {(isUploading || isSubmitting) && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={4}>
        <InternalDataFileUpload
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setIsUploading={setIsUploading}
          handleFileUpload={handleFileUpload}
        />
      </Grid>
      <Grid item xs={8}>
        <InternalDataConfigForm
          values={values}
          handleChange={handleChange}
          extractedColumns={extractedColumns}
          setFieldValue={setFieldValue}
          isMultiCurrencySupport={isMultiCurrencySupport}
          handleRadioButtonChange={handleRadioButtonChange}
          performanceFieldHierarchy={performanceFieldHierarchy}
          setPerformanceFieldHierarchy={setPerformanceFieldHierarchy}
          valueOfCol={valueOfCol}
        />
      </Grid>
      {/* Advance setup */}
      <Grid item xs={12}>
        <AdvanceSetup
          trainPredictHandler={handleSubmit}
          predictHandler={handleRunModel}
          values={values}
          setFieldValue={setFieldValue}
          advanceSetupChecks={advanceSetupChecks}
          setAdvanceSetupChecks={setAdvanceSetupChecks}
          isSubmitting={isSubmitting}
          runModelLoading={runModelLoading}
          valueOfCol={valueOfCol}
          uploadedFile={uploadedFile}
          setValueOfCol={setValueOfCol}
        />
      </Grid>
      <Grid item xs={12}>
        <TrainingDetails trainings={trainings} />
      </Grid>
    </Grid>
  );
};

export default React.memo(InternalDataConfig);
