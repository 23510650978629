import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
  fetchOptionsForVariable,
  predictData,
  saveComparatorLevelConfigVariables,
} from "../../../api";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledSelect,
  StyledText,
  StyledTextfield,
} from "../../../theme/commonStyles";
import StyledSwitch from "../../Global/FormComponents/StyledSwitch";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Global/Loading/Loading";
import Card from "../../Global/Card/Card";
import CohortCard from "../CohortCard";
import ErrorService from "../../../helpers/ErrorService";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPredictedResult,
  setPredictedResult,
} from "../../../store/slice/payRanges";

const HiringForm = ({ variables, trainingId, levels }) => {
  const predictedResult = useSelector(selectPredictedResult);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);
  const [variableInteractions, setVariableInteractions] = useState(() => {
    return predictedResult ? predictedResult?.variableInteractions : true;
  });

  const [variablesOptions, setVariablesOptions] = useState(
    predictedResult ? predictedResult.variablesOptions : {}
  );
  const [selectedVariable, setSelectedVariable] = useState({});
  const [loading, setLoading] = useState(false);
  // for Cohort
  const [levelVariables, setLevelVariables] = useState({});
  const [variablesData, setVariablesData] = useState(levels);

  const initialValues = variables.reduce(
    (a, v) => ({ ...a, [v.name]: "" }),
    {}
  );
  const formik = useFormik({
    initialValues: predictedResult ? predictedResult.values : initialValues,
    async onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      const param = variablesData.map((item, i) => {
        return {
          ...item,
          variables: levelVariables[i + 1],
        };
      });
      // let temp = param.filter((item) => item.variables.length > 0);
      // temp = temp.map((item, i) => ({ ...item, level: i + 1 }));
      const data = await saveComparatorLevelConfigVariables(trainingId, param);
      if (data.length > variablesData.length) {
        ErrorService.sendOkMessage("New Level Added");
      } else if (data.length > 0) ErrorService.sendOkMessage("Levels Updated");

      const result = await predictData(trainingId, values, "Hiring");
      dispatch(
        setPredictedResult({
          ...result,
          values,
          variablesOptions,
          levelsData: data,
          variableInteractions,
        })
      );
      setSubmitting(false);
    },
  });

  const fetchOptions = async (variableName) => {
    setLoading(true);
    const isFilled = Object.values(formik.values).every((val) => val !== "");
    let selectedVar = { ...selectedVariable };
    if (isFilled) {
      formik.resetForm();
      setSelectedVariable({});
      selectedVar = {};
    }
    let result = await fetchOptionsForVariable(
      trainingId,
      variableName,
      variableInteractions ? selectedVar : {}
    );
    if (result)
      setVariablesOptions({
        ...variablesOptions,
        [variableName]: result.values,
      });
    setLoading(false);
  };

  const handleSelect = (name, i) => {
    setOpen(i);
    fetchOptions(name);
  };

  const handleValueSelection = (colName, colValue) => {
    setSelectedVariable({
      ...selectedVariable,
      [colName]: colValue,
    });
  };

  const handleGo = () => {
    let isValid = true;
    Object.keys(formik.values).forEach(
      (key) => formik.values[key] === "" && (isValid = false)
    );
    if (isValid) {
      formik.handleSubmit();
    } else {
      ErrorService.sendWarnMessage({
        message: "Please fill the form to predict data",
      });
    }
  };

  return (
    <>
      <Card heading="Prediction">
        <Box component="form" width="100%" mt={3}>
          {variables.length > 0 ? (
            <>
              <FormControl
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <StyledText fontWeight="300">Variable Interaction</StyledText>
                <StyledSwitch
                  checked={variableInteractions}
                  onChange={(e, c) => {
                    setVariableInteractions(c);
                    formik.resetForm();
                    setVariablesOptions({});
                  }}
                />
              </FormControl>
              <StyledDivider mt={1} size={2} clr="primary" />
              {variables.map((item, i) => (
                <React.Fragment key={uuidv4()}>
                  {item.is_continous ? (
                    <StyledTextfield
                      type="number"
                      id={`continuos-field-${i}`}
                      label={item.name}
                      variant="standard"
                      name={item.name}
                      value={formik.values[item.name]}
                      onChange={formik.handleChange}
                      required
                      sx={{ mt: 2 }}
                      fullWidth
                    />
                  ) : (
                    <>
                      <FormControl sx={{ mt: 2 }} fullWidth key={uuidv4()}>
                        <InputLabel
                          sx={{ left: "-14px" }}
                          id={`variable-field-label-${i}`}>
                          {item.name}
                        </InputLabel>
                        <StyledSelect
                          open={open === i}
                          labelId={`variable-field-label-${i}`}
                          id={`variable-field-select-${i}`}
                          label={item.name}
                          value={formik.values[item.name]}
                          onChange={formik.handleChange}
                          name={item.name}
                          required
                          onOpen={() => handleSelect(item.name, i)}
                          onClose={() => setOpen(null)}
                          variant="standard">
                          {loading ? (
                            <Loading css={{ my: 2 }} />
                          ) : variablesOptions[item.name] ? (
                            variablesOptions[item.name].map((column, i) => (
                              <MenuItem
                                key={i}
                                value={column}
                                onClick={() =>
                                  handleValueSelection(item.name, column)
                                }>
                                {column}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>Fetching Options...</MenuItem>
                          )}
                        </StyledSelect>
                      </FormControl>
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <StyledText fs={14} clr="text4">
              Fetching Variables...
            </StyledText>
          )}
        </Box>
      </Card>
      <CohortCard
        variablesData={variablesData}
        setVariablesData={setVariablesData}
        levelVariables={levelVariables}
        setLevelVariables={setLevelVariables}
      />
      <FlexBox justifyContent="flex-end" width="100%" mt={3}>
        <StyledButton loading={formik.isSubmitting} onClick={handleGo}>
          Go
        </StyledButton>
      </FlexBox>
    </>
  );
};

export default React.memo(HiringForm);
