import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/upload.svg";
import ErrorService from "../../../helpers/ErrorService";
import { StyledText } from "../../../theme/commonStyles";
import { styles } from "../config.styles";
import useClasses from "./../../../hooks/useClasses";

const InternalDataFileUpload = ({
  uploadedFile,
  setUploadedFile,
  setIsUploading,
  handleFileUpload,
}) => {
  const classes = useClasses(styles);
  const inputFile = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    try {
      setIsUploading(true);
      await handleFileUpload(file);
      setUploadedFile(file);
    } catch (err) {
      ErrorService.sendErrorMessage(err);
    } finally {
      setIsUploading(false);
    }
  };

  const handleClick = () => {
    inputFile.current.click();
  };

  return (
    <Box className={classes.uploadButton}>
      <input
        accept=".license, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        multiple
        type="file"
        ref={inputFile}
        onChange={handleFileChange}
        id="input-file"
      />
      <Button onClick={handleClick} className="upload-btn">
        <UploadIcon className="upload-icon" />
        <StyledText clr="text3" fs={32} className="upload-text">
          {uploadedFile ? " Replace File" : " Upload File"}
        </StyledText>
      </Button>
    </Box>
  );
};

export default React.memo(InternalDataFileUpload);
