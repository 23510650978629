import { Routes, Route } from "react-router-dom";

import Register from "./components/Register";
import SignIn from "./components/SignIn";
import {
  PasswordReset,
  PasswordResetConfirm,
} from "./components/PasswordReset";

import RequireAuth from "./middleware/RequireAuth";
import RequireAdminAuth from "./middleware/RequireAdminAuth";
import TrialEnd from "./components/Global/Trial/TrialEnd";
import ErrorPage from "./components/404Page/ErrorPage";
import Layout from "./components/Layout/Layout";
import Unauthorized from "./components/404Page/Unauthorized";
import React from "react";
import RequireSetup from "./middleware/RequireSetup";
import PayRanges from "./components/PayRanges/PayRanges";
import PayEquity from "./components/PayEquity";
import Report from "./components/PayAnalytics/Report/index";
import SalaryOptimizer from "./components/PayAnalytics/SalaryOptimizer/index";
import Home from "./views/Home";
import Configuration from "./views/Configuration";
import TrainingInProgress from "./components/Global/TrainingInProgress/TrainingInProgress";
import MisLogin from "./components/MIS/MisLogin/MisLogin";
import MisDashboard from "./components/MIS/MisDashboard/MisDashboard";
import OrgsDetails from "./components/MIS/MisOrgs/OrgsDetails";
import AllOrgs from "./components/MIS/MisOrgs/AllOrgs";

const ROLES = {
  User: "user",
  Admin: "admin",
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<SignIn />} />
        <Route path="register" element={<Register />} />
        <Route path="account">
          <Route path="password-reset">
            <Route exact path="" element={<PasswordReset />} />
            <Route
              exact
              path=":uid/:token"
              element={<PasswordResetConfirm />}
            />
          </Route>
        </Route>
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/trial-end" element={<TrialEnd />} />
        <Route path="/training-error" element={<TrainingInProgress />} />
        {/* Mis Login System */}
        <Route path="/mis-admin" element={<MisLogin />} />
        <Route element={<RequireAdminAuth />}>
          <Route path="mis-dashboard" element={<MisDashboard />}>
            <Route index element={<AllOrgs />} />
            <Route path="orgs/:id" element={<OrgsDetails />} />
          </Route>
        </Route>

        {/* we want to protect these routes */}
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}>
          <Route element={<RequireSetup />}>
            <Route path="pay-equity" element={<PayEquity />} />
          </Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}>
          <Route element={<RequireSetup />}>
            <Route path="use-cases" element={<PayRanges />} />
          </Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}>
          <Route element={<RequireSetup />}>
            <Route
              path="pay-analytics/salary-optimizer"
              element={<SalaryOptimizer />}
            />
          </Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}>
          <Route element={<RequireSetup />}>
            <Route path="pay-analytics/reports" element={<Report />} />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="config" element={<Configuration />} />
        </Route>
        {/* catch all */}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

export default React.memo(App);
