import React from "react";
import LayoutDashboard from "../../Layout/LayoutDashboard";
import { ReactComponent as Waiting } from "../../../assets/waiting.svg";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./training.styles";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import { useLocation, useNavigate } from "react-router-dom";

const TrainingInProgress = () => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const trainingStatus = location.state?.trainingStatus;

  return (
    <LayoutDashboard>
      <FlexBox alignItems="center" justifyContent="center" height="80vh" col>
        <FlexBox col alignItems="center" justifyContent="center" mt={3}>
          <StyledText variant="h5">
            {trainingStatus === "In Progress" || trainingStatus === "Queued"
              ? "Training is in Progress"
              : trainingStatus === "Failed"
              ? "Training is failed, please retrain your model"
              : "Training Error"}
          </StyledText>
          <StyledDivider my={2} />
          <StyledButton
            onClick={() => {
              navigate("/config?tabValue=0");
            }}
            sx={{
              mb: 2,
            }}>
            Internal Configuration
          </StyledButton>
        </FlexBox>
        <Waiting className={classes.heroImg} />
      </FlexBox>
    </LayoutDashboard>
  );
};

export default TrainingInProgress;
