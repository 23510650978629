export const styles = (theme) => ({
  totalCountCard: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  icon: {
    width: "32px",
    height: "32px",
  },
  countBoxWrapper: {
    background: "#F4F7FB",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    flex: 1,
  },
  cardWrapper: {
    background: "#F4F7FB !important",
    boxShadow: "4px 4px 10px #D9D9D9 !important",
    borderRadius: "20px !important",
    padding: "2rem !important",
    flex: "1",
  },
  cardWrapperDark: {
    background: "#F8F0EF !important",
  },
  textWrapper: {
    marginTop: "1rem",
    // borderRight: "1px solid #A8A8A8",
  },
  graphCard: {
    background: "#F4F7FB",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    minHeight: "355px",
  },
  graphCardDark: {
    background: "#F8F0EF !important",
  },
  yAxisParams: {
    textAlign: "center",
    flex: 1,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 2rem",
    width: "120px",
    height: "60px",
  },
  dataBlock: {
    width: "150px",
    height: "60px",
    background: "#fff",
  },
  labelBlock: {
    width: "50px",
    padding: 0,
    height: "100%",
  },
  xAxisBlock: {
    width: "150px",
  },
});
