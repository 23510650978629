import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectAdminData } from "../store/slice/auth";
import React from "react";
// import { getCookie } from "../helpers/utils";
import { useSelector } from "react-redux";

function RequireAdminAuth() {
  const auth = useSelector(selectAdminData);
  const location = useLocation();
  // const sessionId = getCookie("mis_sessionid");

  return auth?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/mis-admin" state={{ from: location }} replace />
  );
}

export default React.memo(RequireAdminAuth);
