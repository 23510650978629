import React from "react";
import { FlexBox, StyledButton, StyledText } from "../../theme/commonStyles";
import LayoutDashboard from "../Layout/LayoutDashboard";
import { ReactComponent as ErrorSvg } from "../../assets/404Error.svg";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <LayoutDashboard>
      <FlexBox col alignItems="center" justifyContent="center" height="80vh">
        <ErrorSvg />
        <StyledText variant="h4" textAlign="center" mt={4}>
          Looks Like you have taken the wrong turn
        </StyledText>
        <StyledButton sx={{ mt: 2 }} onClick={goBack}>
          GO Back
        </StyledButton>
      </FlexBox>
    </LayoutDashboard>
  );
};

export default ErrorPage;
