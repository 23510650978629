import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import StyledSwitch from "../../Global/FormComponents/StyledSwitch";
import { styles } from "../config.styles";
import useClasses from "./../../../hooks/useClasses";
import { FlexBox } from "./../../../theme/commonStyles";
import TuneIcon from "@mui/icons-material/Tune";
import { Fragment } from "react";
import { clsx } from "clsx";
import { fetchTrainingFields } from "./../../../api/index";
import ErrorService from "./../../../helpers/ErrorService";
import { useSelector } from "react-redux";
import { selectLatestTraining } from "../../../store/slice/auth";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0.5rem",
  margin: `0 0 8px 0`,
  // change background color if dragging
  background: isDragging ? "lightgreen" : "#009EF1",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: "1rem",
  background: isDraggingOver ? "lightblue" : "transparent",
  width: "100%",
});

const AdvanceSetup = ({
  trainPredictHandler,
  predictHandler,
  values,
  setFieldValue,
  advanceSetupChecks,
  setAdvanceSetupChecks,
  isSubmitting,
  runModelLoading,
  valueOfCol,
  uploadedFile,
  setValueOfCol,
}) => {
  const latestTraining = useSelector(selectLatestTraining);
  const [advanceSetup, setAdvanceSetup] = useState(false);
  const [variablesCheckbox, setVariablesCheckbox] = useState(() => {
    let obj = {};
    values.selectedVariables.forEach((item) => {
      obj[item] = false;
    });
    return obj;
  });
  const [isOrdinalVariableDialogOpen, setIsOrdinalVariableDialogOpen] =
    useState(false);
  const [dialogData, setDialogData] = useState(null);
  const classes = useClasses(styles);

  useEffect(() => {
    let isTrue = false;
    Object.values(advanceSetupChecks).forEach((item) => {
      if (item) isTrue = true;
    });
    setAdvanceSetup(isTrue);
  }, [advanceSetupChecks]);

  useEffect(() => {
    let obj = { ...variablesCheckbox };
    Object.keys(valueOfCol.options).forEach((key) => {
      obj[key] = valueOfCol.options[key];
    });
    setVariablesCheckbox(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueOfCol]);

  const getSliderValue = (val) => {
    if (!values.weights) return 100;

    const wightVal = values.weights.hasOwnProperty(val);
    if (wightVal) {
      return values.weights[val];
    } else {
      return 100;
    }
  };

  const handleSliderChange = (variable, value) => {
    setFieldValue("weights", {
      ...values.weights,
      [variable]: value,
    });
  };

  const dialogToggleHandler = (data) => {
    setIsOrdinalVariableDialogOpen(!isOrdinalVariableDialogOpen);
    setDialogData(data);
  };

  const ordinalVariableCheck = async (e) => {
    setAdvanceSetupChecks({
      ...advanceSetupChecks,
      ordinalVariables: e.target.checked,
    });
    if (e.target.checked) {
      let data;
      if (uploadedFile?.name) {
        data = await fetchTrainingFields(
          uploadedFile,
          values.performanceField,
          values.compensationField
        );
      } else data = latestTraining;
      if (data?.fields) {
        const filteredCol = data.fields.filter((item) => !item.is_continous);
        const colWhichAreNotContinuos = filteredCol.map((item) => item.name);
        const thisIsObject = Object.keys(valueOfCol.data)
          .filter((key) => colWhichAreNotContinuos.includes(key))
          .reduce((obj, key) => {
            obj[key] = valueOfCol.data[key];
            return obj;
          }, {});
        setValueOfCol({
          ...valueOfCol,
          data: thisIsObject,
        });
      }
    }
  };

  const handleAdvanceSetupToggle = (checked) => {
    // if (!advanceSetup) {
    //   if (!uploadedFile?.name) {
    //     ErrorService.sendWarnMessage({
    //       message: "Please upload a file to start new training",
    //     });
    //   } else setAdvanceSetup(checked);
    // } else setAdvanceSetup(checked);
    setAdvanceSetup(checked);
  };

  const handleTrainModel = () => {
    if (!uploadedFile?.name) {
      ErrorService.sendWarnMessage({
        message: "Please upload a file to start new training",
      });
    } else {
      trainPredictHandler();
    }
  };

  const handleRunModel = () => {
    if (!uploadedFile?.name) {
      ErrorService.sendWarnMessage({
        message: "Please upload a file to refresh model",
      });
    } else {
      predictHandler();
    }
  };

  const CustomCheckbox = ({
    label,
    checked,
    handleChecked,
    disabled = false,
  }) => {
    return (
      <FlexBox alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={checked}
              onChange={handleChecked}
              disabled={disabled}
            />
          }
          sx={{ mr: 0, mb: 0.5 }}
        />
        <StyledText clr="text3" sx={{ width: "150px" }}>
          {label}
        </StyledText>
      </FlexBox>
    );
  };

  function SimpleDialog() {
    const [data, setData] = useState(valueOfCol.data);

    const handleSave = () => {
      setValueOfCol({
        ...valueOfCol,
        data,
      });
      dialogToggleHandler();
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
      const temp = JSON.parse(JSON.stringify(data));
      let arr = temp[dialogData];

      console.log(temp);
      console.log(arr);

      arr = reorder(arr, result.source.index, result.destination.index);

      temp[dialogData] = arr;

      console.log(temp);
      console.log(arr);

      setData(temp);
    };

    function MyListItem(props) {
      const { item, index } = props;
      const idRef = useRef(generateUniqueId());

      return (
        <Draggable draggableId={idRef.current} index={index}>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                ),
              }}
              sx={{
                borderRadius: "10px",
                background: "#009EF1",
              }}>
              <StyledText clr="text1" textAlign="center">
                {item}
              </StyledText>
            </Box>
          )}
        </Draggable>
      );
    }

    return (
      <Dialog
        onClose={dialogToggleHandler}
        open={isOrdinalVariableDialogOpen}
        className={classes.dialogWrapper}>
        <DialogTitle sx={{ pb: 0 }}>
          <StyledText fs={18} fontWeight={600} textAlign="center">
            {dialogData}
          </StyledText>
        </DialogTitle>
        <StyledDivider size={2} />
        <Stack direction={"row"} className="fieldsWrapper">
          <FlexBox flexcenter="true" width="20px" mr={2}>
            <StyledText
              clr="text3"
              fs={14}
              sx={{
                display: "inline-block",
                whiteSpace: "nowrap",
                fontWeight: 500,
                transform: "rotate(90deg)",

                "& .gg-arrow-long-right": {
                  boxSizing: "border-box",
                  position: "relative",
                  display: "block",
                  transform: "scale(var(--ggs,1))",
                  borderTop: "2px solid transparent",
                  borderBottom: "2px solid transparent",
                  boxShadow: "inset 0 0 0 2px",
                  width: "50px",
                  height: "6px",
                },
                "& .gg-arrow-long-right::after": {
                  content: '""',
                  display: "block",
                  boxSizing: "border-box",
                  position: "absolute",
                  width: "6px",
                  height: "6px",
                  borderTop: "2px solid",
                  borderRight: "2px solid",
                  transform: "rotate(45deg)",
                  right: "0",
                  bottom: "-2px",
                },
              }}>
              <span className="gg-arrow-long-right"></span>
            </StyledText>
          </FlexBox>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <Stack
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  {data[dialogData] &&
                    data[dialogData].map((item, index) => (
                      <MyListItem key={index} item={item} index={index} />
                    ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            autoFocus
            onClick={dialogToggleHandler}
            className={"actionButton"}
            variant="outlined"
            size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={"actionButton"}
            variant="contained"
            size="small">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
      {isOrdinalVariableDialogOpen && <SimpleDialog />}
      <Stack direction="column" my={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <StyledText fontWeight="300" mr={2}>
              Advance Setup
            </StyledText>
            <StyledSwitch
              checked={advanceSetup}
              onChange={(e, c) => handleAdvanceSetupToggle(c)}
            />
          </FormControl>
          {!advanceSetup && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <StyledButton
                disabled={latestTraining?.status === "In Progress"}
                onClick={handleTrainModel}
                loading={isSubmitting}>
                Train + Predict
              </StyledButton>
              <StyledButton
                disabled={latestTraining?.status === "In Progress"}
                loading={runModelLoading}
                onClick={handleRunModel}>
                Predict
              </StyledButton>
            </Stack>
          )}
        </Stack>
        <StyledDivider clr="gray2" />
        {advanceSetup && (
          <Box className={classes.advanceSetupWrapper}>
            <Stack
              direction="row"
              spacing={10}
              width="100%"
              alignItems="center"
              pr={10}
              mb={5}>
              <CustomCheckbox
                label="Ordinal Variables"
                checked={advanceSetupChecks.ordinalVariables}
                handleChecked={(e) => ordinalVariableCheck(e)}
              />
              <FlexBox
                className={clsx(
                  classes.optionCard,
                  !advanceSetupChecks.ordinalVariables &&
                    classes.optionCardDisabled
                )}
                col
                flex={1}>
                {values.selectedVariables.length > 0 ? (
                  values.selectedVariables.map((item, i) => (
                    <FlexBox alignItems="center" key={i}>
                      <CustomCheckbox
                        label={item}
                        checked={variablesCheckbox[item]}
                        handleChecked={(e) => {
                          setVariablesCheckbox({
                            ...variablesCheckbox,
                            [item]: e.target.checked,
                          });
                          setValueOfCol({
                            ...valueOfCol,
                            options: {
                              ...valueOfCol.options,
                              [item]: e.target.checked,
                            },
                          });
                        }}
                        disabled={!advanceSetupChecks.ordinalVariables}
                      />
                      <IconButton
                        onClick={() => dialogToggleHandler(item)}
                        sx={{ ml: 5 }}
                        disabled={
                          !advanceSetupChecks.ordinalVariables ||
                          !variablesCheckbox[item]
                        }>
                        <TuneIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </FlexBox>
                  ))
                ) : (
                  <StyledText clr="text3">
                    Please select fields first!
                  </StyledText>
                )}
              </FlexBox>
            </Stack>
            <Stack
              direction="row"
              spacing={10}
              width="100%"
              alignItems="center"
              pr={10}
              mb={5}>
              <CustomCheckbox
                label="Custom Decision Tree Model"
                checked={advanceSetupChecks.customTreeModel}
                handleChecked={(e) =>
                  setAdvanceSetupChecks({
                    ...advanceSetupChecks,
                    customTreeModel: e.target.checked,
                  })
                }
              />
              <FlexBox
                className={clsx(
                  classes.optionCard,
                  !advanceSetupChecks.customTreeModel &&
                    classes.optionCardDisabled
                )}
                col
                flex={1}>
                <Autocomplete
                  disabled={!advanceSetupChecks.customTreeModel}
                  sx={{ mb: 3 }}
                  multiple
                  id="hierarchy-variables"
                  options={values.selectedVariables}
                  getOptionLabel={(option) => option}
                  name="hierarchy"
                  value={values.hierarchy ? values.hierarchy : []}
                  onChange={(_event, value) => {
                    setFieldValue("hierarchy", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select Hierarchy"
                      placeholder="Fields"
                    />
                  )}
                />
              </FlexBox>
            </Stack>
            <Stack
              direction="row"
              spacing={10}
              width="100%"
              alignItems="center"
              pr={10}
              mb={5}>
              <CustomCheckbox
                label="Custom Regression Model"
                checked={advanceSetupChecks.customRegressionModel}
                handleChecked={(e) =>
                  setAdvanceSetupChecks({
                    ...advanceSetupChecks,
                    customRegressionModel: e.target.checked,
                  })
                }
              />
              <FlexBox
                className={clsx(
                  classes.optionCard,
                  !advanceSetupChecks.customRegressionModel &&
                    classes.optionCardDisabled
                )}
                col
                flex={1}>
                {values.selectedVariables.length > 0 ? (
                  values.selectedVariables.map((v) => (
                    <Fragment key={v}>
                      <Grid container alignItems="center" mb={2}>
                        <Grid item xs={4}>
                          <StyledText clr="text3" sx={{ width: "200px" }}>
                            {v}
                          </StyledText>
                        </Grid>
                        <Grid item xs={8}>
                          <Slider
                            disabled={!advanceSetupChecks.customRegressionModel}
                            valueLabelDisplay="auto"
                            value={getSliderValue(v)}
                            onChange={(e) =>
                              handleSliderChange(v, e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <StyledText clr="text3">
                    Please select fields first!
                  </StyledText>
                )}
              </FlexBox>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"flex-end"}
              spacing={2}>
              <StyledButton
                disabled={latestTraining?.status === "In Progress"}
                onClick={handleTrainModel}
                loading={isSubmitting}>
                Train + Predict
              </StyledButton>
              <Button
                disabled={latestTraining?.status === "In Progress"}
                loading={runModelLoading}
                onClick={handleRunModel}
                sx={{
                  width: "100px",
                  color: "#005776",
                  textTransform: "capitalize",
                  fontWeight: 600,
                  background:
                    "linear-gradient(105.78deg, #F4F7FB 2.22%, #E6E8FA 100%)",
                  boxShadow: "-6px 12px 24px rgba(0, 88, 120, 0.1)",
                }}>
                Predict
              </Button>
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default React.memo(AdvanceSetup);
