import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import useClasses from "../../../hooks/useClasses";
import {
  FlexBox,
  StyledButton,
  StyledCard,
  StyledText,
} from "../../../theme/commonStyles";
import { styles } from "./styles";
import { ReactComponent as ExcelSvg } from "../../../assets/excel.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Card = ({
  heading,
  children,
  isHeadingCenter = true,
  customCssClassName,
  customContainerCss = {},
  showDownload = false,
  downloadHandler = () => {},
  pdfDownloadHandler = () => {},
  isCollapsible,
  isOpen,
  isPositionLeft,
  handleCollapse,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useClasses(styles);
  return (
    <StyledCard
      sx={{ p: 2, ...customContainerCss }}
      col
      className={customCssClassName}>
      <FlexBox
        alignItems="center"
        sx={{ position: "relative", mb: showDownload ? 1 : 0 }}>
        <StyledText
          className={classes.cardText}
          width={"100%"}
          textAlign="center"
          fontWeight={600}>
          {heading}
        </StyledText>
        {isCollapsible && (
          <IconButton
            sx={{
              position: "absolute",
              right: isPositionLeft ? "unset" : 0,
              left: isPositionLeft ? 0 : "unset",
            }}
            size="small"
            onClick={handleCollapse}>
            {!isOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </IconButton>
        )}
        {showDownload && (
          <>
            <StyledButton
              sx={{ position: "absolute", right: 0 }}
              onClick={handleClick}>
              Download
            </StyledButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "5px",
                },
              }}>
              <MenuItem
                onClick={downloadHandler}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                EXCEL &nbsp;
                <ExcelSvg />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  pdfDownloadHandler();
                  handleClose();
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                PDF
                <PictureAsPdfIcon />
              </MenuItem>
            </Menu>
          </>
        )}
      </FlexBox>
      <Box className={classes.divider} mt={1}>
        &nbsp;
      </Box>
      <Box flex={1}>{children}</Box>
    </StyledCard>
  );
};

export default Card;
