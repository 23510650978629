import ErrorService from "../helpers/ErrorService";
import { sendRequest } from "./index";

export const terminateCurrentTraining = async (trainingId) => {
  try {
    const res = await sendRequest(`/api/training/terminate-training-task/`, {
      id: trainingId,
    });
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};
