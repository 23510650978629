import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import { Box } from "@mui/material";
import { StyledText } from "../../theme/commonStyles";

const PayRefIndexGraph = ({ data }) => {
  const canvasRef = useRef(null);
  const barChartData = {
    labels: data.labels,
    datasets: [
      {
        label: "Excess",
        stack: "Stack 0",
        backgroundColor: "rgba(182, 36, 36, 0.79)",
        data: data.excess.map((k) => -k),
      },
      {
        label: "Correction",
        stack: "Stack 0",
        backgroundColor: "#009EF1",
        data: data.correction,
      },
    ],
  };
  useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      new Chart(ctx, {
        type: "horizontalBar",
        data: barChartData,
        options: {
          title: {
            display: true,
          },
          tooltips: {
            intersect: false,
            callbacks: {
              label: (c) => {
                const value = Number(c.value);
                const positiveOnly = value < 0 ? -value : value;
                let retStr = "";
                if (c.datasetIndex === 0) {
                  retStr += `Excess: ${positiveOnly.toString()}`;
                } else {
                  retStr += `Correction: ${positiveOnly.toString()}`;
                }
                return retStr;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            position: "bottom",
          },
          scales: {
            xAxes: [
              {
                stacked: false,
                ticks: {
                  beginAtZero: true,
                  callback: (v) => {
                    return v < 0 ? -v : v;
                  },
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
                position: "left",
              },
            ],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {data.labels.length > 0 ? (
        <canvas
          ref={canvasRef}
          style={{ width: "100%", height: "100%" }}
        ></canvas>
      ) : (
        <StyledText fs={18} clr="text5" textAlign="center" mt={2}>
          Opps! something went wrong
        </StyledText>
      )}
    </Box>
  );
};

export default PayRefIndexGraph;
