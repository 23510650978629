import React, { useState, useEffect, useCallback } from "react";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { selectLatestTraining, setOrgDetails } from "./../store/slice/auth";
import { fetchOrgDetails, getUseCasesPredictionCount } from "../api";
import LayoutDashboard from "../components/Layout/LayoutDashboard";
import { FlexBox } from "../theme/commonStyles";
import SetupContainer from "../components/SetupModal/SetupContainer";
import CountsSection from "./../components/Home/CountsSection";
import TabsLayout from "./../components/Home/TabsLayout";
import { getSpendOptimizerTotals } from "./../api/index";
import { Box } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import ErrorService from "./../helpers/ErrorService";

function Home() {
  const latestTraining = useSelector(selectLatestTraining);
  const [org, setOrg] = useState(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [useCasePredictionCount, setUseCasePredictionCount] = useState({});
  const [spendOptimizerTotals, setSpendOptimizerTotals] = useState({
    total_employee: 0,
    total_comp_spend: 0,
    total_P1_spend: 0,
    total_P2_spend: 0,
  });

  //API Call
  const fetchOverviewData = useCallback(async () => {
    if (latestTraining?.status === "In Progress") {
      ErrorService.sendWarnMessage({
        message: "Please wait training process to complete!",
      });
    } else {
      if (latestTraining?.id) {
        const res1 = await getSpendOptimizerTotals(latestTraining.id);
        const res2 = await getUseCasesPredictionCount(latestTraining.id);
        if (res1 && res2) {
          setSpendOptimizerTotals(res1.data);
          const temp = {};
          res2.forEach((item) => {
            temp[item.prediction] = item.count;
          });
          setUseCasePredictionCount(temp);
        }
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrgAPI = useCallback(async () => {
    const data = await fetchOrgDetails();
    if (data) {
      setOrg(data.setup_stage);
      dispatch(setOrgDetails(data));
    }
  }, [dispatch]);

  // Effects Hooks
  useEffect(() => {
    fetchOrgAPI();
  }, [fetchOrgAPI]);

  useEffect(() => {
    if (org !== null && org >= 3) fetchOverviewData();
  }, [fetchOverviewData, org]);

  return (
    <>
      <LayoutDashboard org={org}>
        {org === null && (
          <Box p={3}>
            <Skeleton style={{ width: "100%", height: "85vh" }} />
          </Box>
        )}
        {org !== null && org !== 4 && (
          <Container sx={{ mt: 4, mb: 4 }}>
            <FlexBox alignItems="center" justifyContent="center">
              <SetupContainer />
            </FlexBox>
          </Container>
        )}

        {org === 4 && latestTraining?.id && (
          <FlexBox col p={3} pb={4}>
            <CountsSection
              spendOptimizerTotals={spendOptimizerTotals}
              useCasePredictionCount={useCasePredictionCount}
              loading={loading}
            />
            <TabsLayout totalData={spendOptimizerTotals} />
          </FlexBox>
        )}
      </LayoutDashboard>
    </>
  );
}

export default React.memo(Home);
