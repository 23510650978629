import React from "react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./misDashboard.styles";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { resetAdmin } from "../../../store/slice/auth";

const MisNavbar = () => {
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetAdmin());
  };
  return (
    <AppBar position="sticky" component="nav" className={classes.root}>
      <Toolbar>
        <FlexBox flex={1} py={0.5} alignItems="center">
          <Logo className={classes.logo} />
          <StyledText fs={18} ml={1} fontWeight={600} clr="secondary">
            Administration
          </StyledText>
        </FlexBox>
        <FlexBox alignItems="center">
          <StyledText variant="caption" fontWeight={600} clr="secondary" mr={1}>
            Welcome, Admin
          </StyledText>
          <IconButton onClick={handleLogout}>
            <LogoutIcon sx={{ color: "#005776" }} />
          </IconButton>
        </FlexBox>
      </Toolbar>
    </AppBar>
  );
};

export default MisNavbar;
