import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useMemo } from "react";

const StyledDataGridPagination = ({
  data,
  levelVariables = [],
  currentPage,
  pageChangeHandler,
  totalRowCount,
  loading,
}) => {
  const rowsWithId = useMemo(() => {
    return data.map((val, index) => {
      return { ...val, id: index };
    });
  }, [data]);

  const columns = useMemo(() => {
    return Object.keys(data[0] || []).map((k) => ({
      field: k,
      headerName: k,
      flex: 1,
      minWidth: 180,
      headerClassName: levelVariables.includes(k)
        ? "highlighted-header"
        : "header",
      cellClassName: levelVariables.includes(k) ? "highlighted-cell" : "cell",
    }));
  }, [data, levelVariables]);

  function CustomToolbar() {
    const btnStyles = {
      p: "5px",
      backgroundColor: "#BBD2EE",
      color: "#000",
      textTransform: "capitalize",
      borderRadius: "5px",
      marginLeft: "0.5rem",
      "&:hover": {
        backgroundColor: "#BBD2EE",
        opacity: 0.8,
      },
    };
    return (
      <GridToolbarContainer sx={{ justifyContent: "flex-end", mb: 1 }}>
        <GridToolbarColumnsButton sx={btnStyles} />
        <GridToolbarFilterButton sx={btnStyles} />
        <GridToolbarDensitySelector sx={btnStyles} />
      </GridToolbarContainer>
    );
  }
  return (
    <div
      style={{
        height: 500,
        width: "100%",
      }}>
      <DataGrid
        page={currentPage}
        pageSize={15}
        rows={rowsWithId}
        columns={columns}
        rowCount={totalRowCount}
        onPageChange={pageChangeHandler}
        getRowId={(payListWithId) => payListWithId.id}
        loading={loading}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          columnMenu: { background: "red", counter: rowsWithId.length },
        }}
        pagination
        paginationMode="server"
        sx={{
          mt: 3,
          border: "none",
          "& .MuiDataGrid-main .MuiDataGrid-columnHeaders": {
            background: "#F4F7FB",
            borderRadius: "8px 8px 0px 0px",
          },
          "& .highlighted-header": {
            backgroundColor: "#BBD2EE",
          },
          "& .highlighted-cell": {
            backgroundColor: "rgba(64, 64, 64, 0.1)",
          },
        }}
      />
    </div>
  );
};

export default StyledDataGridPagination;
