import CryptoJS from "crypto-js";

export const compareObjects = function (o1, o2) {
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  for (let p1 in o2) {
    if (o2.hasOwnProperty(p1)) {
      if (o1[p1] !== o2[p1]) {
        return false;
      }
    }
  }
  return true;
};

export const compareObjectsArr = function (o1, o2) {
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (JSON.stringify(o1[p]) !== JSON.stringify(o2[p])) {
        return false;
      }
    }
  }
  for (let p1 in o2) {
    if (o2.hasOwnProperty(p1)) {
      if (JSON.stringify(o1[p1]) !== JSON.stringify(o2[p1])) {
        return false;
      }
    }
  }
  return true;
};

export const noMatchObj = function (o1, o2) {
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (JSON.stringify(o1[p]) === JSON.stringify(o2[p])) {
        return false;
      }
    }
  }
  for (let p1 in o2) {
    if (o2.hasOwnProperty(p1)) {
      if (JSON.stringify(o1[p1]) === JSON.stringify(o2[p1])) {
        return false;
      }
    }
  }
  return true;
};

export const noMatchObjArr = function (o1, o2) {
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] === o2[p]) {
        return false;
      }
    }
  }
  for (let p1 in o2) {
    if (o2.hasOwnProperty(p1)) {
      if (o1[p1] === o2[p1]) {
        return false;
      }
    }
  }
  return true;
};

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const changeColumnName = (colName) => {
  if (colName.includes("_")) {
    const tempArr = colName
      .split("_")
      .map((item) => item[0].toUpperCase() + item.slice(1));
    return tempArr.join(" ");
  }

  const tempArr = colName
    .split(" ")
    .map((item) => item[0].toUpperCase() + item.slice(1));
  return tempArr.join(" ");
};

export function setCookie(name, value, date) {
  var expires = "";
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function modulePermissionsCheck(module_permissions) {
  if (module_permissions) {
    if (
      module_permissions["Ranges"] &&
      module_permissions["Ranges"].length === 0
    )
      module_permissions["Ranges"] = [
        "Hiring",
        "Transfer",
        "Retention",
        "Promotion",
        "Role Change",
      ];

    if (
      module_permissions["Analytics"] &&
      module_permissions["Analytics"].length === 0
    )
      module_permissions["Analytics"] = ["Optimizer", "Report"];
  }
}

export const hashPassword = (pass) => {
  let password = process.env.REACT_APP_PASS;
  let salt = process.env.REACT_APP_SALT;
  let iterations = 128;
  let bytes = CryptoJS.PBKDF2(password, salt, {
    keySize: 48,
    iterations: iterations,
  });
  let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
  let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

  let ciphertext = CryptoJS.AES.encrypt(pass, key, {
    iv: iv,
  });

  return ciphertext.toString();
};
