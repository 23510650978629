import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { store } from "./../../store/index";
import GlobalCssPriority from "./Mui/GlobalCssPriority";
import PlainCssPriority from "./Mui/PlainCssPriority";
import theme from "./../../theme/theme";
import { COLORS, FONT_SIZES, SPACING } from "./../../theme/commonStyles";
import ModalContextProvider from "../../contexts/modalContext.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const myTheme = {
  ...theme,
  colors: COLORS,
  fontSizes: FONT_SIZES,
  sizes: SPACING,
};

const AllContextProvider = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiThemeProvider theme={myTheme}>
        <ThemeProvider theme={myTheme}>
          <Provider store={store}>
            <ModalContextProvider>
              <CssBaseline />
              <GlobalCssPriority>
                <PlainCssPriority>{children}</PlainCssPriority>
              </GlobalCssPriority>
            </ModalContextProvider>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default AllContextProvider;
