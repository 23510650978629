import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import InternalDataConfig from "../components/Configuration/InternalDataConfig/InternalDataConfig";
import MarketData from "../components/Configuration/MarketData/MarketData";
import MyOrg from "../components/Configuration/MyOrg/MyOrg";
import UserManagement from "../components/Configuration/UserManagement/UserManagement";
import LayoutDashboard from "../components/Layout/LayoutDashboard";
import { FlexBox, LeftPaneWrapper } from "../theme/commonStyles";
// import { useLocation } from "react-router-dom";
import { useQuery } from "./../hooks/useQuery";
import Banner from "./../components/Global/Banner/Banner";
import { useSelector } from "react-redux";
import { selectUserData } from "./../store/slice/auth";
import LicenseUpload from "../components/Configuration/LicenseUpload/LicenseUpload";

function a11yProps(index) {
  return {
    id: `configs-tab-${index}`,
    "aria-controls": `configs-tabpanel-${index}`,
  };
}

const Configuration = () => {
  const query = useQuery();
  const userData = useSelector(selectUserData);
  const tabValue = query.get("tabValue");
  const [value, setValue] = useState(() => {
    return tabValue ? +tabValue : 0;
  });

  const handleChange = (event, newValue) => {
    const url = new URL(window.location);
    url.searchParams.set("tabValue", newValue);
    window.history.pushState({}, "", url);
    setValue(newValue);
  };

  const getTitle = () => {
    switch (value) {
      case 0:
        return "Internal  Data Config";
      case 1:
        return "Market Data";
      case 2:
        return "User Management";
      case 3:
        return "Org Details";
      default:
        return "";
    }
  };

  const isAdmin = useMemo(() => userData?.role === "admin", [userData]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`config-tabpanel-${index}`}
        aria-labelledby={`config-tab-${index}`}
        {...other}
        style={{
          flex: 1,
          height: "90vh",
          paddingBottom: "1rem",
          overflowY: "auto",
          "&::WebkitScrollbar": {
            display: "none",
          },
          "&::WebkitScrollbarTrack": {
            display: "none",
          },
          "&::WebkitScrollbarThumb": {
            display: "none",
          },
        }}>
        {value === index && <Box sx={{ px: 3, pt: 4 }}>{children}</Box>}
      </div>
    );
  }
  const LeftPane = () => {
    return (
      <LeftPaneWrapper>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="config-tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}>
          <Tab label="Internal Data Config" {...a11yProps(0)} />
          <Tab label="Market Data" {...a11yProps(1)} />
          {isAdmin && <Tab label="User Management" {...a11yProps(2)} />}
          <Tab label="Org Details" {...a11yProps(3)} />
          {isAdmin && <Tab label="License" {...a11yProps(4)} />}
        </Tabs>
      </LeftPaneWrapper>
    );
  };
  return (
    <LayoutDashboard>
      <Banner heading={`Configuration - ${getTitle()}`} />
      <FlexBox>
        <LeftPane />
        <TabPanel value={value} index={0}>
          <InternalDataConfig />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MarketData />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserManagement />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MyOrg />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <LicenseUpload />
        </TabPanel>
      </FlexBox>
    </LayoutDashboard>
  );
};

export default Configuration;
