import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { fetchOptionsForVariable } from "../../api";
import {
  StyledButton,
  StyledDivider,
  StyledSelect,
  StyledText,
} from "../../theme/commonStyles";
import { v4 as uuidv4 } from "uuid";
import { FilterWrapper } from "./../../theme/commonStyles";
import Loading from "../Global/Loading/Loading";
import StyledSwitch from "../Global/FormComponents/StyledSwitch";

const FilterPane = ({
  latestTraining,
  filters,
  setFilters,
  trainingFieldsData,
}) => {
  const [open, setOpen] = useState(null);
  const [variableInteractions, setVariableInteractions] = useState(true);
  const [variablesOptions, setVariablesOptions] = useState({});
  const [selectedVariable, setSelectedVariable] = useState({});
  const [filtersData, setFiltersData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchOptions = async (variableName) => {
    setLoading(true);
    const isFilled =
      Object.values(filtersData).every((val) => val.length !== 0) &&
      Object.keys(filtersData).length === trainingFieldsData.length;
    console.log(isFilled);
    let selectedVar = { ...selectedVariable };
    if (isFilled) {
      setFiltersData({});
      setSelectedVariable({});
      selectedVar = {};
    }
    let result = await fetchOptionsForVariable(
      latestTraining.id,
      variableName,
      variableInteractions ? selectedVar : {}
    );
    if (result)
      setVariablesOptions({
        ...variablesOptions,
        [variableName]: result.values,
      });
    setLoading(false);
  };

  const handleSelect = (name, i) => {
    setOpen(i);
    fetchOptions(name);
  };

  const handleValueSelection = (colName, colValue) => {
    setOpen(null);
    setSelectedVariable({
      ...selectedVariable,
      [colName]: colValue,
    });
  };

  const applyFilters = () => {
    setFilters(filtersData);
  };
  const resetFilters = () => {
    setFiltersData({});
    setFilters({});
  };

  return (
    <>
      <FilterWrapper component="form" width="100%">
        <FormControl
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <StyledText
            fontWeight="600"
            clr="text3"
            textAlign="center"
            width="100%">
            Filters
          </StyledText>
        </FormControl>
        <StyledDivider mt={1} size={2} clr="gray2" />
        <FormControl
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <StyledText fontWeight="300">Variable Interaction</StyledText>
          <StyledSwitch
            checked={variableInteractions}
            onChange={(e, c) => {
              setVariableInteractions(c);
              setVariablesOptions({});
              setFiltersData({});
              setSelectedVariable({});
            }}
          />
        </FormControl>
        <StyledDivider my={1} size={2} clr="primary" />
        {trainingFieldsData.length > 0 ? (
          <>
            {trainingFieldsData.map((item, i) => (
              <React.Fragment key={i}>
                <FormControl sx={{ mt: 2 }} fullWidth key={uuidv4()}>
                  <InputLabel
                    sx={{ left: "-14px" }}
                    id={`variable-field-label-${i}`}>
                    {item.name}
                  </InputLabel>
                  <StyledSelect
                    open={open === i}
                    multiple
                    labelId={`variable-field-label-${i}`}
                    id={`variable-field-select-${i}`}
                    label={item.name}
                    value={filtersData[item.name] || []}
                    onChange={(e) => {
                      setFiltersData({
                        ...filtersData,
                        [item.name]: e.target.value,
                      });
                    }}
                    name={item.name}
                    variant="standard"
                    renderValue={(selected) => selected.join(", ")}
                    onOpen={() => handleSelect(item.name, i)}
                    onClose={() => setOpen(null)}>
                    {loading ? (
                      <Loading css={{ my: 2 }} />
                    ) : variablesOptions[item.name] ? (
                      variablesOptions[item.name].map((column, i) => (
                        <MenuItem
                          key={i}
                          value={column}
                          onClick={() =>
                            handleValueSelection(item.name, column)
                          }>
                          <Checkbox
                            checked={
                              (filtersData[item.name] || []).indexOf(column) >
                              -1
                            }
                          />
                          <ListItemText primary={column} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key={i}>No Options</MenuItem>
                    )}
                  </StyledSelect>
                </FormControl>
              </React.Fragment>
            ))}
          </>
        ) : (
          <StyledText fs={14} clr="text4" mt={2}>
            Fetching Variables...
          </StyledText>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3 }}>
          <StyledButton onClick={resetFilters}>Reset Filters</StyledButton>
          <StyledButton onClick={applyFilters}>Apply Filters</StyledButton>
        </Stack>
      </FilterWrapper>
    </>
  );
};

export default React.memo(FilterPane);
