export const styles = (theme) => ({
  tableCell: {
    textAlign: "center",
  },
  countBoxWrapper: {
    width: "150px",
    background: "#F4F7FB",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
});
