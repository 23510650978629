import { selectAdminToken } from "../store/slice/auth";
import { store } from "../store";
import ErrorService from "../helpers/ErrorService";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const sendRequest = (url, body = null, auth = true, methodType = "POST") => {
  const absoluteURL = new URL(url, baseUrl);
  const token = selectAdminToken(store.getState());
  const commonHeaders = {};

  if (token && auth) {
    commonHeaders["Maa-Token"] = `${token}`;
  }

  if (methodType === "PUT") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "PUT",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  if (methodType === "PATCH") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "PATCH",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  if (methodType === "DELETE") {
    const headers = { ...commonHeaders };

    return fetch(absoluteURL, {
      method: "DELETE",
      headers,
    });
  }

  if (body && methodType === "POST") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "POST",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  return fetch(absoluteURL, { headers: { ...commonHeaders } });
};

export const fetchAllOrgDetails = async () => {
  try {
    const res = await sendRequest("/api/paylens-admin/mis-data/");
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const updateOrgDetails = async (payload) => {
  try {
    const res = await sendRequest(
      "/api/paylens-admin/mis-data/",
      payload,
      true,
      "PUT"
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};
