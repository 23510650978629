import { Button, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { performanceGrid } from "../../../api";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import Card from "../../Global/Card/Card";
import useClasses from "../../../hooks/useClasses";
import { styles } from "../styles";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
import { numberWithCommas } from "./../../../helpers/utils";

const PerformanceGrid = ({
  latestTraining,
  filters,
  setSelectedTile,
  totalData,
}) => {
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useClasses(styles);

  const fetchData = useCallback(async () => {
    const data = await performanceGrid(latestTraining.id, {
      filters: filters,
    });
    if (data) {
      let tempObj = {};
      data.forEach((obj) => {
        tempObj[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]];
      });
      setPerformanceData(tempObj);
    }
    setLoading(false);
  }, [filters, latestTraining.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selectTileHandler = (e, value) => {
    setSelectedTile(value);
  };

  return (
    <Card heading="Optimizer Grid" customCssClassName={classes.graphCard}>
      <FlexBox py={7}>
        {loading ? (
          <Skeleton style={{ width: "500px", height: "320px" }} />
        ) : (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <FlexBox flexcenter="true" width="20px">
              <StyledText
                clr="text3"
                fs={14}
                sx={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  transform: "rotate(-90deg)",
                }}>
                Prediction Range ⟶
              </StyledText>
            </FlexBox>
            <Stack>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={14} clr="gray2" fontWeight={600}>
                  Above
                  <br />
                  Outlier
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={14} clr="gray2" fontWeight={600}>
                  Within
                  <br />
                  Range
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={14} clr="gray2" fontWeight={600}>
                  Below
                  <br />
                  Outlier
                </StyledText>
              </FlexBox>
              <FlexBox
                className={clsx(classes.yAxisParams, classes.labelBlock)}>
                <StyledText fs={14} clr="gray2" fontWeight={600}>
                  &nbsp;
                </StyledText>
              </FlexBox>
            </Stack>
            <Stack direction="column">
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    background: "transparent",
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#d9d9d9",
                    borderRadius: "10px",
                  },
                }}>
                {performanceData &&
                  Object.values(
                    latestTraining.training_params.performance_field_hierarchy
                  ).map((item, i) => (
                    <Stack key={`_key${item}_${i}`} spacing={1}>
                      <Button
                        className={clsx(classes.yAxisParams, classes.dataBlock)}
                        onClick={(e) =>
                          selectTileHandler(
                            e,
                            JSON.stringify({
                              outlier_value: "above_max",
                              performance_value: item,
                            })
                          )
                        }>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].above_max_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].above_max_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_excess_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_excess_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <Button
                        className={clsx(classes.yAxisParams, classes.dataBlock)}
                        onClick={(e) =>
                          selectTileHandler(
                            e,
                            JSON.stringify({
                              outlier_value: "within_range",
                              performance_value: item,
                            })
                          )
                        }>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].within_range_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].within_range_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_within_range_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_within_range_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <Button
                        className={clsx(classes.yAxisParams, classes.dataBlock)}
                        onClick={(e) =>
                          selectTileHandler(
                            e,
                            JSON.stringify({
                              outlier_value: "below_min",
                              performance_value: item,
                            })
                          )
                        }>
                        <Stack direction="row" alignItems="center">
                          <StyledText clr="primary" fs={40} fontWeight={600}>
                            {performanceData[item].below_min_grid}
                          </StyledText>
                          &nbsp;
                          <StyledText clr="text4">
                            (
                            {Math.ceil(
                              (performanceData[item].below_min_grid /
                                totalData.total_employee) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <StyledText fs={14} clr="text2" fontWeight={500}>
                            {numberWithCommas(
                              Math.round(
                                performanceData[item].sum_of_correction_grid
                              )
                            )}
                          </StyledText>
                          &nbsp;
                          <StyledText fs={14} clr="text2">
                            (
                            {Math.ceil(
                              (Math.round(
                                performanceData[item].sum_of_correction_grid
                              ) /
                                totalData.total_comp_spend) *
                                100
                            )}
                            %)
                          </StyledText>
                        </Stack>
                      </Button>
                      <FlexBox
                        className={clsx(
                          classes.yAxisParams,
                          classes.labelBlock
                        )}>
                        <StyledText fs={12} clr="gray2" fontWeight={600}>
                          {item}
                        </StyledText>
                      </FlexBox>
                    </Stack>
                  ))}
              </Stack>
              <StyledText
                textAlign="center"
                clr="text3"
                fs={14}
                whiteSpace="nowrap">
                {latestTraining.training_params.performanceField + "⟶"}
              </StyledText>
            </Stack>
          </Stack>
        )}
      </FlexBox>
    </Card>
  );
};

export default PerformanceGrid;
