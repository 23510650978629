import React, { useCallback, useEffect, useState } from "react";
import Banner from "../Global/Banner/Banner";
import LayoutDashboard from "../Layout/LayoutDashboard";
import { FlexBox } from "../../theme/commonStyles";
import PayEquityGraphData from "./PayEquityGraphData";
import {
  selectDataPermissions,
  selectLatestTraining,
} from "../../store/slice/auth";
import { useSelector } from "react-redux";
import { fetchTrainings, getTrainingFields } from "../../api";
import PayEquityMainData from "./PayEquityMainData";
import FilterPane from "./FilterPane";
import DataRefreshTrainingSelect from "./DataRefreshTrainingSelect";
import { Stack } from "@mui/material";
import useClasses from "../../hooks/useClasses";
import { styles } from "./styles";

const PayEquity = () => {
  const classes = useClasses(styles);
  const latestTraining = useSelector(selectLatestTraining);
  const userDataPermissions = useSelector(selectDataPermissions);

  const [selectedTraining, setSelectedTraining] = useState(latestTraining);
  const [trainings, setTrainings] = useState([]);
  const [filters, setFilters] = useState({});
  const [trainingFieldsData, setTrainingFieldsData] = useState([]);

  const fetchTrainingFields = useCallback(async () => {
    const res = await getTrainingFields(latestTraining.id);
    const permittedCols = Object.keys(userDataPermissions);

    const data = res.fields.filter(
      (item) =>
        latestTraining.uploaded_file_detail.extracted_columns.includes(
          item.name
        ) &&
        !item.is_continous &&
        permittedCols.includes(item.name)
    );
    setTrainingFieldsData(data);
  }, [
    latestTraining.id,
    latestTraining.uploaded_file_detail.extracted_columns,
    userDataPermissions,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTrainings();
      if (data) {
        const result = data.filter((item) => item.status === "Success");
        setTrainings(result);
      }
    };
    fetchData();
    fetchTrainingFields();
  }, [fetchTrainingFields]);

  return (
    <LayoutDashboard>
      <Banner heading={`Pay Equity`} />
      <FlexBox col p={4} pt={1} pb={0}>
        <DataRefreshTrainingSelect
          trainings={trainings}
          selectedTraining={selectedTraining}
          setSelectedTraining={setSelectedTraining}
        />
        <PayEquityMainData latestTraining={selectedTraining} isPayAnalytics={false}/>
        <Stack
          mt={5}
          direction="row"
          spacing={4}
          alignItems="flex-start"
          sx={{ height: "calc(100vh - 70px)", overflow: "hidden" }}>
          <FilterPane
            latestTraining={selectedTraining}
            filters={filters}
            setFilters={setFilters}
            trainingFieldsData={trainingFieldsData}
          />
          <Stack
            direction="column"
            flex={1}
            className={classes.payEquityDataWrapper}>
            <PayEquityGraphData
              latestTraining={selectedTraining}
              filters={filters}
              trainingFieldsData={trainingFieldsData}
              isPayAnalytics={false}
            />
          </Stack>
        </Stack>
      </FlexBox>
    </LayoutDashboard>
  );
};

export default React.memo(PayEquity);
