import { Alert, Paper, Stack } from "@mui/material";
import React from "react";
import useClasses from "../../../hooks/useClasses";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
  StyledTextfield,
} from "../../../theme/commonStyles";
import { styles } from "./misLogin.styles";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { useFormik } from "formik";
import { Box } from "@mui/system";
import { adminSignIn } from "../../../api";
import { useDispatch } from "react-redux";
import { setAdmin } from "../../../store/slice/auth";
import { useNavigate } from "react-router-dom";

const MisLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const response = await adminSignIn(values);
        if (!response.ok) {
          const errors = await response.json();
          if (response.status === 400) setErrors(errors);
          else throw new Error(errors);
          return;
        }

        let data = await response.json();
        if (data.token) {
          dispatch(
            setAdmin({
              token: data.token,
            })
          );
          navigate("/mis-dashboard", { replace: true });
        } else if (data.error) {
          setErrors(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <FlexBox
      className={classes.root}
      alignItems="center"
      justifyContent="center">
      <Stack className={classes.loginWrapper} component={Paper}>
        <FlexBox alignItems="center" justifyContent="center">
          <Logo className={classes.logo} />
        </FlexBox>
        <StyledText
          variant="h6"
          textAlign="center"
          clr="text3"
          fontWeight={600}>
          Admin Login
        </StyledText>
        <StyledDivider />
        <Box component="form" onSubmit={handleSubmit} autoComplete="off">
          {Boolean(errors.error) && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errors.error}
            </Alert>
          )}
          <StyledTextfield
            fullWidth
            variant="standard"
            id="name"
            name="username"
            label="Username"
            margin="normal"
            value={values.username}
            onChange={handleChange}
            autoComplete="off"
          />
          <StyledTextfield
            fullWidth
            type="password"
            variant="standard"
            id="password"
            name="password"
            label="Password"
            margin="normal"
            autoComplete="off"
            value={values.password}
            onChange={handleChange}
          />
          <FlexBox mt={2} alignItems="center" justifyContent="center">
            <StyledButton type="submit">Login</StyledButton>
          </FlexBox>
        </Box>
      </Stack>
    </FlexBox>
  );
};

export default MisLogin;
