import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deleteUser, getAllUsers } from "../../../api";
import ErrorService from "../../../helpers/ErrorService";
import {
  FlexBox,
  StyledButton,
  StyledDivider,
  StyledText,
} from "../../../theme/commonStyles";
import AddUserModal from "./AddUserModal";
import Card from "./../../Global/Card/Card";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const UserManagement = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [users, setUsers] = useState({});
  const [userToBeUpdated, setUserToBeUpdated] = useState(null);
  const [isDeleting, setIsDeleting] = useState(null);

  const columns = [
    "Name",
    "UserName",
    "Email",
    "Role",
    "Actions",
    "Password Reset",
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const toggleAddUserModalClose = () => {
    setAddUserModalOpen(!addUserModalOpen);
  };

  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    const data = await getAllUsers();
    if (data) {
      setUsers(data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const handleUpdateUser = (e, user) => {
    setAddUserModalOpen(true);
    setUserToBeUpdated(user);
  };

  const handleDeleteUser = async (e, id) => {
    setIsDeleting(id);
    const response = await deleteUser(id);
    if (!response.ok) {
      const data = await response.json();
      if (ErrorService.handleResponse(response, data)) return data;
    } else if (response.ok) {
      ErrorService.sendOkMessage("User Deleted Successfully");
      fetchAllUsers();
    }
    setIsDeleting(null);
  };
  return (
    <>
      <AddUserModal
        addUserModalOpen={addUserModalOpen}
        setAddUserModalOpen={setAddUserModalOpen}
        updateData={userToBeUpdated}
        setUserToBeUpdated={setUserToBeUpdated}
        fetchAllUsers={fetchAllUsers}
      />
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <FlexBox justifyContent="space-between" alignItems="center">
            <FlexBox col>
              <StyledText variant="h5" fontWeight={500}>
                User Management
              </StyledText>
              <StyledText variant="p" my={1} clr="text3">
                Add, Update and delete your users
              </StyledText>
            </FlexBox>
            <FlexBox>
              <StyledButton
                sx={{ height: "40px", ml: 1 }}
                onClick={toggleAddUserModalClose}>
                Add Users
              </StyledButton>
            </FlexBox>
          </FlexBox>
        </Grid>
        <StyledDivider />
        <Grid item xs={12}>
          <Card heading="Users List">
            <TableContainer sx={{ mt: 2, maxHeight: "400px" }}>
              {loading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}

              <Table aria-label="users list">
                <TableHead>
                  <TableRow>
                    {columns.map((item, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                        }}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.results && users.results.length > 0 ? (
                    users.results.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          {item.user.first_name + " " + item.user.last_name}
                        </TableCell>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          {item.user.username}
                        </TableCell>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          {item.user.email}
                        </TableCell>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          {item.role}
                        </TableCell>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          <FlexBox alignItems="center" justifyContent="center">
                            <IconButton
                              color="primary"
                              sx={{ ml: 1 }}
                              onClick={(e) => handleUpdateUser(e, item)}>
                              <EditIcon />
                            </IconButton>
                            <LoadingButton
                              loading={isDeleting === item.id ? true : false}
                              onClick={(e) => handleDeleteUser(e, item.id)}
                              sx={{
                                ml: 1,
                                "&:hover": {
                                  background: "transparent",
                                },
                              }}>
                              <IconButton color="error">
                                <DeleteIcon />
                              </IconButton>
                            </LoadingButton>
                          </FlexBox>
                        </TableCell>
                        <TableCell scope="row" sx={{ textAlign: "center" }}>
                          <Link to={item.password_reset_link}>
                            Password Reset Link
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <StyledText fontWeight="bold" textAlign="center">
                          No User added yet
                        </StyledText>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {users?.count > 5 && (
                  <TableFooter>
                    <TablePagination
                      count={users?.count}
                      page={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[5, 10, 15]}
                    />
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserManagement;
