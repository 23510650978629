import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useRef } from "react";
import { useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import { useSelector } from "react-redux";
//import { selectLatestTraining } from "../../../store/slice/auth";
import { FlexBox, StyledText } from "../../../theme/commonStyles";
import Card from "../../Global/Card/Card";

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0.5rem",
  margin: `0 0 8px 0`,
  // change background color if dragging
  background: isDragging ? "lightgreen" : "#fff",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: "1rem",
  background: isDraggingOver ? "lightblue" : "transparent",
  width: 250,
});

const InternalDataConfigForm = ({
  values,
  extractedColumns,
  handleChange,
  setFieldValue,
  isMultiCurrencySupport,
  handleRadioButtonChange,
  performanceFieldHierarchy,
  setPerformanceFieldHierarchy,
  valueOfCol,
}) => {
  //const latestTraining = useSelector(selectLatestTraining);

  const columns = useMemo(() => {
    if (!extractedColumns) return [];
    return extractedColumns;
  }, [extractedColumns]);

  const variableOptions = useMemo(() => {
    return columns.filter((c) => c !== values.compensationField);
  }, [columns, values.compensationField]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      performanceFieldHierarchy,
      result.source.index,
      result.destination.index
    );

    setPerformanceFieldHierarchy(items);
  };

  function MyListItem(props) {
    const { item, index } = props;
    const idRef = useRef(generateUniqueId());

    return (
      <Draggable draggableId={idRef.current} index={index}>
        {(provided, snapshot) => (
          <Paper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            sx={{
              borderRadius: "10px",
              background: "#fff",
            }}>
            <StyledText fs={14} clr="text3">
              {item}
            </StyledText>
          </Paper>
        )}
      </Draggable>
    );
  }

  return (
    <Card heading="Prediction">
      <Box component="form" mt={4}>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="compensation-field-label" sx={{ left: "-14px" }}>
            Employee ID field
          </InputLabel>
          <Select
            labelId="compensation-field-label"
            id="compensation-field-select"
            label="Compensation Field"
            name="employeeIdField"
            variant="standard"
            value={values.employeeIdField}
            onChange={handleChange}>
            {columns.map((column) => (
              <MenuItem key={column} value={column}>
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="compensation-field-label" sx={{ left: "-14px" }}>
            Compensation Field
          </InputLabel>
          <Select
            labelId="compensation-field-label"
            id="compensation-field-select"
            label="Compensation Field"
            name="compensationField"
            variant="standard"
            value={values.compensationField}
            onChange={handleChange}>
            {columns.map((column) => (
              <MenuItem key={column} value={column}>
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          sx={{ mb: 3 }}
          multiple
          id="selected-variables"
          options={variableOptions}
          getOptionLabel={(option) => option}
          name="selectedVariables"
          value={values.selectedVariables}
          onChange={(_event, value) => {
            setFieldValue("selectedVariables", value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Fields"
              placeholder="Fields"
            />
          )}
        />
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="performance-field-label" sx={{ left: "-14px" }}>
            Performance Field
          </InputLabel>
          <Select
            labelId="performance-field-label"
            id="performance-field-select"
            label="Performance Field"
            value={values.performanceField}
            onChange={(e) => {
              handleChange(e);
              //if (latestTraining)
               // setPerformanceFieldHierarchy(
                //  latestTraining.unique_values[e.target.value]
                //);
              //else {
                setPerformanceFieldHierarchy(valueOfCol.data[e.target.value]);
              //}
            }}
            name="performanceField"
            variant="standard">
            {values.selectedVariables.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {values.performanceField && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Performance Fields Hierarchy{" "}
              <StyledText variant="caption">
                (Move the tiles to change the order of the performance fields)
              </StyledText>
            </FormLabel>
            <Stack direction="row" mt={1}>
              <FlexBox flexcenter="true" width="20px" mr={2}>
                <StyledText
                  clr="text3"
                  fs={14}
                  sx={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    transform: "rotate(90deg)",

                    "& .gg-arrow-long-right": {
                      boxSizing: "border-box",
                      position: "relative",
                      display: "block",
                      transform: "scale(var(--ggs,1))",
                      borderTop: "2px solid transparent",
                      borderBottom: "2px solid transparent",
                      boxShadow: "inset 0 0 0 2px",
                      width: "50px",
                      height: "6px",
                    },
                    "& .gg-arrow-long-right::after": {
                      content: '""',
                      display: "block",
                      boxSizing: "border-box",
                      position: "absolute",
                      width: "6px",
                      height: "6px",
                      borderTop: "2px solid",
                      borderRight: "2px solid",
                      transform: "rotate(45deg)",
                      right: "0",
                      bottom: "-2px",
                    },
                  }}>
                  <span className="gg-arrow-long-right"></span>
                </StyledText>
              </FlexBox>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}>
                      {performanceFieldHierarchy?.map((item, index) => (
                        <MyListItem key={index} item={item} index={index} />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </FormControl>
        )}
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <FormLabel id="demo-radio-buttons-group-label">
            Does the data contain multiple currencies ?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={isMultiCurrencySupport}
            onChange={handleRadioButtonChange}
            name="radio-buttons-group">
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {isMultiCurrencySupport === "yes" && (
          <>
            <FormControl sx={{ mb: 3 }} fullWidth>
              <InputLabel id="exchange-rate-field-label" sx={{ left: "-14px" }}>
                Exchange Rate Field
              </InputLabel>
              <Select
                labelId="exchange-rate-field-label"
                id="exchange-rate-field-select"
                label="Exchange Rate Field"
                value={values.exchangeRateField}
                onChange={handleChange}
                name="exchangeRateField"
                variant="standard">
                {columns.map((column) => (
                  <MenuItem key={column} value={column}>
                    {column}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 3 }} fullWidth>
              <InputLabel id="currency-field-label" sx={{ left: "-14px" }}>
                Currency Field
              </InputLabel>
              <Select
                labelId="currency-label"
                id="currency-select"
                label="Currency Field"
                value={values.currencyField}
                onChange={handleChange}
                name="currencyField"
                variant="standard">
                {columns.map((column) => (
                  <MenuItem key={column} value={column}>
                    {column}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 3 }} fullWidth>
              <InputLabel
                id="currency-dependent-field-label"
                sx={{ left: "-14px" }}>
                Currency Dependent Field
              </InputLabel>
              <Select
                labelId="currency-dependent-field-label"
                id="currency-dependent-field-select"
                label="Currency Dependent Field"
                value={values.currencyDependentField}
                onChange={handleChange}
                name="currencyDependentField"
                variant="standard">
                {columns.map((column) => (
                  <MenuItem key={column} value={column}>
                    {column}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Box>
    </Card>
  );
};

export default React.memo(InternalDataConfigForm);
