import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const UserForm = ({ formik }) => {
  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        background: "#F4F7FB",
        boxShadow: "4px 4px 10px #D9D9D9",
        p: 2,
        mt: 3,
      }}>
      {Boolean(formik.errors.non_field_errors) && (
        <Alert severity="error">{formik.errors.non_field_errors}</Alert>
      )}
      <FormControl fullWidth margin="dense">
        <TextField
          id="username"
          label="User Name"
          name="username"
          variant="standard"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={Boolean(formik.errors?.user?.username)}
          helperText={formik.errors?.user?.username}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          type="email"
          label="Email"
          variant="standard"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors?.user?.email)}
          helperText={formik.errors?.user?.email}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          label="First Name"
          variant="standard"
          id="first_name"
          name="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors?.user?.first_name)}
          helperText={formik.errors?.user?.first_name}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          label="Last Name"
          variant="standard"
          id="last_name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors?.user?.last_name)}
          helperText={formik.errors?.user?.last_name}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel id="select-user-role-label" sx={{ left: "-14px" }}>
          Select Role
        </InputLabel>
        <Select
          variant="standard"
          labelId="select-user-role-label"
          id="select-user-role"
          label="Select Role"
          name="role"
          value={formik.values.role}
          onChange={formik.handleChange}
          error={Boolean(formik.errors?.role)}>
          <MenuItem value={"admin"}>Admin</MenuItem>
          <MenuItem value={"user"}>User</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default UserForm;
