import ErrorService from "../helpers/ErrorService";
// import { getCookie } from "../helpers/utils";
import { store } from "../store";
import { selectUserData } from "../store/slice/auth";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const sendRequest = (
  url,
  body = null,
  auth = true,
  methodType = "POST",
  firstTimeAuth
) => {
  const absoluteURL = new URL(url, baseUrl);
  const data = firstTimeAuth ? firstTimeAuth : selectUserData(store.getState());
  const { token, authToken, sid, sidExp } = data;
  const commonHeaders = {};

  if (token && auth) {
    commonHeaders["Authorization"] = `Token ${token}`;
    commonHeaders["auth"] = authToken;
    commonHeaders["sessionid"] = sid;
    commonHeaders["expiry"] = sidExp;
  }

  if (methodType === "PUT") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "PUT",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  if (methodType === "PATCH") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "PATCH",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  if (methodType === "DELETE") {
    const headers = { ...commonHeaders };

    return fetch(absoluteURL, {
      method: "DELETE",
      headers,
    });
  }

  if (body && methodType === "POST") {
    const headers = { ...commonHeaders };
    const isBodyFormData = body instanceof FormData;
    if (!isBodyFormData) {
      headers["Content-Type"] = "application/json";
    }
    return fetch(absoluteURL, {
      method: "POST",
      headers,
      body: isBodyFormData ? body : JSON.stringify(body),
    });
  }

  return fetch(absoluteURL, { headers: { ...commonHeaders } });
};

export const registerOrg = (data) => sendRequest("/api/orgs/", data, false);

export const signIn = (data) => sendRequest("/api/auth/login/", data, false);

export const adminSignIn = (data) =>
  sendRequest("/api/paylens-admin/login/", data, false);

export const getLoggedInMemberInfo = async (authData) => {
  try {
    const res = await sendRequest(
      "/api/member/me/",
      null,
      true,
      "GET",
      authData
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const requestPasswordReset = (data) =>
  sendRequest(`/api/auth/password-reset/`, data);

export const changePassword = (data, uid, token) =>
  sendRequest(`/api/auth/password-reset/${uid}/${token}/`, data);

export const fetchOrgDetails = async () => {
  try {
    const res = await sendRequest("/api/orgs/my-org/");
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const fetchOrgDetailsOnSignIn = async (authData) => {
  try {
    const res = await sendRequest(
      "/api/orgs/my-org/",
      null,
      true,
      "GET",
      authData
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const uploadData = async (file) => {
  const fd = new FormData();
  fd.append("uploaded_file", file);
  const res = await sendRequest("/api/training/upload-data/", fd);
  if (!res.ok && res.status === 400) {
    throw await res.json();
  }
  return await res.json();
};

export const createTraining = async (
  uploadedFile,
  training_params,
  multi_currency_params
) => {
  const fd = new FormData();
  fd.append("uploaded_file", uploadedFile);
  fd.append("training_params", JSON.stringify(training_params));
  fd.append("multi_currency_params", JSON.stringify(multi_currency_params));

  try {
    const res = await sendRequest(`/api/training/train/`, fd);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const fetchTrainings = async () => {
  try {
    const res = await sendRequest("/api/training/");
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const latestTrainings = async () => {
  try {
    const res = await sendRequest("/api/training/latest-training/");
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
};

export const fetchVariablesForUserCases = async (trainingId, useCase) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/use-case-fields/${useCase}/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const fetchOptionsForVariable = async (
  trainingId,
  columnName,
  selectedVariable
) => {
  let apiUrl = new URL(
    `/api/training/${trainingId}/employee-list-column-values/${columnName}/`,
    baseUrl
  );
  if (Object.keys(selectedVariable).length > 0) {
    for (let x in selectedVariable) {
      apiUrl.searchParams.set(x, selectedVariable[x]);
    }
  }
  try {
    const res = await sendRequest(apiUrl.pathname + apiUrl.search);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const fetchEmployeesIdByTrainingId = async (url) => {
  try {
    const res = await sendRequest(url);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const marketValueTemplate = async (trainingId, variables) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/market-value-template/`,
      {
        variables: variables,
      }
    );
    const data = await res.blob();
    return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const employeeDataDownload = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/employees-excel/`
    );
    const data = await res.blob();
    return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const employeeCorrectionCasesDataDownload = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/employees-excel-corrective-risk/`
    );
    const data = await res.blob();
    return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const employeeCompensationReviewCasesDataDownload = async (
  trainingId
) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/employees-excel-risk/`
    );
    const data = await res.blob();
    return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const marketValueUpload = async (trainingId, file) => {
  const fd = new FormData();
  fd.append("file", file);
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/market-value-upload/`,
      fd
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payEquityOveriew = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/comp-perf-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payEquityList = async (trainingId, page, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/pay-equity/?page=${page}${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payEquityCompensationReviewCases = async (
  trainingId,
  page,
  filterQuery
) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/employees-risk/?page=${page}${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payEquityCorrectionCase = async (
  trainingId,
  page,
  filterQuery
) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/employees-corrective-risk/?page=${page}${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const predictData = async (trainingId, params, useCase) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/prediction/${useCase}/`,
      {
        params,
      }
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getAllTrainingModels = async (trainingId, page) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/trained-models/?page=${page}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payOptimizationOveriew = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/market-comp-perf-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payEquitySumOverview = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/sum-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payOptimizationSumOverview = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/market-sum-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const payReferenceIndex = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/pay-reference-index/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const interalPayReferenceIndex = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/internal-pay-reference-index/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getMarketDataTempplateVariables = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/market-value-template-fields/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getUseCasesPredictionCount = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/prediction-overview/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getComparatorLevelConfigVariables = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/comparator-levels/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};
export const saveComparatorLevelConfigVariables = async (trainingId, param) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/comparator-levels/`,
      param
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getComparatorLevelData = async (
  predictionId,
  trainingId,
  level,
  page
) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/predictions/${predictionId}/comparator-data/${level}/?page=${page}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getAllUsers = async () => {
  try {
    const res = await sendRequest(`/api/member/`);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const addUser = (data) => sendRequest("/api/member/", data);

export const updateUser = (id, data) =>
  sendRequest(`api/member/${id}/`, data, true, "PUT");

export const updateUserPatch = (id, data) =>
  sendRequest(`api/member/${id}/`, data, true, "PATCH");

export const deleteUser = async (id) =>
  sendRequest(`/api/member/${id}/`, null, true, "DELETE");

export const getComparatorPercentils = async (trainingId, predictionId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/predictions/${predictionId}/comparator-percentiles/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const fetchCurrentCompensation = async (trainingId, employeeId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/comp-ratio/${employeeId}/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getCorrectiveRiskOverview = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      ` /api/training/${trainingId}/corrective-risk-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getRiskOverview = async (trainingId, filterQuery) => {
  try {
    const res = await sendRequest(
      ` /api/training/${trainingId}/risk-overview/${filterQuery}`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const runModel = async (trainingId, payload) => {
  try {
    const res = await sendRequest(`/api/training/${trainingId}/run/`, payload);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const uploadLicenseFile = async (file) => {
  const fd = new FormData();
  fd.append("file", file);
  try {
    const res = await sendRequest("/api/orgs/upload-license/", fd);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getTrainingFields = async (trainingId) => {
  try {
    const res = await sendRequest(`/api/training/${trainingId}/fields/`);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};
export const fetchTrainingFields = async (file, field_1, filed_2) => {
  const fd = new FormData();
  fd.append("uploaded_file", file);
  fd.append("performance_field", field_1);
  fd.append("compensation_field", filed_2);
  try {
    const res = await sendRequest(`/api/training/internal-fields/`, fd);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const downloadUseCasesExcel = async (trainingId, predictionId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/predictions/${predictionId}/excel-download/`
    );
    const data = await res.blob();
    return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getUseCasesJson = async (trainingId, predictionId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/predictions/${predictionId}/excel-json/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getOptimizationPayRefIndex = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/pay-reference-index/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getInternalPayRefIndex = async (trainingId) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/internal-pay-reference-index/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getInternalPayRefIndexPost = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/internal-pay-reference-index/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getOptimizationPayRefIndexPost = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/pay-reference-index/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getInternalCohort = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/payequity/${trainingId}/internal-cohort/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getMarketCohort = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/payequity/${trainingId}/market-cohort/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getPayEquityReport = async (trainingId, payload) => {
  const temp = { ...payload };
  delete temp.currentPage;
  try {
    const res = await sendRequest(
      `/api/payequity/${trainingId}/pay-equity-report/${payload.currentPage}`,
      temp
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const saveReport = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/payequity/${trainingId}/save-report/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getSavedReport = async (trainingId) => {
  try {
    const res = await sendRequest(`/api/payequity/${trainingId}/save-report/`);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const performanceGrid = async (trainingId, payload) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/perf-overview-grid/`,
      payload
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getSpendOptimizerTotals = async (trainingId) => {
  try {
    const res = await sendRequest(`/api/training/${trainingId}/get-totals/`);
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const getPayParityTrend = async (trainingId, tab) => {
  try {
    const res = await sendRequest(
      `/api/training/${trainingId}/get-comp-trend/${tab}/`
    );
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};

export const logout = async () => {
  try {
    const res = await sendRequest(`api/auth/logout/`, {});
    const data = await res.json();
    if (ErrorService.handleResponse(res, data)) return data;
  } catch (error) {
    ErrorService.logError("Something went wrong");
  }
  return null;
};
